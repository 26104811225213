import { Component, Fragment } from 'inferno'
import { safeGet } from 'safe-utils'
import querystring from 'querystring'

import './List.scss'
import './Dashboard.scss'

import {
  Button,
  ButtonBar,
  Form,
  Footer,
  NavButton,
  NavStateButton,
  Toolbar,
} from 'influence-ux-components'
import { IUser } from 'app-entity-User'
import { IBlog } from 'app-entity-Blog'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { INotificationManager } from 'influence-interfaces/presentation'

// This is needed to register the relation field widget adapter
import 'app-field-RelationField/widgets'

// import { FormRows } from 'influence-ux-formlib'
import { FormRows, renderString } from 'influence-ux-formlib'
import SectionPage from '../SectionPage'
import './Edit.scss'
import {
  i18n,
  Schema,
  ObjectField } from 'isomorphic-schema'

export default class ProfilePage extends Component {

  render ({ match }) {
    const { fetchData, ...props } = this.props 
    return (
      <SectionPage
        editAvatar
        editHero
        editBlogVisibility
        match={match}
        toolbar={this.renderToolbarContent()}
        filter={this.renderFilterForm()}>
        <EditForm value={fetchData} {...props} />
      </SectionPage>
    )
  }

  renderToolbarContent () {
    return null
  }

  renderFilterForm () {
    const { match, location } = this.props
    const { subForm } = querystring.parse(location.search.replace(/^\?/, ''))

    return (
        <ButtonBar className="FormFilter">
            <NavStateButton link to={`${match.url}`}
              isSelected={subForm === undefined}>{renderString('filterForm-all-label')}</NavStateButton>
          {Object.keys(formSchema._fields).map((fieldKey) => {
            const field = formSchema._fields[fieldKey]
            return (
              <NavStateButton link to={`${match.url}?subForm=${fieldKey}`}
                isSelected={subForm === fieldKey}>{renderString(field.label)}</NavStateButton>
            )
          })}
        </ButtonBar>
    )
  }
}

const formSchema = new Schema('Profile Editing Form Schema', {
  user: new ObjectField({
    label: i18n('profileFormSchema-user-label', 'User'),
    schema: IUser.schema
  }),
  blog: new ObjectField({
    label: i18n('profileFormSchema-blog-label', 'Blog'),
    schema: IBlog.schema
  })
})

const validationOptions = {
  omitFields: ["user.avatarUrl", "user.accounts", "user.influencer", "user.roles", "user.mainBlogName", "user.mainBlogId", "blog.heroImageUrl", "blog.principals", "blog.profilePost", "blog.title"]
}

class EditForm extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: props.value || {
        user: undefined,
        blog: undefined
      },
      validationErrors: undefined,
      submitted: false,
      isDirty: false,
    }    
  }

  _isBlogPublic(blog) {
    return blog && (blog._workflows.publishWorkflow === 'published')
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const user = new ISessionManager().getCurrentUser()
    const blog = new ISessionManager().getCurrentRoleManager()

    this.setState({
      value: { blog, user }
    })
    window.addEventListener("beforeunload", this.doWarnClosingWindow)
  }

  componentWillUnmount () {
    window.removeEventListener("beforeunload", this.doWarnClosingWindow)
  }

  doWarnClosingWindow = (e) => {
    if (this.state.isDirty) {
      var confirmationMessage = "WARNING! You have unsaved changes that will be lost if you continue!";
    
      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Safari, Chrome, and other WebKit-derived browsers
    }
  }

  render ({ location }) {
    const { subForm } = querystring.parse(location.search.replace(/^\?/, ''))

    const value = Object.assign(this.state.value, {
      isPublic: this._isBlogPublic(this.state.value.blog)
    })

    const blogName = safeGet(() => this.state.value.blog.title)
    const profilePost = safeGet(() => this.state.value.blog.profilePost, {})

    return (
      <Form onSubmit={this.doSubmit} className='IEditItem'>
        <FormRows
          schema={formSchema}
          value={value}
          validationErrors={this.state.validationErrors}
          selectFields={subForm}
          omitFields={validationOptions.omitFields}
          onChange={this.didUpdate} />
        
        <ButtonBar>
          <NavButton primary to={`/edit/${blogName}/BlogPost/${profilePost.id}`}>Edit presentation...</NavButton>
        </ButtonBar>
        
        <Footer fixed className="ActionsToolbar">
          <Toolbar
            leading={<Fragment>
              <Button primary submit>Save</Button>
                <i>or</i>
              <Button link onClick={this.doCancel}>Cancel</Button>
              </Fragment>} />
        </Footer>
      </Form>
    )
  }

  renderToolbarContent () {
    return null
  }

  renderFilterForm () {
    return null
  }


  didUpdate = (propName, val) => {
    const isNewValue = JSON.stringify(value) !== JSON.stringify(this.state.value[propName])
    const isDirty = this.state.isDirty || isNewValue

    const { value } = this.state

    if (propName === 'isPublic') {
      value['blog']._workflows.publishWorkflow = (val ? 'published' : 'draft')
    }
    else {
      value[propName] = val
    }

    let validationErrors
    if (this.state.submitted) {
      validationErrors = formSchema.validate(value, validationOptions)
    }

    this.setState({
      value,
      validationErrors,
      isDirty
    })
  }

  doSubmit = (e) => {
    e.preventDefault()

    let validationErrors = formSchema.validate(this.state.value, validationOptions)
    if (validationErrors) {
      return this.setState({
        validationErrors,
        submitted: true
      })
    }

    const { user, blog } = this.state.value
    new ISessionManager().setCurrentRoleManager(blog)

    new IApiClient().update([
      {
        URI: `/content/User/${user._id}`,
        data: user,
        invalidate: ['/content/User', '/session']
      },
      {
        URI: `/content/Blog/${blog._id}`,
        data: blog,
        invalidate: ['/content/Blog']
      }
    ]).then(() => {
      this.setState({
        isDirty: false
      })

      new ISessionManager().refreshCurrentUser()
      // TODO: Show success indicator at center of screen
      new INotificationManager().showSuccessMessage()
    })
  }

  doCancel = (e) => {
    // TODO: Reload content from server
  }
}