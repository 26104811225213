import { createVNode } from "inferno";
import { Adapter } from 'component-registry';
import classnames from 'classnames';
import { IRelationFieldItemWidget } from 'app-field-RelationField/widgets';
import { IBlog } from '../interface';

function ListItem(_ref) {
  var context = _ref.context,
      className = _ref.className;
  return createVNode(1, "div", classnames("RelationFieldItem-BlogPost", className), context.title, 0);
}

new Adapter({
  implements: IRelationFieldItemWidget,
  adapts: IBlog,
  Component: ListItem
});