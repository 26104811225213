import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { BaseField } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IConnectedTitleField = new Interface({
  name: 'IConnectedTitleField'
});
export var ConnectedTitleField = createObjectPrototype({
  implements: [IConnectedTitleField],
  extends: [BaseField],

  /*
  {
    cardId: 'xxx',
    active: true
  }
  */
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  }
});