import { Component } from 'inferno'
import { fetchContent } from 'app-entity-Guide/widgets'

import { Animated } from 'inferno-animation'
import { IEditForm } from 'influence-interfaces/presentation'
import { IApiClient } from 'influence-interfaces/presentation'
import './Edit.scss'

export default class Page extends Component {

    static async fetchData ({registry, match, location}) {
        const { data } = await new IApiClient({ registry }).query({
            URI: `/content/Guide/${match.params.id}`
        })
        const outp = await fetchContent({ registry, obj: data })
        return outp
    }

    render () {
        const data = this.props.fetchData

        let EditForm = null
        if (data) {
            EditForm = new IEditForm(data).Component
        }

        if (!EditForm) return null

        return (
          <Animated key="me" className="EditPage" prefix="PageNav">
            <EditForm value={data} {...this.props} />
          </Animated>
        )
    }
}

