import { Component } from 'inferno'
import { safeJoin } from 'safe-utils'
import { Animated } from 'inferno-animation'

import { IApiClient, IPageManager } from 'influence-interfaces/presentation'
import { IPublicForm } from 'influence-interfaces/presentation'

import logo_colored_svg from 'app-widget-common/img/logo/logo-colored.svg'
import anonAvatar_svg from 'app-widget-common/img/anon_avatar.svg'

import './RegisterStep.scss'


export default class Page extends Component {

 static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
      URI: `/admin/Invitation/${match.params.id}`
    })

    const name = safeJoin([data.firstName, data.lastName], ' ')
    new IPageManager({ registry }).setMetaData({
      title: 'Invitation to Memly.io',
      description: `Hi${name ? ' ' + name : ''}! We have invited you to join our community because you are an inspiring content creator. To sign up, all you need is your Facebook account.`,
      url: 'https://memly.io' + location.pathname
    })
    
    return data
  }

  render(props) {
    const data = this.props.fetchData

    if (!data) {
      return null
    }

    const Content = new IPublicForm(data).Component

    return (
      <Animated key="onboarding-2" className="Fullpage Onboarding" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
        <div id="Page-Logo"><img className="logo" src={logo_colored_svg} /></div>
        <img class="Onboarding-Avatar" src={anonAvatar_svg} />
        <div className="Onboarding-Content">
          <Content context={data} />
        </div>
      </Animated>
    )
  }
}

