import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, [createVNode(32, "defs", null, [createVNode(32, "path", null, null, 1, {
    "d": "M30,5 C20.325,5 12.5,12.825 12.5,22.5 C12.5,35.625 30,55 30,55 C30,55 47.5,35.625 47.5,22.5 C47.5,12.825 39.675,5 30,5 L30,5 Z M30,28.75 C26.55,28.75 23.75,25.95 23.75,22.5 C23.75,19.05 26.55,16.25 30,16.25 C33.45,16.25 36.25,19.05 36.25,22.5 C36.25,25.95 33.45,28.75 30,28.75 L30,28.75 Z",
    "id": "path-1"
  }), createVNode(32, "rect", null, null, 1, {
    "id": "path-3",
    "x": "0",
    "y": "0",
    "width": "125",
    "height": "125"
  })], 4), createVNode(32, "path", "inverse", null, 1, {
    "d": "M78.5,1.5 L28,1.5 C26.7573593,1.5 25.6323593,2.00367966 24.8180195,2.81801948 C24.0036797,3.63235931 23.5,4.75735931 23.5,6 L23.5,102 C23.5,103.242641 24.0036797,104.367641 24.8180195,105.181981 C25.6323593,105.99632 26.7573593,106.5 28,106.5 L78.5,106.5 L78.5,1.5 Z",
    "stroke": "currentColor",
    "stroke-width": "3"
  }), createVNode(32, "path", "inverse", null, 1, {
    "d": "M100.5,19.5 L50,19.5 C48.7573593,19.5 47.6323593,20.0036797 46.8180195,20.8180195 C46.0036797,21.6323593 45.5,22.7573593 45.5,24 L45.5,120 C45.5,121.242641 46.0036797,122.367641 46.8180195,123.181981 C47.6323593,123.99632 48.7573593,124.5 50,124.5 L100.5,124.5 L100.5,19.5 Z",
    "stroke": "currentColor",
    "stroke-width": "3",
    "transform": "translate(73.000000, 72.000000) scale(-1, 1) translate(-73.000000, -72.000000) "
  }), createVNode(32, "line", null, null, 1, {
    "x1": "46.5",
    "y1": "18.5",
    "x2": "77.5",
    "y2": "1.5",
    "id": "Line",
    "stroke": "currentColor",
    "stroke-width": "3",
    "stroke-linecap": "square"
  }), createVNode(32, "g", null, [createVNode(32, "mask", null, createVNode(32, "use", null, null, 1, {
    "xlink:href": "#path-1"
  }), 2, {
    "id": "mask-2",
    "fill": "white"
  }), createVNode(32, "g", null, createVNode(32, "g", null, [createVNode(32, "mask", "inverse", createVNode(32, "use", null, null, 1, {
    "xlink:href": "#path-3"
  }), 2), createVNode(32, "use", null, null, 1, {
    "id": "Black",
    "stroke": "none",
    "fill": "currentColor",
    "fill-rule": "evenodd",
    "xlink:href": "#path-3"
  })], 4, {
    "transform": "translate(-32.500000, -32.500000)"
  }), 2, {
    "id": "Colors/Soft",
    "mask": "url(#mask-2)"
  })], 4, {
    "transform": "translate(43.000000, 24.000000)"
  }), createVNode(32, "line", null, null, 1, {
    "x1": "54.5",
    "y1": "92.5",
    "x2": "92.5",
    "y2": "92.5",
    "id": "Line-2",
    "stroke": "currentColor",
    "stroke-width": "3",
    "stroke-linecap": "square"
  }), createVNode(32, "line", null, null, 1, {
    "x1": "54.5",
    "y1": "103.5",
    "x2": "92.5",
    "y2": "103.5",
    "id": "Line-2-Copy",
    "stroke": "currentColor",
    "stroke-width": "3",
    "stroke-linecap": "square"
  }), createVNode(32, "line", null, null, 1, {
    "x1": "54.5",
    "y1": "114.5",
    "x2": "92.5",
    "y2": "114.5",
    "id": "Line-2-Copy-2",
    "stroke": "currentColor",
    "stroke-width": "3",
    "stroke-linecap": "square"
  })], 4, {
    "width": "126px",
    "height": "126px",
    "viewBox": "0 0 126 126"
  });
}