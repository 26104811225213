import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["domRef", "tag", "children"];

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import { createElement } from 'inferno-create-element';
import { utils } from 'inferno-animation';
var addClassName = utils.addClassName,
    removeClassName = utils.removeClassName,
    registerTransitionListener = utils.registerTransitionListener,
    forceReflow = utils.forceReflow,
    setDisplay = utils.setDisplay;
/**
 * These are the open and close animation helpers
 */

export function animateModalOnRemove(node, callback) {
  var clone = node.cloneNode(true);
  setDisplay(node, 'none !important');
  node.parentNode.appendChild(clone);
  registerTransitionListener(clone, function () {
    // *** Cleanup ***
    callback && callback(clone);
    clone.remove();
  });
  setTimeout(function () {
    removeClassName(clone, 'show');
  }, 5);
}
export function animateModalOnAdd(node, callback) {
  setDisplay(node, 'none');
  addClassName(node, 'fade');
  forceReflow(node);
  setDisplay(node, undefined);
  registerTransitionListener([node, node.children[0]], function () {
    // *** Cleanup ***
    setDisplay(node, undefined);
    callback && callback(node);
  });
  addClassName(node, 'show');
}
export function Animated(props) {
  var domRef = props.domRef,
      Tag = props.tag,
      children = props.children,
      attrs = _objectWithoutProperties(props, _excluded);

  return createElement(Tag || 'div', _objectSpread({
    ref: domRef
  }, attrs), children);
}