import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["tag", "children", "className"];
import { createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import classNames from 'classnames';
import titleCase from '../utils/titleCase';
import { Button } from 'influence-ux-components';
export var MarkupButton = function MarkupButton(_ref, _ref2) {
  var _ref$tag = _ref.tag,
      tag = _ref$tag === void 0 ? '' : _ref$tag,
      _ref$children = _ref.children,
      children = _ref$children === void 0 ? titleCase(tag) : _ref$children,
      className = _ref.className,
      props = _objectWithoutProperties(_ref, _excluded);

  var editor = _ref2.editor,
      _ref2$activeMarkupTag = _ref2.activeMarkupTags,
      activeMarkupTags = _ref2$activeMarkupTag === void 0 ? [] : _ref2$activeMarkupTag;

  var onClick = function onClick(e) {
    e && e.preventDefault();
    editor.toggleMarkup(tag);
  };

  className = classNames(className, {
    active: activeMarkupTags.indexOf(tag.toLowerCase()) > -1
  });
  props = _objectSpread(_objectSpread({}, props), {}, {
    onClick: onClick,
    className: className
  });
  return normalizeProps(createComponentVNode(2, Button, _objectSpread(_objectSpread({
    "link": true
  }, props), {}, {
    children: children
  })));
};
/*
MarkupButton.propTypes = {
  tag: PropTypes.string.isRequired,
  children: PropTypes.node
}

MarkupButton.contextTypes = {
  editor: PropTypes.object,
  activeMarkupTags: PropTypes.array
}
*/