import { Component } from 'inferno'
import { Animated } from 'inferno-animation'
import SitePageFooter from '../../widgets/SitePageFooter'

import '../ContentPage.scss'
import '../onboarding/RegisterStep.scss'
import './PrivacyPolicy.scss'

import logo_colored_svg from 'app-widget-common/img/logo/logo-colored.svg'

export default class Page extends Component {
  render() {
    return (
      <Animated key="me" className="ContentPage PrivacyPolicy" prefix="PageNav">
        <div id="Page-Logo"><img className="logo" src={logo_colored_svg} /></div>
        <div className="content">
          <h2>Privacy Policy</h2>
          <p>To sign up, all you need is a Facebook account.</p>
          <p>We take privacy seriously and will not share your data with a third party unless you give us permission.</p>
          <p>For a complete removal of your personal data, please contact our support <a href="malto:support@memly.io">support@memly.io</a> and we will help you out with the practical issues.</p>
          <h2>Cookies</h2>
          <p>We use cookies to gather anonymous visitor stats. That is it for now.</p>
          </div>
        <SitePageFooter />
      </Animated>
    )
  }
}

