import { Adapter } from 'component-registry';
import { IImageHelper } from 'influence-interfaces/presentation';
import { IImage } from '../interface';

var _env_ = typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env;

var IMAGE_URI_BASE = _env_.IMAGE_URI_BASE;
var FRONTEND_BASE_URI = process.env.FRONTEND_BASE_URI;
IMAGE_URI_BASE = IMAGE_URI_BASE || (FRONTEND_BASE_URI || '') + '/images';

function _srcSet(lst, maxWidth) {
  if (!Array.isArray(lst)) return "deprecatedImageObject";
  return lst.filter(function (obj) {
    return !maxWidth || obj.width <= maxWidth;
  }).map(function (obj) {
    return "".concat(IMAGE_URI_BASE).concat(obj.imagePath);
  }, undefined).join(', ');
}

function _src(lst, maxWidth) {
  if (!Array.isArray(lst)) return "deprecatedImageObject";
  var tmp = lst.filter(function (obj) {
    return !maxWidth || obj.width <= maxWidth;
  });

  if (tmp.length > 0) {
    return "".concat(IMAGE_URI_BASE).concat(tmp[0].imagePath);
  } else {
    return '';
  }
}

var ImageHelper = new Adapter({
  implements: IImageHelper,
  adapts: IImage,
  getSrc: function getSrc(maxWidth) {
    return _src(this.context.rendered, maxWidth);
  },
  getSrcSet: function getSrcSet(maxWidth) {
    return _srcSet(this.context.rendered, maxWidth);
  },
  getThumbSrc: function getThumbSrc(maxWidth) {
    return _src(this.context.thumbnails, maxWidth);
  },
  getThumbSrcSet: function getThumbSrcSet(maxWidth) {
    return _srcSet(this.context.thumbnails, maxWidth);
  }
});