import './SitePageFooter.scss'

function PageFooter (props) {
  return (
    <div className="PageFooter">
      {props.children}
    </div>
  )
}

function PageFooterBody (props) {
  return (
    <div className="PageFooterBody">
      {props.children}
    </div>
  )
}

export default function SitePageFooter () {
  return (
    <PageFooter>
      <PageFooterBody>© Copyright 2021, memly.io – We are currently in beta, to apply for access follow our Instagram account <a href="https://www.instagram.com/memly.io/">@memly.io</a> and send us a DM.</PageFooterBody>
    </PageFooter>
  )
}