import { Component } from 'inferno'
import classnames from 'classnames'
import { safeGet } from 'safe-utils'


import { 
  Body,
  Button,
  ButtonBar,
  Column,
  Footer,
  FormRow,
  Header,
  Label,
  Modal,
  Section,
  Sticky,
} from 'influence-ux-components'

import { BoolField } from 'isomorphic-schema'
import { widgets } from 'influence-ux-formlib'
import { Animated } from 'inferno-animation'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField/widgets'

import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { IUser } from 'app-entity-User'

import './RegisterStep.scss'
import 'app-widget-StickyRow/index.scss'

import logo_colored_svg from 'app-widget-common/img/logo/logo-colored.svg'
const { CheckboxWidget } = widgets

const acceptField = new BoolField({
  label: 'I accept the terms'
})


export default class Page extends Component {


  constructor () {
    super(arguments)

    this.state = {
      modal: false,
      accepted: false
    }
    this.didChange = this.didChange.bind(this)
    this.doNext = this.doNext.bind(this)
    this.doOpenModal = this.doOpenModal.bind(this)
    this.doCloseModal = this.doCloseModal.bind(this)
  }

  static async fetchData ({registry, match, location}) {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/session`
      })
      
      return data
  }
  
  doNext (e) {
    e.preventDefault()
    if (this.state.accepted) {
      const currentUser = new ISessionManager().getCurrentUser()
      currentUser.termsAndConditions = {
        name: 'onboarding-terms',
        dateTime: new Date().toUTCString()
      }
      new IApiClient().update({
        URI: `/content/User/${currentUser._id}`,
        data: currentUser,
        invalidate: ['/content/User', '/session']
      }).then(({data}) => {
        // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
        new ISessionManager().refreshCurrentUser()
        // Success message triggers refetch and causes weird re-render
        // new INotificationManager().showSuccessMessage()
        this.context.router.history.push(`/register_4`)
      })
    } else {
      this.setState({
        modal: true
      })
    }
  }

  didChange (propName, value) {
    this.setState({
      accepted: value
    })
  }

  doCloseModal (e) {
    e && e.preventDefault()

    this.setState({
      modal: false
    })
  }

  doOpenModal () {
    this.setState({
      modal: true
    })
  }

  render () {
      const currentUser = safeGet(() => new ISessionManager().getCurrentUser())
      return (
          <Animated className="Fullpage Onboarding" key="onboarding-3" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
            {this.renderModal()}
            <div id="Page-Logo"><img className="logo" src={logo_colored_svg} /></div>
            {this.renderAvatar()}
            <Body className='terms-and-conditions Onboarding-Content'>
              <h2>Terms & Conditions</h2>
              <Section hasColumns>
                <Column>
                  <p>Memly.io is a kind and respectful community of world class content creators. You are responsible for the content you post, <strong>following our community rules</strong> and <strong>respecting the law.</strong></p>
                  <p>Memly.io is currently in beta. You therefor have to accept that there can be bugs and at worst you could loose all of your content. Obviously we will do everything in our power to ensure this doesn't happen. If you run into issues just contact community support and we will help you.</p>
                  <p>By posting content with memly.io you accept that we make it available through those channels we have at our disposal at any given time. You can control visibility through our privacy options.</p>
                  <p>These communty rules can be updated at our discretion, but we will be mindful of how these changes might impact you as a content creator.</p>
                </Column>
                <Column>
                  <div className="keep-together">
                    <h4>Here are examples of what we don't tolerate:</h4>
                    <ul>
                      <li>Intellectual property infringement</li>
                      <li>Hate speech</li>
                      <li>Sexism</li>
                      <li>Denial of scientific fact</li>
                      <li>Abuse or harassment</li>
                      <li>Breaking the law or inciting others to break the law</li>
                      <li>Sell or promote controlled substances or other illegal products or services</li>
                    </ul>
                  </div>
                  <p>If the content you publish are in breach of our community rules we reserve the right to unpublish it. Any content that has been edited to conform to our rules can be published again. If you repetadly break our rules your account can be suspended.</p>
                  <p>If you have any questions, send a DM to <a target="_blank" href="https://www.instagram.com/memly.io/">@memly.io</a>. To contact our support by e-mail, use <a href="mailto:support@memly.io">support@memly.io</a>.</p>
                </Column>
              </Section>

              {currentUser && <Sticky className="Onboarding-ActionBar" stickToBottom>
                {/* Added key so the duplicate is visually updated on changes */}
                <div className="formlib-FormRows" key={this.state.accepted.toString()}>
                  <FormRow className="IBoolField">
                    <Label className="checkbox" for="accept__Field">
                      <CheckboxWidget namespace={["accept"]} value={this.state.accepted} adapter={{ context: acceptField }} onChange={this.didChange}/>
                      {acceptField.label}
                    </Label>
                  </FormRow> 
                  <Button primary
                    className={classnames("Onboarding-Button Onboarding-Action-Button", { "fake-disabled": !this.state.accepted })}
                    onClick={this.doNext} href="#instagram">Let's go!</Button>
                </div>
              </Sticky>}
            </Body>
          </Animated>
      )
  }

  renderAvatar() {
    const currentUser = new ISessionManager({ registry: this.context.registry }).getCurrentUser()

    if (!currentUser) return null

    const propName = 'avatarUrl'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      readOnly
      adapter={dummyAdapter}
      value={currentUser[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  renderModal () {
    return (
      <Modal fade className="MessageModal" isOpen={this.state.modal} doClose={this.doCloseModal}>
        <Header toggle={this.doCloseModal}>Oops!</Header>
        <Body>
          <p>You need to accept the terms by clicking the checkbox to continue.</p>
        </Body>
        <Footer>
          <ButtonBar>
            <Button primary onClick={this.doCloseModal}>Ok</Button>
          </ButtonBar>
        </Footer>
      </Modal>
    )
  }
}

