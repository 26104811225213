import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { i18n, Schema, TextField, DateTimeField } from 'isomorphic-schema';
import { MobiledocField } from 'app-field-MobiledocField';
export var IGuidePage = new Interface({
  name: 'IGuidePage',
  schema: new Schema('Guide Page Schema', {
    title: new TextField({
      label: i18n('ICard-title-label', 'Title'),
      placeholder: i18n('ICard-title-placeholder', 'Type here...')
    }),
    publishingStartsAt: new DateTimeField({
      label: i18n('ITimedPublishing-publishingStartsAt-label', 'Publish from')
    }),
    publishingEndsAt: new DateTimeField({
      label: i18n('ITimedPublishing-publishingEndsAt-label', 'Publish to')
    }),
    body: new MobiledocField({
      toolbar: 'PublicPost'
    })
  })
});