import { Component } from 'inferno'
import { IPublicListItem } from 'influence-interfaces/presentation'
import { IApiClient } from 'influence-interfaces/presentation'
import querystring from 'querystring'

import '../admin/List.scss'
import '../admin/Dashboard.scss'
import {
  Form,
  Masonry,
  MessageCanvas
} from 'influence-ux-components'
import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'

import 'app-field-WorkflowFilterField/widgets'
import SectionPage from '../SectionPage'
import './Cards.scss'

const { FormRows } = require('influence-ux-formlib')

const filterSchema = new Schema('Filter Schema', {
  search: new TextField({
    label: 'Filter list:',
    placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
  })
})

export default class MediaPage extends Component {

  static _limit = 30
  _lastLoadedPage = 1

  constructor (props) {
    super(props)

    this.state = {
      search: undefined,
      posts: props.fetchData || [],
      queryIsLoading: false,
      hasMore: true,
      rowGap: 10,
      rowHeight: 10
    }
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    const { search } = location.search || {}
    const query = { 
      search: (search && search.length >= 3 ? { shortFreetext: search } : undefined), 
      stateFilter: 'publishWorkflow.published'
    }
    
    if (match.params.roleManagerId) {
       query['roleManagerId'] = match.params.roleManagerId
    }

    for (let k in query) {
      if (!query[k]) delete query[k]
    }

    try {
      const { data } = await new IApiClient({ registry }).query([
        {
          URI: '/content/MediaCard', query: { query, page, limit: MediaPage._limit }
        },
        {
          URI: '/content/CityGuide', query: { query, page, limit: MediaPage._limit }
        },
        {
          URI: '/content/PlaceCard', query: { query, page, limit: MediaPage._limit }
        },
        {
          URI: '/content/RecipeCard', query: { query, page, limit: MediaPage._limit }
        },
        {
          URI: '/content/ListCard', query: { query, page, limit: MediaPage._limit }
        }
      ])
  
      const outp = data.reduce((prev, curr) => {
        return prev.concat(curr)
      }, [])
      outp.sort((a, b) => b._createdAt - a._createdAt)
      return outp
    }
    catch (e) {
      return []
    }
  }

  componentDidMount() {
    requestAnimationFrame(this._checkLoadMore)
  }

  componentWillReceiveProps (nextProps, nextContext) {
    // Make sure load more is enabled when search is manipulated
    let { hasMore, page } = this.state
    if (nextProps.location.search !== this.props.location.search) {
      hasMore = true
      page = 1 // Reset the page count (this is a quickfix, should refactor)
    }

    const { search, publishWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    let query = { search, publishWorkflow }
    const posts = nextProps.fetchData || []
    this.setState({ posts, ...query, hasMore, page })
  }

  render () {
    const { location } = this.context.router.history
    const { search } = querystring.parse(location.search.replace(/^\?/, ''))
    const searchMode = (search ? true : false)
    return (
      <SectionPage className="LivePage"
        type="MediaCard"
        match={this.props.match}>
        {searchMode && this.renderSearchHeader()}
        {this.renderFeed()}
      </SectionPage>
    )
  }

  renderFilterForm () {
    const filterValue = this.state

    return (
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }

  renderSearchHeader () {
    return [
      <h2 className="SearchHeader">Search Results:</h2>,
      (this.state.posts.length === 0) && <div>
        <MessageCanvas>Search and you will find...</MessageCanvas>
      </div>
    ]
  }

  renderFeed () {
    if (this.state.posts.length > 0) {
      return (
        <Masonry className="List-Container" domRef={(el) => this._thumbnailsEl = el}>
          {this.state.posts.map((post) => {
            const ListItem = new IPublicListItem(post).Component
            return <ListItem key={post._id}
                      rowGap={this.state.rowGap}
                      rowHeight={this.state.rowHeight}
                      context={post} />
          })}
        </Masonry>
      )
    }
    return <div className="IEditItem"><p className="placeholder">Sometime in the future you will see some really inspiring content here. :)</p></div>
  }

  _checkLoadMore = () => {
    if (this.$UN) return

    if (this._thumbnailsEl) {
      if (!this.state.isLoading && this._hasMoreResults) {
        const {
          scrollTop,
          scrollHeight,
          offsetHeight
        } = this._thumbnailsEl
       
        if (scrollTop + offsetHeight + this.endZone > scrollHeight) {
          this.doLoadMore()
        }
      }
    }
    requestAnimationFrame(this._checkLoadMore)
  }

  doLoadMore = async () => {
    if (this.state.queryIsLoading || this._lastLoadedPage === null) return

    const { router } = this.context
      
    const { search, publishWorkflow } = this.state
    const match = router.route.match
    const location = { ...router.route.location }
    location.search = { search, publishWorkflow }
    
    this.setState({ queryIsLoading: true })

    const posts = await MediaPage.fetchData({match, location, router: this.context.router, page: ++this._lastLoadedPage})

    if (posts.length === 0) {
      this._lastLoadedPage = null
    }

    this.setState({
      posts: this.state.posts.concat(posts)
    })

    // Delay so we don't get insane amounts of reloading
    setTimeout(() => this.setState({
      queryIsLoading: false,
      hasMore: Array.isArray(posts) && posts.length >= MediaPage._limit
    }), 500)
  }
 
  didUpdate = (propName, value) => {
    const { search, publishWorkflow } = this.state
    const state = { search, publishWorkflow }
    state[propName] = value

    this.setState(state)


    if (this._typeFilter) {
      clearTimeout(this._typeFilter)
    }

    this._typeFilter = setTimeout(async () => {
      const tmp = {}
      for (let k in state) {
        if (state[k]) tmp[k] = state[k]
      }
      const query = querystring.encode(tmp)

      const { router } = this.context

      // Update URL
      window.history.replaceState(undefined, 'Filter', router.route.location.pathname + (query ? '?' + query : ''))
      // router.history.push(router.route.location.pathname + (query ? '?' + query : ''))
      this._typeFilter = undefined
      
      // Update list
      const match = router.route.match
      const location = { ...router.route.location }
      location.search = state

      this.setState({ queryIsLoading: true })
      
      this._lastLoadedPage = 1
      const posts = await MediaPage.fetchData ({match, location, router})
      
      this.setState({
        posts,
        queryIsLoading: false,
        hasMore: Array.isArray(posts) && posts.length >= MediaPage.limit
      })
    }, 200)
  }
}
