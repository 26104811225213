import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import classnames from 'classnames';
var arrowLookup = {
  down: 'arrowBottomCenter',
  up: 'arrowTopCenter',
  left: 'arrowLeftCenter',
  right: 'arrowRightCenter'
};
/**
 * A message canvas to be used in places where content hasn't been added yet
 * @param {String} param0.className
 * @param {String} param0.arrowPointing place an arrow [up|down|left|right] (optional)
 * @param {object} param0.children content
 */

export default function (_ref) {
  var className = _ref.className,
      arrowPointing = _ref.arrowPointing,
      children = _ref.children;
  var props = {
    className: classnames('MessageCanvas', arrowLookup[arrowPointing], className)
  };
  return normalizeProps(createVNode(1, "div", null, createVNode(1, "div", "body", children, 0), 2, _objectSpread({}, props)));
}