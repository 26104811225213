import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { ListField } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IGuideContentListField = new Interface({
  name: 'IGuideContentListField'
});
export var GuideContentListField = createObjectPrototype({
  implements: [IGuideContentListField],
  extends: [ListField],
  constructor: function constructor(options) {
    this._IListField.constructor.call(this, options);
  }
});