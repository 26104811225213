import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["domRef", "className", "children"],
    _excluded2 = ["domRef", "className", "children"];
import { createVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import classnames from 'classnames';
export var Grid = function Grid(_ref) {
  var domRef = _ref.domRef,
      className = _ref.className,
      children = _ref.children,
      other = _objectWithoutProperties(_ref, _excluded); // TODO: Implement sticky (see Footer.js)


  var props = _objectSpread({
    className: classnames('grid', className)
  }, other);

  return normalizeProps(createVNode(1, "div", null, children, 0, _objectSpread({}, props), null, function (el) {
    return domRef && domRef(el);
  }));
};
export var GridItem = function GridItem(_ref2) {
  var domRef = _ref2.domRef,
      className = _ref2.className,
      children = _ref2.children,
      other = _objectWithoutProperties(_ref2, _excluded2); // TODO: Implement sticky (see Footer.js)


  var props = _objectSpread({
    className: classnames('grid-item', className)
  }, other);

  return normalizeProps(createVNode(1, "div", null, children, 0, _objectSpread({}, props), null, function (el) {
    return domRef && domRef(el);
  }));
};