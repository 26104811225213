const _typeMapping = {
  'blog': 'Blog',
  'invitation': 'Invitation',
  'user': 'User',
  'guide': 'Guide',
  'post': 'BlogPost',
}

export function typeMapping (inp) {
  return _typeMapping[inp] || inp
}