import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createVNode, createComponentVNode, normalizeProps } from "inferno";
import { HelpMsg, generateId, renderString, interfaces as interfacesFormlib } from 'influence-ux-formlib';
import { SwitchButton } from 'influence-ux-components'; // Placeholder

function ConnectedTitleFieldWidget(_ref) {
  var inputName = _ref.inputName,
      namespace = _ref.namespace,
      options = _ref.options,
      doesNotRenderLabel = _ref.doesNotRenderLabel,
      id = _ref.id,
      propName = _ref.propName,
      value = _ref.value,
      onChange = _ref.onChange;
  var field = this.props.adapter.context;
  var isValid = this.props.validationError || this.props.invariantError ? false : undefined;
  var ariaLabels = {
    'aria-invalid': isValid !== undefined,
    'aria-labelledby': doesNotRenderLabel ? undefined : id,
    'aria-label': doesNotRenderLabel ? renderString(field.label || 'influence-ux-formlib--InputField', options && options.lang, 'Bool Field') : undefined,
    'aria-required': field._isRequired ? field._isRequired : undefined
  };

  if (!value) {
    return null;
  }

  var toggleValue = value.active;
  return createVNode(1, "div", "ConnectedTitleFieldWidget", [normalizeProps(createComponentVNode(2, SwitchButton, _objectSpread({
    "primary": true,
    "isSelected": toggleValue,
    "id": generateId(namespace, '__Field'),
    "textDefault": field.labelInactive,
    "textSelected": field.labelActive,
    "name": inputName,
    "valid": isValid
  }, ariaLabels, {
    "readOnly": field.readOnly,
    "onClick": function onClick(e) {
      e && e.preventDefault();
      onChange(propName, Object.assign({}, value, {
        active: !toggleValue
      }));
    }
  }))), toggleValue ? createComponentVNode(2, HelpMsg, {
    "field": field,
    "text": field.helpActive,
    "options": options
  }) : createComponentVNode(2, HelpMsg, {
    "field": field,
    "text": field.helpInactive,
    "options": options
  })], 0);
}

export { ConnectedTitleFieldWidget };