import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Body, Footer } from 'influence-ux-components';
import { IMediaSelectDetails } from 'influence-interfaces/presentation';
import { IUnsplashImage } from '../interface';

function MediaSelectDetails(props) {
  return createVNode(1, "div", "MediaSelectDetails", [createVNode(1, "img", null, null, 1, {
    "width": "100%",
    "src": props.context.urls.small
  }), createComponentVNode(2, Body, {
    children: createVNode(1, "p", null, props.context.description, 0)
  }), props.children && createComponentVNode(2, Footer, {
    children: props.children
  })], 0);
}

new Adapter({
  implements: IMediaSelectDetails,
  adapts: IUnsplashImage,
  Component: MediaSelectDetails
});