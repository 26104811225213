import { Utility } from 'component-registry';
import { IApiClient, IAnalytics } from 'influence-interfaces/presentation';
console.log('[app-external-googleAnalytics] Using dummy analytics utility');
/** @class */

export var AnalyticsUtil = new Utility({
  /** @lends IAnalytics */
  implements: IAnalytics,
  interactiveEvent: function interactiveEvent() {},
  noninteractiveEvent: function noninteractiveEvent() {},
  pageView: function pageView() {},
  screenView: function screenView() {},
  session: function session() {}
});