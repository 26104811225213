import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { RelationField } from 'app-field-RelationField';
var Interface = createInterfaceClass('app-field');
export var IBlogUserRelationField = new Interface({
  name: 'IBlogUserRelationField'
});
export var BlogUserRelationField = createObjectPrototype({
  implements: [IBlogUserRelationField],
  extends: [RelationField],
  lookupType: '/content/User',
  fromString: function fromString(inp) {
    return {
      id: inp._id,
      type: inp._type,
      description: safeJoin([inp.firstName, inp.lastName], ' ')
    };
  }
});