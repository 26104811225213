import { Adapter, globalRegistry } from 'component-registry';
import { IWorkflowFilterField } from 'app-field-WorkflowFilterField';
import WorkflowFilterFieldWidget from './WorkflowFilterFieldWidget';
import { interfaces } from 'influence-ux-formlib';
var IInputFieldWidget = interfaces.IInputFieldWidget;
export * from './WorkflowActions';
export * from './WorkflowStatus';
new Adapter({
  implements: IInputFieldWidget,
  adapts: IWorkflowFilterField,
  Component: WorkflowFilterFieldWidget
});
export { IWorkflowFilterField, WorkflowFilterFieldWidget };