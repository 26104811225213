import { Component } from 'inferno'
import classnames from 'classnames'

import { AvatarImageFieldWidget } from 'app-field-AvatarImageField/widgets'

import { IUser } from 'app-entity-User'
import { IImageHelper } from 'influence-interfaces/presentation'
import { IImage } from 'app-entity-Image'

import './ScrollAwareImage.scss'
import anonAvatar_svg from 'app-widget-common/img/anon_avatar.svg'

function _calcProgress(scrollY, start, end) {
  var scrollProgress = (scrollY > start ? (scrollY - start) / (end - start) : 0)
  if (scrollProgress > 1) {
    scrollProgress = 1
  }
  return scrollProgress
}

export class ScrollAwareImage extends Component {

  constructor(props) {
    super(props)

    var scrollY = (typeof window !== 'undefined' ? window.scrollY : 0)

    this.state = {
      scrollY: scrollY,
      scrollProgress: _calcProgress(scrollY, this.props.start || 0, this.props.end || 0)
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.didScroll, {
      passive: true
    })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.didScroll, {
      passive: true
    })
  }

  render () {
    const scale = (0.5 + (1 - this.state.scrollProgress) * 0.5)
    const styles = {
      transform: 'scale(' + scale + ', ' + scale + ')',
      opacity: (1 - this.state.scrollProgress * 0.5)
    }

    const stylesWrapper = {
      "margin-bottom": '-' + ((1 - scale) * 15) + 'rem'
    }

    let src = this.props.src || anonAvatar_svg
    let srcSet = this.props.srcSet
    if (IImage.providedBy(this.props.image)) {
      const imgHlpr = new IImageHelper(this.props.image)
      src = imgHlpr.getThumbSrc()
      srcSet = imgHlpr.getThumbSrcSet()
    }

    const propName = 'avatarUrl'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return (
      <div id={this.props.id} class={classnames("AvatarWrapper", {
        hidden: !this.props.active
      })} style={stylesWrapper}>
        {this.props.onChange
          ? <AvatarImageFieldWidget className="Avatar"
              style={styles}
              id={this.props.imgId}
              adapter={dummyAdapter}
              value={this.props.image}
              propName={propName}
              onChange={this.props.onChange} />
          : <img id={this.props.imgId} class="Avatar" style={styles} src={src} srcSet={srcSet} />}
      </div>
    )
  }

  didScroll = (e) => {
    if (!this.animFrame) {
      let scrollY = window.scrollY

      // Don't repeatedly fire scroll events if outside boundary
      if ((scrollY < this.props.start && this.state.scrollProgress <= 0) ||
        (scrollY > this.props.end && this.state.scrollProgress >= 1)) return

      this.animFrame = window.requestAnimationFrame(() => {
        let scrollProgress = _calcProgress(scrollY, this.props.start || 0, this.props.end || 0)
        this.setState({
          scrollY: scrollY,
          scrollProgress: scrollProgress
        })
        delete this.animFrame
      })
    }
  }
}