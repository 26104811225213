import { Component, createRef } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from 'influence-i18n'
import querystring from 'querystring'

import {
  Body,
  ButtonBar,
  Form,
  NavButton,
} from 'influence-ux-components'

import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'

import { WorkflowFilterField } from 'app-field-WorkflowFilterField'
import AdminPageTemplate from './AdminPageTemplate'
import { InfinityScroll } from 'app-widget-InfinityScroll'
import { FormRows } from 'influence-ux-formlib'

import { typeMapping } from './_typeMapping'

import 'app-field-WorkflowFilterField/widgets'
import 'app-widget-WorkflowWidgets'

import { IApiClient, IListItem, IPageManager } from 'influence-interfaces/presentation'
import { IInvitationWorkflow } from 'app-workflow-InvitationWorkflow'
import { IPublishWorkflow } from 'app-workflow-PublishWorkflow'
import { IUserWorkflow } from 'app-workflow-UserWorkflow'

import './ListPage.scss'
//import './Dashboard.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export class ListPage extends Component {

  static limit = 30
  _lastLoadedPage = 1
  _scrollRef = null

  constructor (props) {
    super(props)

    this.state = {
      search: undefined,
      invitationWorkflow: undefined,
      userWorkflow: undefined,
      posts: props.fetchData || [],
      queryIsLoading: false,
      hasMore: true
    }

    this._scrollRef = createRef()
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      title: 'Admin @ Memly',
      url: FRONTEND_BASE_URI + location.pathname
    })

    const objType = typeMapping(safeGet(() => match.params.type))
    const { search, invitationWorkflow, publishWorkflow, userWorkflow } = location.search || {}

    let stateFilter
    switch (objType) {
      case 'Blog':
        stateFilter = (publishWorkflow && `publishWorkflow.${publishWorkflow}`) || '!publishWorkflow.trash'
        break
      case 'Invitation':
        stateFilter = (invitationWorkflow && `invitationWorkflow.${invitationWorkflow}`) || '!invitationWorkflow.trash'
        break
      case 'User':
        stateFilter = (userWorkflow && `userWorkflow.${userWorkflow}`)
        break
    }
    
    const query = { 
      search: (search && search.length >= 3 ? { title: search } : undefined), 
      stateFilter
    }

    for (let k in query) {
      if (!query[k]) delete query[k]
    }

    try {
      const URI = `/admin/${objType}`
      const { data } = await new IApiClient({ registry }).query({
        URI,
        query: { 
          query,
          page,
          limit: ListPage.limit
        }
      })
      
      return data.sort((a, b) => b._createdAt - a._createdAt)
    }
    catch (e) {
      return []
    }
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { search, invitationWorkflow, publishWorkflow, userWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    
    let query = { search, invitationWorkflow, publishWorkflow, userWorkflow }
    const posts = (this.props.fetchData !== nextProps.fetchData ? nextProps.fetchData : this.state.posts) || []
    
    this.setState({ posts, ...query })
  }

  loadMore = async () => {
    // load some more
    this._lastLoadedPage = parseInt(this.state.posts.length / 30)
    if (this._lastLoadedPage > 0) {
      this.setState({ queryIsLoading: true })

      this._lastLoadedPage++

      const { router } = this.context
      const { search, invitationWorkflow, publishWorkflow, userWorkflow } = this.state
      const match = router.route.match
      const location = { ...router.route.location }
      location.search = { search, invitationWorkflow, publishWorkflow, userWorkflow }

      // TODO: Do we really need to pass apiClient?
      const data = await ListPage.fetchData({apiClient: new IApiClient(), match, location, router: this.context.router, page: this._lastLoadedPage})
      this.setState({
        posts: this.state.posts.concat(data)
      })

      // Delay so we don't get insane amounts of reloading because of
      // delay with reflow (this is actually a bit flaky because images
      // might be loaded too slow)
      setTimeout(() => this.setState({
        queryIsLoading: false,
        hasMore: Array.isArray(data) && data.length >= ListPage.limit
      }), 100) 
    }
  }

  didUpdate = async (propName, value) => {
    if (this._typeFilter) clearTimeout(this._typeFilter)

    const { search, invitationWorkflow, publishWorkflow, userWorkflow } = this.state
    const newState = { search, invitationWorkflow, publishWorkflow, userWorkflow }
    newState[propName] = value
    this.setState(newState)

    const { router } = this.context

    // Update URL
    // Only add stuff that has a value
    const searchQ = {}
    for (let k in newState) {
      if (newState[k]) searchQ[k] = newState[k]
    }
    const query = querystring.encode(searchQ)
    router.history.replace(router.route.location.pathname + (query ? '?' + query : ''))
    
    // Reload after 200ms delay
    this._typeFilter = setTimeout(async () => {
      this._typeFilter = undefined

      const { router } = this.context
      
      // Update list
      const match = router.route.match
      const location = { ...router.route.location }
      location.search = searchQ

      this.setState({ queryIsLoading: true })
      
      const posts = await ListPage.fetchData ({match, location, router})
      
      this.setState({
        posts,
        queryIsLoading: false,
        hasMore: Array.isArray(posts) && posts.length >= ListPage.limit
      })
    }, 300)
  }

  renderActionbarContent () {
    const listPageType = this.props.match.params.type

    switch (listPageType) {
      case 'invitation':
        return (<ButtonBar inline>
          <NavButton link className="action" to={`/admin/${listPageType}/create`}>{i18n('ListAction-createInvitation', '+ Skapa en ny inbjudan')}</NavButton>
        </ButtonBar>)
      case 'blog':
        return (<ButtonBar inline>
          <NavButton link className="action" to={`/admin/${listPageType}/create`}>{i18n('ListAction-createBlog', '+ Skapa en ny blog')}</NavButton>
        </ButtonBar>)
      case 'user':
        return (<ButtonBar inline>
          <NavButton link className="action" to={`/admin/${listPageType}/create`}>{i18n('ListAction-createUser', '+ Skapa en ny användare')}</NavButton>
        </ButtonBar>)
    }
  }

  renderFilterForm () {
    const filterValue = this.state

    let { type } = this.props.match.params || {}

    const filterSchema = new Schema('Filter Schema', {
      search: new TextField({
        label: 'Filter list:',
        placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
      })
    })

    switch (type) {
      case 'blog':
        filterSchema._fields['publishWorkflow'] = new WorkflowFilterField({
          workflowInterface: IPublishWorkflow
        })
        break
      case 'invitation':
        filterSchema._fields['invitationWorkflow'] = new WorkflowFilterField({
          workflowInterface: IInvitationWorkflow
        })
        break
      case 'user':
        filterSchema._fields['userWorkflow'] = new WorkflowFilterField({
          workflowInterface: IUserWorkflow
        })
        break
    }

    return (
      <Form key={type} onSubmit={(e) => e.preventDefault()}>
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }

  render () {
    let { type } = this.props.match.params || {}

    return (
      <AdminPageTemplate
        className={'ListPage-' + type}
        filter={this.renderFilterForm()}
        actionbar={this.renderActionbarContent()}>
        <Body ref={this._scrollRef}>
          <InfinityScroll
            scrollRef={this._scrollRef}
            className="List-Container"
            hasMore={this.state.hasMore}
            queryIsLoading={this.state.queryIsLoading}
            inBackground={false}
            loadMorePadding={100}
            onLoadMore={this.loadMore}>
          {this.state.posts.map((post) => {
            const ListItem = new IListItem(post).Component
            return <ListItem key={post._id} context={post} />
          })}
          </InfinityScroll>
        </Body>
      </AdminPageTemplate>
    )
  }
}
