/**
 * Inject a named param type=[...]
 * @param {*} type 
 * @param {*} fn 
 * @returns 
 */
 export function injectType (type, fn) {
  return (namedArgs) => {
    const { match } = namedArgs
    match.params.type = type
    return fn.call(fn, namedArgs)
  }
}

/**
 * Inject a named param cardTypeSlug=[...] picked from an noname param
 * @param {*} nonameParam anything within () in the path is passed as a param with index as key
 * @param {*} fn the actual function to be invoked
 * @returns 
 */
 export function injectCardTypeSlug (nonameParam, fn) {
  return (namedArgs) => {
    const { match } = namedArgs
    match.params.cardTypeSlug = match.params[nonameParam]
    return fn.call(fn, namedArgs)
  }
}