import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, createVNode(32, "g", null, createVNode(32, "g", null, [createVNode(32, "path", null, null, 1, {
    "d": "M106.001723,53.0312229 C111.524571,53.0312229 116.001723,57.5083754 116.001723,63.0312229 L116.00159,64.6495667 C112.239073,93.0817771 87.7057793,115.031223 58.0017232,115.031223 C28.2927771,115.031223 3.75629591,93.0745497 -1.88737914e-15,64.6355244 L0.00172315599,63.0312229 C0.00172315599,57.5083754 4.47887566,53.0312229 10.0017232,53.0312229 L106.001723,53.0312229 Z M105.948012,59.6340722 L10.6198872,59.6340722 L10.6198872,59.6340722 C6.78297316,59.6340722 6.78297316,60.767551 6.78297316,62.9687229 C6.78297316,64.4361708 7.33798618,67.1446069 8.44801222,71.0940311 C21.2520487,72.8294565 30.4698221,75.5484293 36.1013325,79.2509495 C44.5485982,84.8047298 52.7898325,90.4501682 58.5022232,92.1103245 C64.2146138,93.7704807 78.0105122,93.8105198 84.3864888,90.220676 C90.7624653,86.6308323 98.8962544,82.0864687 105.948012,79.2509495 C106.216241,79.2600641 107.635838,73.8326552 110.206801,62.9687229 C110.363702,60.7456225 108.944106,59.6340722 105.948012,59.6340722 Z"
  }), createVNode(32, "path", null, null, 1, {
    "d": "M110.911626,0.878679656 L115.154266,5.12132034 C116.325839,6.29289322 116.325839,8.19238816 115.154266,9.36396103 L85.6140781,38.9038459 C85.6514865,39.039429 85.6876026,39.1766146 85.7224263,39.3154026 C87.8698872,47.8739964 81.2360982,61.3280979 76.2204732,69.2607151 C60.5212544,94.064426 41.8620747,83.1878736 37.7214497,79.2880589 C33.5808247,75.3882441 24.56227,60.7441135 39.7009419,45.7402073 C51.6475565,35.1594781 63.3721659,30.0204807 74.87477,30.3232151 C75.6129013,30.3600015 76.3156578,30.4406505 76.9830395,30.565162 L106.668985,0.878679656 C107.840558,-0.292893219 109.740053,-0.292893219 110.911626,0.878679656 Z M78.5921443,52.666218 C78.5921443,52.666218 72.0802602,62.071042 65.1762441,69.0175765 C58.6477008,75.5863259 51.7010886,79.7199872 51.8631988,79.6520859 C71.1292194,79.9883755 78.5921443,52.666218 78.5921443,52.666218 Z M77.6528611,43.4192499 C54.4842123,65.3958525 42.9738883,76.4532621 43.1218893,76.5914785 C48.4819127,76.6850381 55.1037492,74.1822545 62.7464318,65.7376914 C67.8415535,60.1079826 72.8103633,52.6685021 77.6528611,43.4192499 Z M73.81227,38.5165745 C64.4799133,43.196913 56.9548482,48.0351292 51.2370747,53.0312229 C42.6604146,60.5253635 40.0424452,67.102512 40.0424452,72.4633518 C40.1780576,72.6137425 51.4346659,61.29815 73.81227,38.5165745 Z M63.9646138,37.665012 L63.9321151,37.6734363 C62.8776634,37.9493236 36.5163716,45.035342 36.5163716,63.9189182 C36.4456513,64.0798187 40.6999182,57.2064069 47.381606,50.7934983 C54.4475741,44.0117676 63.9646138,37.665012 63.9646138,37.665012 Z"
  })], 4, {
    "id": "Group",
    "transform": "translate(4.498277, 5.968777)",
    "fill": "currentColor"
  }), 2, {
    "id": "recipe",
    "stroke": "none",
    "stroke-width": "1",
    "fill-rule": "evenodd"
  }), 2, {
    "width": "126px",
    "height": "126px",
    "viewBox": "0 0 126 126"
  });
}