import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { RelationField } from 'app-field-RelationField';
var Interface = createInterfaceClass('app-field');
export var IGuidePageRelationField = new Interface({
  name: 'IGuidePageRelationField'
});
export var GuidePageRelationField = createObjectPrototype({
  implements: [IGuidePageRelationField],
  extends: [RelationField],
  lookupType: '/content/GuidePage',
  hasRoleManager: true,
  fromString: function fromString(inp) {
    return {
      id: inp._id,
      type: inp._type,
      description: inp.title
    };
  }
});