import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, BoolField, SelectField, TextField, TextAreaField, ListField, ObjectField, i18n } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { ImageListField } from 'app-field-ImageListField';
import { GalleryLayoutField } from 'app-field-GalleryLayoutField';
import { ConnectedTitleField } from 'app-field-ConnectedTitleField';
var listSchema = new Schema('ListSchema', {
  image: new ImageField({
    label: i18n('ICard-title-label', 'Title'),
    placeholder: i18n('ICard-image-placeholder', 'Drag and drop...'),
    help: i18n('ICard-image-help', 'Only JPEG or PNG. Max size 5Mb.')
  }),
  description: new TextField({
    label: i18n('ListSchema-description-label', 'Description'),
    required: true
  }),
  url: new TextField({
    label: i18n('ListSchema-url-label', 'URL'),
    required: false
  })
});
var IListCard = new Interface({
  name: 'IListCard',
  schema: new Schema('ListCard Schema', {
    title: new TextField({
      label: i18n('IListCard-title-label', 'Title'),
      placeholder: i18n('IListCard-title-placeholder', 'Type here...'),
      required: true
    }),
    connectedTitle: new ConnectedTitleField({
      labelActive: 'post title',
      labelInactive: 'card title',
      helpActive: i18n('ICard-connectedTitle-helpActive', 'You are connected'),
      helpInactive: i18n('ICard-connectedTitle-helpInactive', 'Now you are disconnected')
    }),
    images: new ImageListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    }),
    shortDescription: new TextAreaField({
      label: i18n('ICard-shortDescription-label', 'Short Description'),
      required: true
    }),
    listLayoutType: new SelectField({
      label: i18n('IListCard-listLayoutType-label', 'List Layout'),
      valueType: new TextField({}),
      options: [{
        name: 'thumbnails',
        title: i18n('IListCard-listLayoutType-option-thumbnail', 'Thumbnail to left')
      }, {
        name: 'headers',
        title: i18n('IListCard-listLayoutType-option-header', 'Header on top')
      }]
    }),
    theList: new ListField({
      label: i18n('IListCard-shortDescription-label', 'The List'),
      valueType: new ObjectField({
        schema: listSchema
      })
    })
  })
});
export { IListCard };