export * from './interface';
import { Adapter } from 'component-registry';
import { IMobiledocField } from '../field';
import MobiledocFieldWidget from './MobiledocFieldWidget';
import { interfaces } from 'influence-ux-formlib';
var IInputFieldWidget = interfaces.IInputFieldWidget;
new Adapter({
  implements: IInputFieldWidget,
  adapts: IMobiledocField,
  Component: MobiledocFieldWidget
});
export { IMobiledocField, MobiledocFieldWidget };