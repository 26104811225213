import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import { render } from 'inferno';
import { createElement } from 'inferno-create-element';
export function cardRenderer(component, innerComponent, deserializeUtil) {
  var _this = this;

  return function (_ref) {
    var env = _ref.env,
        options = _ref.options,
        payload = _ref.payload;
    var targetNode = document.createElement('div');
    var didRender = env.didRender,
        onTeardown = env.onTeardown;
    didRender(function () {
      var context = _this.context;
      payload = _objectSpread({}, payload); // deref payload
      // The deserializeUtil allows you to set types properly on content that has been pasted

      if (deserializeUtil) {
        payload = deserializeUtil.deserialize(payload);
      }

      var cardProps = options.cardProps; // This is to allow the rendered content to stay visible during editing
      // so we don't break the content when editing by means of a modal

      var children;

      if (innerComponent) {
        children = createElement(innerComponent, _objectSpread(_objectSpread(_objectSpread({}, env), cardProps), {}, {
          payload: payload,
          context: context
        }));
      }

      var element = createElement(component, _objectSpread(_objectSpread(_objectSpread({}, env), cardProps), {}, {
        payload: payload,
        context: context
      }), children);
      render(element, targetNode);
    });
    onTeardown(function () {
      return render(null, targetNode);
    });
    return targetNode;
  };
}
export function utilityToCard(utility, cardRenderer, deserializeUtil) {
  return {
    name: utility._name,
    type: utility.type,
    render: cardRenderer(utility.RenderComponent, undefined, deserializeUtil),
    edit: cardRenderer(utility.EditComponent, utility.RenderComponent, deserializeUtil)
  };
}