import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { AnyOf } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IGuideContentListRowField = new Interface({
  name: 'IGuideContentListRowField'
});
export var GuideContentListRowField = createObjectPrototype({
  implements: [IGuideContentListRowField],
  extends: [AnyOf],
  constructor: function constructor(options) {
    options['placeholder'] = 'Type here...';

    this._IAnyOf.constructor.call(this, options);
  }
});