import { createInterfaceClass } from 'component-registry';
import { i18n, Schema, TextField, SelectField, ListField, ObjectField } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { BlogUserRelationField } from 'app-field-BlogUserRelationField';
import { BlogArticleRelationField } from 'app-field-BlogArticleRelationField';
import { BlogTitleField } from 'app-field-BlogTitleField';
var Interface = createInterfaceClass('app-entity');
var principalRoleSchema = new Schema('Principal Role Schema', {
  principal: new BlogUserRelationField({
    label: i18n('PrincipalRoleSchema-principalId-label', 'User')
  }),
  role: new SelectField({
    label: i18n('PrincipalRoleSchema-role-label', 'Role'),
    options: [{
      name: '{id}.admin',
      title: i18n('PrincipalRoleSchema-role-option-admin', 'Admin')
    }, {
      name: '{id}.editor',
      title: i18n('PrincipalRoleSchema-role-option-editor', 'Editor')
    }, {
      name: '{id}.writer',
      title: i18n('PrincipalRoleSchema-role-option-writer', 'Writer')
    }],
    valueType: new TextField({})
  })
});
export var IBlog = new Interface({
  name: 'IBlog',
  schema: new Schema('Blog Schema', {
    title: new BlogTitleField({
      label: i18n('IBlog-title-label', 'Name of Blog'),
      help: i18n('IBlog-title-help', 'This name is similar to the Instagram user name, so no spaces and lowercase')
    }),
    principals: new ListField({
      label: i18n('IBlog-users-label', 'Users'),
      valueType: new ObjectField({
        schema: principalRoleSchema,
        required: true
      })
    }),
    heroImageUrl: new ImageField({
      label: i18n('IBlog-heroImageUrl-label', 'Bakgrundsbild'),
      placeholder: i18n('ImageField-placeholder', 'Drag och släpp här...'),
      help: i18n('IBlog-heroImageUrl-help', 'Bara rimliga bilder')
    }),
    layoutType: new SelectField({
      label: i18n('IBlog-layoutType-label', 'Layout'),
      valueType: new TextField({}),
      options: [{
        name: 'normal',
        title: i18n('IBlog-layoutType-option-normal', 'Normal')
      }, {
        name: 'cover',
        title: i18n('IBlog-layoutType-option-cover', 'Cover')
      }]
    }),
    profilePost: new BlogArticleRelationField({
      label: i18n('IBlog-profilePost-label', 'Id of Profile Post'),
      placeholder: i18n('IBlog-profilePost-placeholder', 'Type title of post...'),
      help: i18n('IBlog-profilePost-help', 'Some instructions...')
    })
  })
}); // TODO: Add invariant to title

/*
IBlog.schema.addInvariant((data) => {
  [...]
})
*/