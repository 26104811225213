import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import { IPublicForm, IImageHelper } from 'influence-interfaces/presentation'
import { IApiClient, ISessionManager, IPageManager } from 'influence-interfaces/presentation'


import 'app-field-WorkflowFilterField/widgets'
import { Animated } from 'inferno-animation'
import { convertCardTypeToSlug, convertCardSlugToType } from 'app-card-Card/widgets'

import 'app-card-Card/widgets/common.scss'
import 'app-card-RecipeCard/widgets/RecipeCard.scss'

function _getPostId(post) {
  return post && (post._pathId || post._id)
}

function _getPostSlug(post) {
  if (post && post._slug) {
    return '/' + post._slug
  }
  else {
    return ''
  }
}

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

function _setMetaData (registry, card) {
  const blog = new ISessionManager({ registry }).getCurrentRoleManager()
  
  const metaData = {
    title: safeGet(() => (card.title || card._type) + `(by ${blog.title})`, blog.title),
    description: safeGet(() => card.shortDescription || '', ''),
    // TODO: This should probably use getCardPageUrl
    url: `${FRONTEND_BASE_URI}/${blog.title}` + safeGet(() => `/Cards/${convertCardTypeToSlug(card._type)}/${card._pathId}/${card._slug}`, '')
  }

  // If the post has no image set, use the first card with images
  if (Array.isArray(card.images)) {
    // Get sharing image
    const images = card.images.filter(img => img) // no undefined
    const imgHlpr = new IImageHelper(images[0])
    const { width, height } = images[0].rendered[0]
    const imageMetaData = {
      url: imgHlpr.getSrc(width),
      width,
      height
    }
    metaData['image'] = imageMetaData
  }

  new IPageManager({ registry }).setMetaData(metaData)
  return metaData
}

export default class CardPage extends Component {

  static async fetchData ({registry, match }) {
    const { cardTypeSlug, cardId } = match.params
    const cardType = convertCardSlugToType(cardTypeSlug)
    const URI = `/content/${cardType}/${cardId}`

    const { data } = await new IApiClient({ registry }).query({
      URI
    })
    return data
  }

  render ({ fetchData: card }) {
    if (card) {
      const LiveItem = new IPublicForm(card._type).Component
      return (
        <Animated key="me" className="ViewPage" prefix="PageNav">
          <LiveItem context={card} />
        </Animated>
  
      )
    }
  }
}
