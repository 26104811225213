import { createObjectPrototype, Utility } from 'component-registry';
import { TimedPublishing } from 'app-aspect-TimedPublishing';
import { IGuideChapter } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var GuideChapter = createObjectPrototype({
  implements: [IGuideChapter],
  constructor: function constructor(params) {
    this._type = 'GuideChapter'; // Convert start and end date to real Date objects

    if (Array.isArray(params.content)) {
      params.content = params.content.map(function (p) {
        if (p.type === 'GuidePage') {
          var tp = new TimedPublishing(p);
          return Object.assign(p, tp);
        }

        return p;
      });
    }
  }
});
export default GuideChapter;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'GuideChapter',
  getPrototype: function getPrototype() {
    return GuideChapter;
  },
  getObject: function getObject(data) {
    return new GuideChapter(data);
  }
});