import { Component } from 'inferno'
import { HeroBody } from '../../../widgets/Hero'
import { IPageManager, ISessionManager } from 'influence-interfaces/presentation'
import SitePageFooter from '../../../widgets/SitePageFooter'
import { LogoutMenu } from '../../../widgets/MainMenu'
import classnames from 'classnames'

import './index.scss'
import flowers_jpg from '../../../img/frontpage/collage_inspire_to_create/flowers.jpg'
import girl_jpg from '../../../img/frontpage/collage_inspire_to_create/girl.jpg'
import bedroom_jpg from '../../../img/frontpage/collage_inspire_to_create/bedroom.jpg'
import makeup_jpg from '../../../img/frontpage/collage_inspire_to_create/makeup.jpg'
import jewlery_jpg from '../../../img/frontpage/collage_inspire_to_create/jewlery.jpg'
import breakfast_jpg from '../../../img/frontpage/collage_inspire_to_create/breakfast.jpg'

import dive_jpg from '../../../img/frontpage/collage_show_the_world/dive.jpg'
import new_york_jpg from '../../../img/frontpage/collage_show_the_world/new_york.jpg'
import city_jpg from '../../../img/frontpage/collage_show_the_world/city.jpg'
import mountains_jpg from '../../../img/frontpage/collage_show_the_world/mountains.jpg'
import sand_jpg from '../../../img/frontpage/collage_show_the_world/sand.jpg'
import train_jpg from '../../../img/frontpage/collage_show_the_world/train.jpg'

import pregnant_jpg from '../../../img/frontpage/collage_share_your_stories/pregnant.jpg'
import kiss_jpg from '../../../img/frontpage/collage_share_your_stories/kiss.jpg'
import girls_jpg from '../../../img/frontpage/collage_share_your_stories/girls.jpg'
import sitting_jpg from '../../../img/frontpage/collage_share_your_stories/sitting.jpg'
import flower_girls_jpg from '../../../img/frontpage/collage_share_your_stories/flowers.jpg'
import summer_drink_jpg from '../../../img/frontpage/collage_share_your_stories/summer_drink.jpg'

import logo_inverse_svg from 'app-widget-common/img/logo/logo-inverse.svg'
import { safeGet } from 'safe-utils'
import { AnimatedImage } from './AnimatedImage'

function Stripe (props)  {
  return (
    <p><span className="HeroBody-Stripe">{props.children}</span></p>
  )
}
function StripeHeader (props)  {
  return (
    <h2><span className={classnames("HeroBody-Stripe", props && props.className)}>{props.children}</span></h2>
  )
}

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

class TypeWriter extends Component {
  state = {
    characters: []
  }

  componentDidMount() {
    this.nextChar()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.text !== this.props.text) {
      this.setState({
        characters: []
      })
      setTimeout(this.nextChar, 16)
    }
  }

  nextChar = () => {
    let showChars = this.props.text.substring(0, this.state.characters.length + 1)
    this.setState({
      characters: showChars.split('')
    })
    if (showChars.length < this.props.text.length) {
      setTimeout(this.nextChar, Math.round(this.props.time / this.props.text.length))
    }
  }

  render () {
    // return this.state.characters.map((char, index) => <AnimatedImage tag="span" key={char + '_' + index} prefix="TypeWriter--Animation">{char}</AnimatedImage>)
    return this.state.characters
  }
}

export default class Page extends Component {

  state = {
    showImages: 0,
  }

  taglines = [
    'Create to Inspire',
    'Show the World',
    'Share Your Stories'
  ]

  
    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'memly.io - Inspire to create',
        description: `Posting pictures and stories is fun and all but you have more to share. Welcome to a new content creation experience!`,
        url: FRONTEND_BASE_URI
      })
    }

    componentWillReceiveProps() {
      if (typeof window !== 'undefined') {
        const currentUser = new ISessionManager().getCurrentUser()
        if (safeGet(() => currentUser.mainBlogName)) {
          return window.location.pathname = '/' + currentUser.mainBlogName
        }
      }
    }

    componentDidMount() {
      setTimeout(this.doProgress, 8000)
    }

    doProgress = (e) => {
      this.setState({
        showImages: ++this.state.showImages % 3
      })
      setTimeout(this.doProgress, 8000)
    }
 
    renderImagesForStories() {
      let active = this.state.showImages == 2;
      return (
        <div className="StartPage-HeroImagesContainer">
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="pregnant" animationPrefix="FrontTopLeft--Animation" src={pregnant_jpg} active={active} />
            <FrontPageImage key="kiss" animationPrefix="FrontBottomLeft--Animation" src={kiss_jpg} active={active} />
          </div>
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="girls" animationPrefix="FrontTop--Animation" src={girls_jpg} active={active} />
            <FrontPageImage key="sitting" animationPrefix="FrontBottom--Animation" src={sitting_jpg} active={active} />
          </div>
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="flower_girls" animationPrefix="FrontTopRight--Animation" src={flower_girls_jpg} active={active} />
            <FrontPageImage key="summer_drink" animationPrefix="FrontBottomRight--Animation" src={summer_drink_jpg} active={active} />
          </div>
        </div>
      )
    }
 
    renderImagesForShow() {
      let active = this.state.showImages == 1;
      return (
        <div className="StartPage-HeroImagesContainer">
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="dive" animationPrefix="FrontTopLeft--Animation" src={dive_jpg} active={active} />
            <FrontPageImage key="new_york" animationPrefix="FrontBottomLeft--Animation" src={new_york_jpg} active={active} />
          </div>
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="city" animationPrefix="FrontTop--Animation" src={city_jpg} active={active} />
            <FrontPageImage key="mountains" animationPrefix="FrontBottom--Animation" src={mountains_jpg} active={active} />
          </div>
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="sand" animationPrefix="FrontTopRight--Animation" src={sand_jpg} active={active} />
            <FrontPageImage key="train" animationPrefix="FrontBottomRight--Animation" src={train_jpg} active={active} />
          </div>
        </div>
      )
    }
    
    renderImagesForCreate() {
      let active = this.state.showImages == 0;
      return (
        <div className="StartPage-HeroImagesContainer">
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="flowers" animationPrefix="FrontTopLeft--Animation" src={flowers_jpg} active={active} />
            <FrontPageImage key="makeup" animationPrefix="FrontBottomLeft--Animation" src={makeup_jpg} active={active} />
          </div>
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="girl" animationPrefix="FrontTop--Animation" src={girl_jpg} active={active} />
            <FrontPageImage key="jewlery" animationPrefix="FrontBottom--Animation" src={jewlery_jpg} active={active} />
          </div>
          <div className="StartPage-HeroImageCol">
            <FrontPageImage key="bedroom" animationPrefix="FrontTopRight--Animation" src={bedroom_jpg} active={active} />
            <FrontPageImage key="breakfast" animationPrefix="FrontBottomRight--Animation" src={breakfast_jpg} active={active} />
          </div>
        </div>
      )
    }

    render () {
        return (
            <div className="Fullpage StartPage">
              <LogoutMenu />
              <div className="HeroContainer">
                {this.renderImagesForCreate()}
                {this.renderImagesForShow()}
                {this.renderImagesForStories()}
                <div className="HeroOverlay" />
                <HeroBody className="Hero-Influencer">
                  <StripeHeader className={'Tagline_' + this.state.showImages}>
                    <TypeWriter text={this.taglines[this.state.showImages]} time={950} />
                  </StripeHeader>
                  <img id="Page-Logo" src={logo_inverse_svg} />
                  {/*<Button tag={Link} to="register_2"><Stripe>Sign up now »</Stripe></Button>*/}
                </HeroBody>
              </div>
              <SitePageFooter />
            </div>
        )
    }
}

function FrontPageImage({className, key, animationPrefix, src, active}) {
  return (
    <div className={classnames("StartPage-HeroImage", className)}>
      {active && <AnimatedImage tag="img" key={key + '-visible'} className="visible" src={src} prefix={animationPrefix} />}
      <img key={key + '-hidden'} className="hidden" src={src} />
    </div>
  )
}


