import { createComponentVNode } from "inferno";

/*

    To use this input widget adapter you need to register it with your
    adapter registry.

*/
import { Adapter } from 'component-registry';
import { i18n } from 'influence-i18n';
import { Filter } from 'influence-ux-components';
import { interfaces } from 'influence-ux-formlib';
var IInputFieldWidget = interfaces.IInputFieldWidget;
import { IWorkflowFilterField } from '../field';
import './index.scss';
export default function WorkflowFilterFieldWidget(props) {
  var field = props.adapter.context;
  var workflowStates = field.workflowInterface.schema.workflowStates;
  var filterOptions = [{
    value: undefined,
    label: i18n('IWorkflowFilter-all-label', 'Alla')
  }].concat(Object.keys(workflowStates).map(function (key) {
    return {
      value: key,
      label: i18n(workflowStates[key].title)
    };
  }));
  return createComponentVNode(2, Filter, {
    "value": props.value,
    "options": filterOptions,
    "onChange": function onChange(value) {
      return props.onChange(props.propName, value);
    }
  });
}
new Adapter({
  implements: IInputFieldWidget,
  adapts: IWorkflowFilterField,
  Component: WorkflowFilterFieldWidget
});