import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Link } from 'inferno-router';
import { IListItem } from 'influence-interfaces/presentation';
import { IMedia } from '../interface'; // TODO: Fix these: import '../common/ListItem.scss'

function ListItem(props, context) {
  return createVNode(1, "div", "list-item media", [createVNode(1, "img", "thumbnail mr-3", null, 1, {
    "src": props.context.imageUrl
  }), createVNode(1, "div", "media-body list-item-body", createComponentVNode(2, Link, {
    "to": "/edit/".concat(props.context._roleManagerId, "/Media/").concat(props.context._id),
    children: props.context.shortDescription
  }), 2)], 4);
}

new Adapter({
  implements: IListItem,
  adapts: IMedia,
  Component: ListItem
});