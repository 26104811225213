import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createVNode, normalizeProps } from "inferno";
export default function (props) {
  return normalizeProps(createVNode(32, "svg", null, createVNode(32, "g", null, createVNode(32, "polygon", null, null, 1, {
    "id": "Triangle",
    "fill": "currentColor",
    "transform": "translate(68.000000, 63.000000) rotate(90.000000) translate(-68.000000, -63.000000) ",
    "points": "68 44 102 82 34 82"
  }), 2, {
    "id": "arrow",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  }), 2, _objectSpread({
    "width": "126px",
    "height": "126px",
    "viewBox": "0 0 126 126"
  }, props)));
}