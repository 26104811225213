import { Component } from 'inferno'
import classnames from 'classnames'
import { Page } from 'influence-ux-components'

import { typeMapping } from './_typeMapping'

import { ICreateForm } from 'influence-interfaces/presentation'
import { ISessionManager } from 'influence-interfaces/presentation'
import './Edit.scss'

export default class CreatePage extends Component {

    static async fetchData ({registry, match, location}, context) {
      
    }

    render (props, state, context) {
      // TODO: This is also a hack because theh roleManager isn't passed to 
      // context until really late. The entire flow should be refactored to be 
      // easier to follow
      if (!new ISessionManager({ registry: context.registry }).getCurrentRoleManager()) return

      const { type } = context.router.route.match.params
      let EditForm = new ICreateForm(typeMapping(type)).Component
      
      return (
        <Page key="page" className={classnames("EditPage", type)} animationPrefix="PageNav">
          <EditForm {...this.props} />
        </Page>
      )
    }
}
