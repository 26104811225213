import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, [createVNode(32, "rect", "inverse", null, 1, {
    "stroke": "currentColor",
    "stroke-width": "3",
    "x": "1.5",
    "y": "1.5",
    "width": "123",
    "height": "123",
    "rx": "6"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "17",
    "y": "22",
    "width": "15",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "17",
    "y": "94",
    "width": "15",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "17",
    "y": "46",
    "width": "15",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "17",
    "y": "70",
    "width": "15",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "44",
    "y": "22",
    "width": "65",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "44",
    "y": "94",
    "width": "65",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "44",
    "y": "46",
    "width": "65",
    "height": "9"
  }), createVNode(32, "rect", null, null, 1, {
    "fill": "currentColor",
    "x": "44",
    "y": "70",
    "width": "65",
    "height": "9"
  })], 4, {
    "width": "126px",
    "height": "126px",
    "viewBox": "0 0 126 126"
  });
}