import { createComponentVNode } from "inferno";
import { Filter } from 'influence-ux-components';
import { i18n } from 'influence-i18n';
export default function WorkflowFilterFieldWidget(props) {
  var field = props.adapter.context;
  var workflowStates = field.workflowInterface.schema.workflowStates;
  var filterOptions = [{
    value: undefined,
    label: i18n('IWorkflowFilter-all-label', 'Alla')
  }].concat(Object.keys(workflowStates).map(function (key) {
    return {
      value: key,
      label: i18n(workflowStates[key].title)
    };
  }));
  return createComponentVNode(2, Filter, {
    "value": props.value,
    "options": filterOptions,
    "onChange": function onChange(value) {
      return props.onChange(props.propName, value);
    }
  });
}