import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { RelationField } from 'app-field-RelationField';
var Interface = createInterfaceClass('app-field');
export var IBlogRelationField = new Interface({
  name: 'IBlogRelationField'
});
export var BlogRelationField = createObjectPrototype({
  implements: [IBlogRelationField],
  extends: [RelationField],
  lookupType: '/content/Blog',
  fromString: function fromString(inp) {
    return {
      id: inp._id,
      type: inp._type,
      description: inp.title
    };
  }
});