import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createVNode, normalizeProps } from "inferno";
export default function (props) {
  return normalizeProps(createVNode(32, "svg", null, [createVNode(32, "defs", null, createVNode(32, "path", null, null, 1, {
    "d": "M90.5,16 C131.645214,16 165,49.3547861 165,90.5 C165,131.645214 131.645214,165 90.5,165 C49.3547861,165 16,131.645214 16,90.5 C16,49.3547861 49.3547861,16 90.5,16 Z M90.5,30 C57.0867726,30 30,57.0867726 30,90.5 C30,123.913227 57.0867726,151 90.5,151 C123.913227,151 151,123.913227 151,90.5 C151,57.0867726 123.913227,30 90.5,30 Z M96.1818182,42 C97.1859723,42 98,42.8140277 98,43.8181818 L98,92.528 L127.146552,109.356207 C128.016174,109.858284 128.314129,110.970266 127.812052,111.839889 L121.630234,122.547112 C121.128157,123.416735 120.016174,123.71469 119.146552,123.212613 L83.3245916,102.530795 C82.8817221,102.275104 82.5871159,101.861233 82.470935,101.401748 C82.1780228,101.079418 82,100.651408 82,100.181818 L82,43.8181818 C82,42.8140277 82.8140277,42 83.8181818,42 L96.1818182,42 Z",
    "id": "iconClockPath"
  }), 2), createVNode(32, "g", null, [createVNode(32, "mask", null, createVNode(32, "use", null, null, 1, {
    "href": "#iconClockPath"
  }), 2, {
    "id": "iconClockMask",
    "fill": "white"
  }), createVNode(32, "g", null, createVNode(32, "rect", null, null, 1, {
    "x": "0",
    "y": "0",
    "width": "180",
    "height": "180"
  }), 2, {
    "mask": "url(#iconClockMask)",
    "fill": "currentColor"
  })], 4, {
    "id": "clock",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  })], 4, _objectSpread({
    "width": "180px",
    "height": "180px",
    "viewBox": "0 0 180 180"
  }, props)));
}