import { Component, createRef } from 'inferno'
import { safeGet } from 'safe-utils'
import {
  Body,
  Button,
  Form,
  Sticky
} from 'influence-ux-components'
import { Animated } from 'inferno-animation'
import { FormRows } from 'influence-ux-formlib'
import { StickyRow } from 'app-widget-StickyRow'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField/widgets'

import { IUser } from 'app-entity-User'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { INotificationManager } from 'influence-interfaces/presentation'

import 'app-field-ImageField/widgets'
import 'app-entity-User/widgets/EditForm.scss'
import 'app-widget-StickyRow/index.scss'
import './RegisterStep.scss'
import logo_colored_svg from 'app-widget-common/img/logo/logo-colored.svg'

const selectFields = ['firstName', 'lastName', 'userName', 'city', 'country', 'gender', 'birthDate', 'occupation']
const omitFields = []


export default class Page extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: new ISessionManager({ registry: context.registry }).getCurrentUser() || {},
      validationErrors: undefined,
      actionBarBoundary: {top: 0, bottom: 0}
    }

    this._scrollRef = createRef()

    this.didUpdate = this.didUpdate.bind(this)
    this.doSubmit = this.doSubmit.bind(this)
  }

  static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
        URI: `/session`
    })
    
    return data
  }

  componentDidUpdate(prevProps, prevState) {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    if (safeGet(() => prevState.value._id !== currentUser._id)) {
      this.setState({
        value: currentUser
      })
    }
  }

  render (props, state, context) { 
    return (
        <Animated domRef={el => this._scrollRef.current = el} className="Fullpage Onboarding" key="onboarding-4" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
          <div id="Page-Logo"><img className="logo" src={logo_colored_svg} /></div>
          {this.renderAvatar()}
          <div className="Onboarding-Content">
            <Body>
              <Form onSubmit={this.doSubmit}>
                <h2>Great choice! Now the fun begins.</h2>
                <FormRows
                  schema={IUser.schema}
                  validationErrors={this.state.validationErrors}
                  value={this.state.value}
                  selectFields={selectFields}
                  omitFields={omitFields}
                  onChange={this.didUpdate} />
                <Sticky className="Onboarding-ActionBar" stickToBottom>
                  <Button primary
                    onClick={this.doSubmit} href="#instagram">Move on...</Button>
                </Sticky>
              </Form>
            </Body>
          </div>
        </Animated>
    )
  }

  renderAvatar() {
    const propName = 'avatarUrl'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      adapter={dummyAdapter}
      value={this.state.value[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  didUpdate = (propName, value) => {
    const newVal = this.state.value
    newVal[propName] = value

    let validationErrors
    if (this.state.submitted) {
      validationErrors = IUser.schema.validate(this.state.value, {
        selectFields,
        omitFields
      })
    }

    this.setState({
        value: newVal,
        validationErrors
    })
  }

  doSubmit = (e) => {
    e.preventDefault()

    let validationErrors = IUser.schema.validate(this.state.value, {
      selectFields,
      omitFields
    })
    if (validationErrors) {
      return this.setState({
        validationErrors,
        submitted: true
      })
    }
    
    new IApiClient().update({
        URI: `/content/User/${new ISessionManager().getCurrentUser()._id}`,
        data: this.state.value,
        invalidate: ['/content/User', '/session']
    }).then(({data}) => {
        // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
        new ISessionManager().refreshCurrentUser()
        new INotificationManager().showSuccessMessage()
        this.context.router.history.push('/register_5')
    })
}
}

