import { Adapter } from 'component-registry';
import { IImageListField } from '../field';
import { ImageListFieldWidget } from './ImageListFieldWidget';
import { interfaces } from 'influence-ux-formlib';
import 'app-field-ImageField/widgets/FileUploadUtil';
var IInputFieldWidget = interfaces.IInputFieldWidget;
new Adapter({
  implements: IInputFieldWidget,
  adapts: IImageListField,
  Component: ImageListFieldWidget
});
export { IImageListField, ImageListFieldWidget };