import { Component, Fragment } from 'inferno'
import { safeGet } from 'safe-utils'

import {
  Body,
  Button,
  Footer,
  Form,
  Modal,
  Toolbar,
} from 'influence-ux-components'
import { Animated } from 'inferno-animation'
import { FormRows } from 'influence-ux-formlib'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField/widgets'
import { HeroImageFieldWidget } from 'app-field-HeroImageField/widgets'
import { ModalCloseWarning } from 'app-widget-ModalCloseWarning'

import querystring from 'querystring'

import { getMobiledocRenderer } from 'influence-ux-mobiledoc'

import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IBlogPost } from 'app-entity-BlogPost'
import { IBlog } from 'app-entity-Blog'
import { IUser } from 'app-entity-User'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { INotificationManager } from 'influence-interfaces/presentation'

import 'app-field-ImageField/widgets'
import 'app-entity-User/widgets/EditForm.scss'
import './RegisterStep.scss'

const _postSelectFields = ['title', 'body']

export default class Page extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: undefined,
      validationError: undefined,
      actionBarBoundary: {top: 0, bottom: 0},
      isDirty: false,
      showCloseWarningModal: false,
    }
    this.renderer = getMobiledocRenderer()
  }

  static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
        URI: `/templates/ProfileTemplate`
    })
    
    return data
  }

  getChildContext = () => {
    // This passes the post value to cards so connectedTitle works
    return Object.assign({
      __value__: this.state.value
    }, this.context)
  }

  render (props, state, context) {
    const { location } = this.props
    const { subForm } = querystring.parse(location.search.replace(/^\?/, ''))
    const showTemplates = subForm !== 'edit'
    return (
      <Animated className="Fullpage Onboarding" key="onboarding-6" prefix="OnboardingPageNav">
        {this.renderHeader()}
        {this.renderTemplates(props)}
        {this.renderPostEditing(props, {isOpen: subForm === 'edit'})}
        {this.renderCloseWarningModal()}
      </Animated>
    )
  }

  renderHeader () {
    const propName = 'heroImageUrl'
    const dummyAdapter = {
      context: IBlog.schema._fields[propName]
    }

    // A bit wierd, should proabable be stored as currentBlog
    const currentBlog = new ISessionManager({ registry: this.context.registry }).getCurrentRoleManager()

    return (
      <div className="HeroContainer">
        <HeroImageFieldWidget className="Hero"
          adapter={dummyAdapter}
          value={safeGet(() => currentBlog[propName])}
          propName={propName}
          onChange={this.didUpdateHero} />
        {this.renderAvatar()}
      </div>
    )
  }

  renderTemplates({fetchData}) {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    const templates = fetchData || []

    return (<Fragment>
      <Body className='Presentation'>
        <Form>
          <h2>Finally, write your presentation!</h2>
          <p>To help you out we have created som templates for you but you can of course choose to start with a blank page.</p>
          <p>Don't forget to add a header image above. To change any of this later, just go and edit your profile.</p>
          
          <div className="select-template">
            {templates.map((t) => {
              return (<Fragment>
                <h3>{t.title}<Button link onClick={(e) => this.didSelectTemplate(e, t._id)}>Select</Button></h3>
                {this.renderer.render(t.description)}
                </Fragment>)
            })}
            <h3>Start with a blank page... <Button primary onClick={(e) => this.didSelectTemplate(e)}>Select</Button></h3>
          </div>
        </Form>
      </Body>
    </Fragment>)
  }

  renderPostEditing(props, { isOpen }) {
    return (
      <Modal fade className="EditModal Onboarding" isOpen={isOpen} doClose={this.doCloseEdit}>
        <Toolbar className="NavBar"
          leading={<Button onClick={this.doCloseEdit}>Cancel</Button>} />
        <Form className='IEditItem'>
          <FormRows
            schema={IBlogPost.schema}
            selectFields={_postSelectFields}
            validationErrors={this.state.validationErrors}
            value={this.state.value} onChange={this.didUpdate} />
        </Form>
        <Footer className="ActionsToolbar">
          <Toolbar
            leading={this.renderSaveActions()} />
        </Footer>
      </Modal>
    )
  }

  renderSaveActions () {
    switch (safeGet(() => this.state.value._workflows.publishWorkflow, 'draft')) {
      case 'published':
        return (<Button primary onClick={this.doSubmit}>Update</Button>)
      case undefined:
      case 'draft':
        return (
          <Fragment>
            <Button primary onClick={this.doSubmit}>Save</Button>
            <i>or</i>
            <Button link onClick={(e) => {this.doSubmit(e, true)}}>Save and make blog public</Button>
          </Fragment>
        )
      default:
        return (<Button primary onClick={this.doSubmit}>Save</Button>)
    }
  }

  renderAvatar() {
    const propName = 'avatarUrl'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    const currentUser = new ISessionManager(this.context).getCurrentUser()

    return <div className="AvatarContainer">
      <AvatarImageFieldWidget className="Avatar"
        adapter={dummyAdapter}
        value={safeGet(() => currentUser.avatarUrl)}
        propName={propName}
        onChange={this.didUpdateAvatar} />
    </div>
  }


  renderHero() {
    const propName = 'heroImageUrl'
    const dummyAdapter = {
      context: IBlog.schema._fields[propName]
    }

    return <HeroImageFieldWidget className="Onboarding-Hero"
      adapter={dummyAdapter}
      value={this.state.value[propName]}
      propName={propName}
      onChange={this.didUpdateHero} />
  }

  renderCloseWarningModal () {
    return (
      <ModalCloseWarning
        isOpen={this.state.showCloseWarningModal}
        doClose={this.doCloseEdit}
        doCancel={(e) => {e && e.preventDefault(); this.setState({ showCloseWarningModal: false })}} />
    )
  }

  didSelectTemplate = (e, templateId) => {
    e && e.preventDefault()

    const blog = new ISessionManager().getCurrentRoleManager()

    const template = (this.props.fetchData || []).find((t) => t._id === templateId)
    const body = safeGet(() => template.templateBody)

    this.setState({
      value: new IObjectPrototypeFactory('BlogPost').getObject({
        title: 'About me',
        body,
      }, blog),
      isDirty: false,
    })

    const { match } = this.props
    this.context.router.history.push(`${match.url}?subForm=edit`)
  }

  doCloseEdit = (e) => {
    const { event, force } = e
    const ev = (event || e)
    ev.preventDefault()

    if (this.state.isDirty && !force) {
      this.setState({
        showCloseWarningModal: true
      })
    }
    else {
      this.setState({
        showCloseWarningModal: false
      })
      const { match } = this.props
      this.context.router.history.push(`${match.url}`)
    }
  }

  didUpdate = (propName, value) => {
    const isNewValue = JSON.stringify(value) !== JSON.stringify(this.state.value[propName])
    const isDirty = this.state.isDirty || isNewValue
    
    if (isNewValue) {
      const newVal = this.state.value
      newVal[propName] = value

      /*** This is all related to connectedTitle ***/
      if (propName === 'body') {
        const connectedCard = value.cards.reduce((prev, curr) => prev || (curr[1].connectedTitle ? curr[1] : undefined), undefined)
        if (connectedCard) {
          newVal['connectedTitle'] = {
            cardId: connectedCard._id, // I believe this can be undefined for new cards but that is okay
            active: true,
          }
          newVal['title'] = connectedCard['title']
        }
        else {
          newVal['connectedTitle'] = null
        }
      }
      else if (propName === 'title' && safeGet(() => newVal.connectedTitle.active)) {
        // Sync title with card
        newVal.body.cards.forEach(([type, card]) => {
          if (card._id === newVal.connectedTitle.cardId) {
            card.title = newVal.title
          }
        })
      }
      else if (propName === 'connectedTitle') {
        // NOTE: We set the connectedTitle property to null on save
        // But we add/remove connectedTitle for linked card to help troubleshootin in DB
        let changedCard
        if (value.active) {
          // add
          newVal.body.cards.forEach(([type, card]) => {
            if (card._id === newVal.connectedTitle.cardId) {
              changedCard = card
              changedCard.title = newVal.title
              changedCard.connectedTitle = {
                postId: newVal._id,
                active: true
              }
            }
          })
        }
        else {
          // remove
          newVal.body.cards.forEach(([type, card]) => {
            if (card._id === newVal.connectedTitle.cardId) {
              changedCard = card
              changedCard.connectedTitle = null
            }
          })
        }
        /*** End connected title ***/

        // TODO: The following has no effect. I haven't found any way
        // to mutate cards in an active editor other than using .save
        // when returning from an editor.
        //
        // // Trigger update of mobiledoc field so card is in sync
        // this._mobiledocEditor.run((postEditor) => {
        //   const { post, mobiledoc } = postEditor.editor

        //   mobiledoc.cards = mobiledoc.cards.map(([type, card]) => {
        //     if (card._id === changedCard._id) {
        //       return [type, changedCard]
        //     }
        //     else {
        //       return [type, card]
        //     }
        //   })
        //   let section = post.sections.head
        //   while (section) {
        //     const payload = section.payload
        //     if (payload && payload._id === newVal.connectedTitle.cardId) {
        //       // This is the connected card so we update the prop
        //       payload.connectedTitle = changedCard.connectedTitle
        //       // ...and stop processing the loop
        //       break
        //     }
        //     // Step forward in linked list
        //     section = section.next
        //   }
        // })
      }
      /*** END This is all related to connectedTitle ***/

      this.setState({
          value: newVal,
          isDirty
      })
    }
  }

  doSubmit = async (e, makePublic) => {
    e.preventDefault()
    const currentUser = new ISessionManager().getCurrentUser()
    const blog = new ISessionManager().getCurrentRoleManager()

    let post = this.state.value

    // Publish presentation
    if (makePublic) {
      post._workflows.publishWorkflow = 'published'
    }
    else {
      post._workflows.publishWorkflow = post._workflows.publishWorkflow || 'draft'
    }

    // Create the blog post
    const { data: presentationPost } = await new IApiClient().create({
      URI: '/content/BlogPost',
      data: post,
      invalidate: '/content/BlogPost'
    })

    // Assign the blog post as the presentation post and make blog public if requested
    blog.profilePost = {
      id: presentationPost._id,
      type: presentationPost._type,
      description: presentationPost.title
    }


    // Make blog public if requested
    if (makePublic) {
      blog._workflows.publishWorkflow = 'published'
    }

    await new IApiClient().update({
      URI: `/content/Blog/${currentUser.mainBlogName}`,
      data: blog,
      invalidate: '/content/Blog'
    })

    new ISessionManager().setCurrentRoleManager(blog)
    new INotificationManager().showSuccessMessage()

    // Navigate to blog list view
    if (makePublic) {
      this.context.router.history.push(`/${currentUser.mainBlogName}`)
    }
    else {
      this.context.router.history.push(`/edit/${currentUser.mainBlogName}`)
    }
  }

  didUpdateHero = (propName, val) => {
    const blog = new ISessionManager().getCurrentRoleManager()
    blog[propName] = val
    new ISessionManager().setCurrentRoleManager(blog)
    
    new IApiClient().update({
        URI: `/content/Blog/${blog._id}`,
        data: blog,
        invalidate: ['/content/Blog']
    }).then(() => {
      // new ISessionManager().refreshCurrentUser()
      // TODO: Show success indicator at center of screen
      new INotificationManager().showSuccessMessage()
    })
  }


  didUpdateAvatar = (propName, val) => {
    const blogUser = new ISessionManager().getCurrentUser()
    blogUser[propName] = val

    // Make sure the image is updated in the hero
    const blog = new ISessionManager().getCurrentRoleManager()
    blog['_userInfo'].avatarUrl = val

    new ISessionManager().setCurrentRoleManager(blog)

    new IApiClient().update({
        URI: `/content/User/${blogUser._id}`,
        data: blogUser,
        invalidate: ['/content/User', '/session']
    }).then(() => {
      // Make sure we have the updated user
      new ISessionManager().refreshCurrentUser()
      // Show success indicator
      new INotificationManager().showSuccessMessage()
    })
  }
}

