import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Link } from 'inferno-router';
import { Body, Trailing } from 'influence-ux-components';
import { WorkflowActions } from 'app-widget-WorkflowWidgets';
import { Thumbnail } from 'influence-ux-responsive-image';
import { safeJoin } from 'safe-utils';
import { IApiClient } from 'influence-interfaces/presentation';
import { IListItem, INotificationManager } from 'influence-interfaces/presentation';
import { IUser } from '../interface';
import { IUserWorkflow } from 'app-workflow-UserWorkflow'; // TODO: Fix these: import '../common/ListItem.scss'

import './ListItem.scss';
import anonAvatar_svg from './img/anon_avatar.svg';
var workflowStates = IUserWorkflow.schema.workflowStates;

function doChangeWorkflowState(obj, newState) {
  obj._workflows.objWorkflow = newState;
  new IApiClient().update({
    URI: "/admin/Invitation/".concat(obj._id),
    data: obj,
    invalidate: "/admin/Invitation"
  }).then(function (_ref) {
    var data = _ref.data;
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage();
  });
}

function _renderAvatar(avatarUrl) {
  if (avatarUrl) {
    return createComponentVNode(2, Thumbnail, {
      "className": "avatar",
      "image": avatarUrl
    });
  } else {
    return createVNode(1, "img", "avatar", null, 1, {
      "src": anonAvatar_svg
    });
  }
}

function ListItem(_ref2) {
  var obj = _ref2.context;
  return createVNode(1, "div", "ListItem ListItemUser", [createComponentVNode(2, Body, {
    children: [_renderAvatar(obj.avatarUrl), createComponentVNode(2, Link, {
      "to": "/admin/user/".concat(obj._id),
      children: safeJoin([obj.firstName, obj.lastName], ' ')
    }), createVNode(1, "h3", null, safeJoin([obj._id, safeJoin(obj.roles, ', ')], ' - '), 0)]
  }), createComponentVNode(2, Trailing, {
    "className": "workflow",
    children: [createVNode(1, "h2", "status", obj._workflows && obj._workflows.userWorkflow, 0), createComponentVNode(2, WorkflowActions, {
      "obj": obj,
      "workflowStates": workflowStates,
      "doChangeState": function doChangeState(state) {
        return doChangeWorkflowState(obj, state);
      }
    })]
  })], 4);
}

new Adapter({
  implements: IListItem,
  adapts: IUser,
  Component: ListItem
});