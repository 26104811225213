import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, BoolField, SelectField, TextField, TextAreaField, ListField, i18n } from 'isomorphic-schema';
import { BlogCityGuideRelationField } from 'app-field-BlogCityGuideRelationField';
import { ImageField } from 'app-field-ImageField';
import { ImageListField } from 'app-field-ImageListField';
import { GalleryLayoutField } from 'app-field-GalleryLayoutField';
import { GeoPointField } from 'app-field-GeoPointField';
import { ConnectedTitleField } from 'app-field-ConnectedTitleField';
export var IPlaceCard = new Interface({
  name: 'IPlaceCard',
  schema: new Schema('CityGuide Schema', {
    title: new TextField({
      label: i18n('IPlaceCard-title-label', 'Title'),
      placeholder: i18n('IPlaceCard-title-placeholder', 'Type here...'),
      required: true
    }),
    connectedTitle: new ConnectedTitleField({
      labelActive: 'post title',
      labelInactive: 'card title',
      helpActive: i18n('ICard-connectedTitle-helpActive', 'You are connected'),
      helpInactive: i18n('ICard-connectedTitle-helpInactive', 'Now you are disconnected')
    }),
    placeType: new SelectField({
      label: i18n('IPlaceCard-placeType-label', 'Type'),
      valueType: new TextField({}),
      options: [{
        name: 'bar',
        title: i18n('IPlaceCard-placeType-option-bar', 'Bar')
      }, {
        name: 'coffeshop',
        title: i18n('IPlaceCard-placeType-option-coffeshop', 'Coffeshop')
      }, {
        name: 'fastfood',
        title: i18n('IPlaceCard-placeType-option-fastfood', 'Fastfood')
      }, {
        name: 'nightclub',
        title: i18n('IPlaceCard-placeType-option-nightclub', 'Nightclub')
      }, {
        name: 'park',
        title: i18n('IPlaceCard-placeType-option-park', 'Park')
      }, {
        name: 'place',
        title: i18n('IPlaceCard-placeType-option-place', 'Place')
      }, {
        name: 'restaurant',
        title: i18n('IPlaceCard-placeType-option-restaurant', 'Restaurant')
      }, {
        name: 'shop',
        title: i18n('IPlaceCard-placeType-option-shop', 'Shop')
      }]
    }),
    collection: new BlogCityGuideRelationField({
      label: i18n('IPlaceCard-collection-label', 'Belongs to City Guide'),
      placeholder: i18n('IPlaceCard-collection-placeholder', 'Type name of city guide...')
    }),
    images: new ImageListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    }),
    shortDescription: new TextAreaField({
      label: i18n('ICard-shortDescription-label', 'Short Description'),
      required: true
    }),
    url: new TextField({
      label: i18n('IPlaceCard-url-label', 'Website URL')
    }),
    location: new GeoPointField({
      label: i18n('IPlaceCard-location-label', 'Destination'),
      placeholder: i18n('IPlaceCard-location-placeholder', 'Type an address...')
    }),
    funFacts: new ListField({
      label: i18n('IPlaceCard-funFacts-label', 'Fun Facts'),
      valueType: new TextField({})
    })
  })
});