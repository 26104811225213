import { createVNode } from "inferno";
import "./FacebookButton.scss";
/**
 * Render a Facebook login button
 * @param {string} id – Set ID
 * @param {string} className – Add a CSS-class to button
 * @param {string} size – Size of button. Options: small|large|default
 * @param {string} type – Button type, affects text. Options: login|continue 
 * @param {string} onClick – onClick callback
 */

export default function (_ref) {
  var id = _ref.id,
      className = _ref.className,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? 'default' : _ref$size,
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? 'continue' : _ref$type,
      onClick = _ref.onClick;
  var msg;

  switch (type) {
    case "login":
      if (size === 'small') {
        msg = "Log in";
      } else {
        msg = "Login with Facebook";
      }

      break;

    default:
      msg = "Continue with Facebook";
      break;
  }

  var cls = 'fb-btn';

  switch (size) {
    case "small":
      cls += ' fb-btn-small';
      break;

    case "large":
      cls += ' fb-btn-large';
      break;

    default:
      break;
  }

  if (className) {
    cls += ' ' + className;
  }

  return createVNode(1, "div", cls, [createVNode(1, "div", "fb-btn-text", msg, 0), createVNode(1, "div", "block")], 4, {
    "id": id,
    "onClick": onClick
  });
}