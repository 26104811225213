import { createInterfaceClass } from 'component-registry';
import { Schema, TextField, i18n } from 'isomorphic-schema';
import { MobiledocField } from 'app-field-MobiledocField';
var Interface = createInterfaceClass('app-entity');
export var IContentTemplate = new Interface({
  name: 'IContentTemplate',
  schema: new Schema('ContentTemplate Schema', {
    title: new TextField({
      label: i18n('IContentTemplate-title-label', 'Title'),
      placeholder: i18n('IContentTemplate-title-placeholder', 'Type here...')
    }),
    description: new MobiledocField({
      label: i18n('IContentTemplate-description-label', 'Decription'),
      placeholder: i18n('IContentTemplate-description-placeholder', 'Type here...'),
      toolbar: 'PublicPost'
    }),
    templateTitle: new TextField({
      label: i18n('IContentTemplate-templateTitle-label', 'Template Title'),
      placeholder: i18n('IContentTemplate-title-placeholder', 'Type here...')
    }),
    templateSubject: new TextField({
      label: i18n('IContentTemplate-templateSubject-label', 'Template Subject'),
      placeholder: i18n('IContentTemplate-title-placeholder', 'Type here...')
    }),
    templateBody: new MobiledocField({
      label: i18n('IContentTemplate-templateBody-label', 'Template Body'),
      placeholder: i18n('IContentTemplate-title-placeholder', 'Type here...'),
      toolbar: 'PublicPost'
    })
  })
});