import classnames from 'classnames'
import querystring from 'querystring'
import { i18n } from 'influence-i18n'
import { Button, NavButton, NavFilter, Toolbar } from 'influence-ux-components'
import { Animated } from 'inferno-animation'
import { Avatar } from 'app-entity-User/widgets'

import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'

import './AdminMenu.scss'


const adminMenuItems = [
  'dashboard',
  'blog',
  'user',
  'invitation'
]

export const AdminTypeMenu = (props, context) => {
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  if (!currentUser) return null
  
  const userIsAdmin = currentUser.roles.indexOf('admin') >= 0
  const menuItems = userIsAdmin ? adminMenuItems : ['dashboard']
  
  const { pathname } = context.router.route.location
  const menuOptions = menuItems.map((type) => {
    return {
      isSelected: '/admin/' + type === pathname,
      to: '/admin/' + type,
      label: i18n('AdminTypeMenu-' + type)
    }
  })

  return <NavFilter options={menuOptions} />
}

export const AdminMenu = (props, context) => {
  const { pathname } = context.router.route.location
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser() || {}
  let avatarImage = currentUser.avatarImage

  return (
    <div className={classnames({
      AdminMenu: true,
      isAdminRoot: pathname == '/admin', // use this to determine if menu should be visible on mobile
      active: props.active
    }, props.classNames)}>
      <Toolbar
        leading={[
          <Avatar image={avatarImage} active={props.active} targetElId="AvatarImg" />,
          <AdminTypeMenu />
        ]}
        trailing={<LogoutMenu />}>
      </Toolbar>
    </div>
  )
}

async function doLogout(e, context) {
  e.preventDefault()

  await new IApiClient().delete({
    URI: '/session'
  })

  await new ISessionManager({ registry: context.registry }).refreshCurrentUser()

  // I have short timeout to allow the animation to play
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}

export function LogoutMenu(props, context) {
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  if (currentUser) {
    return (
      <div className="LogoutMenu">
        <Button link onClick={(e) => doLogout(e, context)}>{i18n('AdminMenu-logout', 'Logout')}</Button>
      </div>
    )
  }
  else {
    return (
      <div className="LogoutMenu">
        <NavButton to='/edit'>{i18n('AdminMenu-login', 'Login')}</NavButton>
      </div>
    )
  }
}
