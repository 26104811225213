import { createInterfaceClass } from 'component-registry';
import { Schema, TextField, EmailField, i18n } from 'isomorphic-schema';
import { BlogRelationField } from 'app-field-BlogRelationField';
var Interface = createInterfaceClass('app-entity');
export var IInvitation = new Interface({
  name: 'IInvitation',
  schema: new Schema('Invitation Schema', {
    firstName: new TextField({
      label: i18n('IInvitation-firstName-label', 'First Name')
    }),
    lastName: new TextField({
      label: i18n('IInvitation-lastName-label', 'Last Name')
    }),
    instagramUsername: new TextField({
      label: i18n('IInvitation-instagramUserName-label', 'Instagram Username')
    }),
    websiteUrl: new TextField({
      label: i18n('IInvitation-websiteUrl-label', 'Website URL')
    }),
    email: new EmailField({
      label: i18n('IInvitation-email-label', 'Email')
    }),
    connectedBlogId: new BlogRelationField({
      label: i18n('IInvitation-connectedBlogId-label', 'Connected Blog ID')
    })
  })
});