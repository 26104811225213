import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Link } from 'inferno-router';
import { Body, Trailing } from 'influence-ux-components';
import { WorkflowActions } from 'app-widget-WorkflowWidgets';
import { renderTimedPublishingString, hasTimedPublishing } from 'app-aspect-TimedPublishing/widgets';
import { IApiClient } from 'influence-interfaces/presentation';
import { ISessionManager } from 'influence-interfaces/presentation';
import { IListItem, INotificationManager } from 'influence-interfaces/presentation';
import { IGuide } from '../interface';
import { IPublishWorkflow } from 'app-workflow-PublishWorkflow';
import clock_svg from 'app-widget-common/img/icons/clock.svg'; // TODO: Fix these: import '../common/ListItem.scss'

var workflowStates = IPublishWorkflow.schema.workflowStates;

function doChangeWorkflowState(obj, newState) {
  obj._workflows.publishWorkflow = newState;
  new IApiClient().update({
    URI: "/content/Guide/".concat(obj._id),
    data: obj,
    invalidate: "/content/Guide"
  }).then(function (_ref) {
    var data = _ref.data;
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage();
  });
}

function IconClock() {
  return createVNode(1, "img", "Icon-Clock", null, 1, {
    "src": clock_svg
  });
}

function ListItem(props, context) {
  var _ISessionManager$getC = new ISessionManager({
    registry: context.registry
  }).getCurrentRoleManager(),
      blogName = _ISessionManager$getC.title;

  var obj = props.context;
  return createVNode(1, "div", "ListItem", [createComponentVNode(2, Body, {
    children: [createComponentVNode(2, Link, {
      "link": true,
      "to": "/edit/".concat(blogName, "/guide/").concat(obj._pathId || obj._id),
      children: obj.title || 'Untitled...'
    }), createVNode(1, "h3", null, [createVNode(1, "i", null, "Guide", 16), renderTimedPublishingString(obj.publishingStartsAt, obj.publishingEndsAt)], 0)]
  }), createComponentVNode(2, Trailing, {
    "className": "workflow",
    children: [createVNode(1, "h2", "status", [hasTimedPublishing(obj) && createComponentVNode(2, IconClock), obj._workflows && obj._workflows.publishWorkflow], 0), createComponentVNode(2, WorkflowActions, {
      "obj": obj,
      "workflowStates": workflowStates,
      "doChangeState": function doChangeState(state) {
        return doChangeWorkflowState(obj, state);
      }
    })]
  })], 4);
}

new Adapter({
  implements: IListItem,
  adapts: IGuide,
  Component: ListItem
});