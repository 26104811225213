import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-aspect');
import { Schema, DateTimeField, i18n } from 'isomorphic-schema';
export var ITimedPublishing = new Interface({
  // Interface for PublishWorkflow
  name: 'ITimedPublishing',
  schema: new Schema('TimedPublishing', {
    publishingStartsAt: new DateTimeField({
      label: i18n('ITimedPublishing-publishingStartsAt-label', 'Publish from')
    }),
    publishingEndsAt: new DateTimeField({
      label: i18n('ITimedPublishing-publishingEndsAt-label', 'Publish to')
    })
  })
});