import { createObjectPrototype, Adapter } from 'component-registry';
import { IRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
import { safeGet } from 'safe-utils';
import assert from 'assert';
var roleRegex = /{id}/g;
/**
 * TODO: Consider specializing so we can have several RoleManagers
 * this._permissionTemplates = {
 *  BlogPost: {...}
 * }
 */

export var RoleManager = createObjectPrototype({
  implements: [IRoleManager],
  constructor: function constructor(params
  /*, permissions*/
  ) {
    /*
      // We want to throw an error if no permissions are passed
      // assert(typeof permissions === 'object', 'No permissions passed to Permissions constructor')
      
      // Make sure we can't mess up permissions by passing weird stuff that overrides general settings
      if (params && params.hasOwnProperty('_permissions')) {
          delete params._permissions
      }
    */
  }
});
/*
* WARNING! If you change the templates you need to update ALL documents in the database
* to reflect new permissions. Images, cards, entities etc...
*/

RoleManager.prototype._permissionsTemplateBlogPost = {
  create: ['admin', '{id}.admin', '{id}.editor', '{id}.writer'],
  read: ['admin', '{id}.admin', '{id}.editor', 'owner', 'anonymous:publishWorkflow.published'],
  update: ['admin', '{id}.admin', '{id}.editor', 'owner'],
  delete: ['admin', '{id}.admin', 'owner']
};
RoleManager.prototype._permissionsTemplateGuide = {
  create: ['admin', '{id}.admin', '{id}.editor', '{id}.writer'],
  read: ['admin', '{id}.admin', '{id}.editor', 'owner', 'anonymous:publishWorkflow.published'],
  update: ['admin', '{id}.admin', '{id}.editor', 'owner'],
  delete: ['admin', '{id}.admin', 'owner']
};
RoleManager.prototype._permissionsTemplate = {
  create: ['admin', '{id}.admin', '{id}.editor', '{id}.writer'],
  read: ['admin', '{id}.admin', '{id}.editor', 'owner', 'anonymous:publishWorkflow.published'],
  update: ['admin', '{id}.admin', '{id}.editor', 'owner'],
  delete: ['admin', '{id}.admin', 'owner']
};
new Adapter({
  implements: IRoleManagerAdapter,
  adapts: IRoleManager,
  getPermissions: function getPermissions(type) {
    assert(type, 'No type specified');
    var templ = this.context["_permissionsTemplate".concat(type)] || this.context["_permissionsTemplate"];
    var id = this.context._id;
    var outp = {
      create: templ.create.map(function (role) {
        return role.replace(roleRegex, id);
      }),
      read: templ.read.map(function (role) {
        return role.replace(roleRegex, id);
      }),
      update: templ.update.map(function (role) {
        return role.replace(roleRegex, id);
      }),
      delete: templ.delete.map(function (role) {
        return role.replace(roleRegex, id);
      })
    };
    return outp;
  },
  getRolesByPrincipalId: function getRolesByPrincipalId(principalId) {
    var _this = this;

    return this.context.principals.filter(function (roleObj) {
      return safeGet(function () {
        return roleObj.principal.id === principalId;
      });
    }).map(function (roleObj) {
      return roleObj.role.replace(roleRegex, _this.context._id);
    });
  }
});