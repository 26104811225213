import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Link } from 'inferno-router';
import { ISessionManager } from 'influence-interfaces/presentation';
import { IPublicListItem } from 'influence-interfaces/presentation';
import { IBlogPost } from '../interface';

function ListItem(props, context) {
  var _ISessionManager$getC = new ISessionManager({
    registry: context.registry
  }).getCurrentRoleManager(),
      blogName = _ISessionManager$getC.title;

  var obj = props.context;
  return createVNode(1, "div", "live-list-item list-item col", [createVNode(1, "h2", null, createComponentVNode(2, Link, {
    "to": "/".concat(blogName, "/BlogPost/").concat(obj._pathId || obj._id),
    children: obj.title
  }), 2), createVNode(1, "h3", null, "xxx timmar sedan av MMM NNN", 16)], 4);
}

new Adapter({
  implements: IPublicListItem,
  adapts: IBlogPost,
  Component: ListItem
});