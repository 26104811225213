import { createVNode, createComponentVNode } from "inferno";
import classnames from 'classnames';
import { safeGet } from 'safe-utils';
import { i18n } from 'influence-i18n';
import { Button, Dropdown } from 'influence-ux-components';
import IconArrow from './_arrow';
import './WorkflowStatus.scss';

function WorkflowStatus(_ref) {
  var obj = _ref.obj,
      workflowPropName = _ref.workflowPropName,
      workflowStates = _ref.workflowStates,
      isOpen = _ref.isOpen,
      doChangeWorkflowState = _ref.doChangeWorkflowState,
      doOpen = _ref.doOpen,
      doClose = _ref.doClose;
  return createVNode(1, "div", "WorkflowStatus", [createVNode(1, "div", "status", safeGet(function () {
    return obj._workflows[workflowPropName];
  }), 0), createComponentVNode(2, Dropdown, {
    "direction": "up",
    "alignment": "right",
    "doClose": doClose,
    "customWidget": createVNode(1, "div", null, [createVNode(1, "div", "header", i18n('WorkflowAction-updateStatus', 'Change Status'), 0), createVNode(1, "div", "body", Object.keys(workflowStates).map(function (key) {
      // Don't return the current state since we don't want to transition to ourself
      var isActive = (obj._workflows && obj._workflows[workflowPropName]) === key;
      if (isActive) return null;
      return createComponentVNode(2, Button, {
        "link": true,
        "onClick": function onClick(e) {
          e.preventDefault();
          doChangeWorkflowState(obj, key);
        },
        children: i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)
      });
    }), 0)], 4),
    "isOpen": isOpen,
    children: createComponentVNode(2, Button, {
      "link": true,
      "className": "status-btn",
      "onClick": function onClick(e) {
        return isOpen ? doClose(e) : doOpen(e);
      },
      children: createComponentVNode(2, IconArrow, {
        "className": classnames("Icon-Arrow")
      })
    })
  })], 4);
}

export { WorkflowStatus };