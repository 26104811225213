import { createVNode } from "inferno";
export default function () {
  return createVNode(32, "svg", null, [createVNode(32, "defs", null, [createVNode(32, "path", null, null, 1, {
    "d": "M52.5,8.75 C35.56875,8.75 21.875,22.44375 21.875,39.375 C21.875,62.34375 52.5,96.25 52.5,96.25 C52.5,96.25 83.125,62.34375 83.125,39.375 C83.125,22.44375 69.43125,8.75 52.5,8.75 L52.5,8.75 Z M52.5,50.3125 C46.4625,50.3125 41.5625,45.4125 41.5625,39.375 C41.5625,33.3375 46.4625,28.4375 52.5,28.4375 C58.5375,28.4375 63.4375,33.3375 63.4375,39.375 C63.4375,45.4125 58.5375,50.3125 52.5,50.3125 L52.5,50.3125 Z",
    "id": "path-1"
  }), createVNode(32, "rect", null, null, 1, {
    "id": "path-3",
    "x": "0",
    "y": "0",
    "width": "218.75",
    "height": "218.75"
  })], 4), createVNode(32, "g", null, [createVNode(32, "mask", null, createVNode(32, "use", null, null, 1, {
    "xlink:href": "#path-1"
  }), 2, {
    "id": "mask-2",
    "fill": "white"
  }), createVNode(32, "g", null, null, 1, {
    "id": "Mask"
  }), createVNode(32, "g", null, createVNode(32, "g", null, [createVNode(32, "mask", null, createVNode(32, "use", null, null, 1, {
    "xlink:href": "#path-3"
  }), 2, {
    "id": "mask-4",
    "fill": "none"
  }), createVNode(32, "use", null, null, 1, {
    "stroke": "none",
    "fill": "currentColor",
    "fill-rule": "evenodd",
    "xlink:href": "#path-3"
  })], 4, {
    "transform": "translate(-56.875000, -56.875000)"
  }), 2, {
    "id": "Colors/Soft",
    "mask": "url(#mask-2)"
  })], 4, {
    "id": "ic_room",
    "transform": "translate(9.0, 0)"
  })], 4, {
    "width": "126px",
    "height": "126px",
    "viewBox": "0 0 72 72"
  });
}