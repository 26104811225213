import { Component } from 'inferno'
import { safeGet } from 'safe-utils'

import {
  Schema,
  i18n
} from 'isomorphic-schema'

import {
  Body,
  Button,
  Form,
  Sticky,
} from 'influence-ux-components'
import { Animated } from 'inferno-animation'
import { FormRows } from 'influence-ux-formlib'
import SitePageFooter from '../../widgets/SitePageFooter'
import { AvatarImageFieldWidget } from 'app-field-AvatarImageField/widgets'
import { HeroImageFieldWidget } from 'app-field-HeroImageField/widgets'

import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IBlog } from 'app-entity-Blog'
import { IUser } from 'app-entity-User'
import { BlogTitleField } from 'app-field-BlogTitleField'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { INotificationManager } from 'influence-interfaces/presentation'

import 'app-field-ImageField/widgets'
import 'app-entity-User/widgets/EditForm.scss'
import logo_colored_svg from 'app-widget-common/img/logo/logo-colored.svg'
import anonAvatar_svg from 'app-widget-common/img/anon_avatar.svg'

const createBlogSchema = new Schema('User Schema', {
  blogName: new BlogTitleField({
    label: i18n('createBlog-choose-blogName-label', 'Välj namn på din blogg:'),
    placeholder: i18n('createBlog-blogName-placeholder', 'Bloggnamn'),
    required: true
  }),
})

createBlogSchema.addInvariant((data) => {
  // TODO: Check validity of blogName
})



export default class Page extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: { blogName: safeGet(() => new ISessionManager({ registry: context.registry }).getCurrentUser().mainBlogName) },
      validationError: undefined,
      actionBarBoundary: {top: 0, bottom: 0}
    }
  }

  static async fetchData ({registry, match, location}) {
    // const { data } = await new IApiClient({ registry }).query({
    //     URI: `/session`
    // })
    
    return
  }

  render (props, state, context) {      
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    const connectedBlog = safeGet(() => !!currentUser.mainBlogName)


    return (
      <Animated className="Fullpage Onboarding" key="onboarding-5" animatedChildClass="Onboarding-Content" prefix="OnboardingPageNav">
        <div id="Page-Logo"><img className="logo" src={logo_colored_svg} /></div>
        {this.renderAvatar()}
        <div className="Onboarding-Content">
          {connectedBlog
            ? this.renderConnectedBlog()
            : this.renderChooseName()}
        </div>
      </Animated>
    )
  }

  renderChooseName() {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    const mainBlogName = safeGet(() => currentUser.mainBlogName)

    return (
      <Body>
        <h2>{safeGet(() => currentUser.firstName)}, let's give your blog a name!</h2>
        <Form onSubmit={this.doSubmit}>
          <FormRows
            schema={createBlogSchema}
            validationErrors={this.state.validationErrors}
            value={mainBlogName ? { blogName: mainBlogName } : this.state.value}
            onChange={this.didUpdate} />
          <Sticky className="Onboarding-ActionBar" stickToBottom>
            <Button primary submit>I want it!</Button>
          </Sticky>
        </Form>
      </Body>
    )
  }

  renderConnectedBlog() {
    const currentUser = new ISessionManager(this.context).getCurrentUser()
    const mainBlogName = safeGet(() => currentUser.mainBlogName)

    return (
      <Body>
        <h2>{safeGet(() => currentUser.firstName)}, this invitation was connected to an existing blog:</h2>
        <h2>https://memly.io/{mainBlogName}</h2>
        <Sticky className="Onboarding-ActionBar" stickToBottom>
          <Button primary onClick={this.doGoNext}>Next</Button>
        </Sticky>
      </Body>
    )
  }

  renderAvatar() {
    const propName = 'avatarUrl'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    const currentUser = new ISessionManager(this.context).getCurrentUser()

    return <AvatarImageFieldWidget className="Onboarding-Avatar"
      adapter={dummyAdapter}
      value={safeGet(() => currentUser.avatarUrl)}
      propName={propName}
      readOnly
      onChange={this.didUpdate} />
  }


  renderHero() {
    const propName = 'hero'
    const dummyAdapter = {
      context: IUser.schema._fields[propName]
    }

    return <HeroImageFieldWidget className="Onboarding-Hero"
      adapter={dummyAdapter}
      value={this.state.value[propName]}
      propName={propName}
      onChange={this.didUpdate} />
  }

  didUpdate = (propName, value) => {
    const newVal = this.state.value
    newVal[propName] = value

    let validationError
    if (this.state.submitted) {
      validationError = createBlogSchema.validate(newVal)
    }

    this.setState({
        value: newVal,
        validationError
    })
  }

  doSubmit = async (e) => {
    e.preventDefault()

    let validationError = createBlogSchema.validate(this.state.value)
    if (validationError) {
      return this.setState({
        validationError,
        submitted: true
      })
    }
    
    // Create a blog object
    let newBlog = new IObjectPrototypeFactory('Blog').getObject(
      { title: this.state.value.blogName }
    )

    // Create the blog
    let { data } = await new IApiClient().create({
      URI: '/content/Blog',
      data: newBlog,
      invalidate: '/content/Blog'
    })

    // If we don't get a blog back it probably means we have a name conflict
    if (!data) {
      const validationErrors = {
        fieldErrors: {
          blogName: {
            i18nLabel: 'IBlog-title-error-notAvailable',
            message: 'This name is not available',
            type: 'constraint_error'
          }
        },
        invariantErrors: undefined
      }
      return this.setState({
        validationErrors,
        submitted: true
      })
    }

    new INotificationManager().showSuccessMessage()

    let currentUser = new ISessionManager().getCurrentUser()
    currentUser.mainBlogId = data._id
    currentUser.mainBlogName = data.title // @myblog
    
    // Store the blogId with the user so it can easily be recalled
    await new IApiClient().update({
      URI: `/content/User/${currentUser._id}`,
      data: currentUser,
      invalidate: ['/content/User', '/session']
    })
    
    // Navigate to resentation edit view
    this.context.router.history.push(`/edit/${currentUser.mainBlogName}/presentation`)
  }

  doGoNext = (e) => {
    e.preventDefault()
    let currentUser = new ISessionManager().getCurrentUser()
    this.context.router.history.push(`/edit/${currentUser.mainBlogName}/presentation`)
  }
}

