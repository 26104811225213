import { createVNode } from "inferno";
import { Adapter } from 'component-registry';
import { IRelationFieldItemWidget } from 'app-field-RelationField/widgets';
import { IBlogPost } from '../interface';

function ListItem(_ref) {
  var post = _ref.context;
  return createVNode(1, "div", "RelationField-BlogPost", post.title, 0);
}

new Adapter({
  implements: IRelationFieldItemWidget,
  adapts: IBlogPost,
  Component: ListItem
});