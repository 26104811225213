import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { BaseField, i18n } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IRelationField = new Interface({
  name: 'IRelationField'
});
export var RelationField = createObjectPrototype({
  implements: [IRelationField],
  extends: [BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  },
  validate: function validate(inp) {
    var error = this._IBaseField.validate.call(this, inp);

    if (error) {
      return error;
    } // TODO: Check for line breaks


    if (inp && typeof inp !== "object") {
      return {
        type: 'type_error',
        i18nLabel: i18n('influence-app-entities--field_no_reltype', 'The field isn\'t a relationship object'),
        message: "Fältet är inte ett relationsobjekt"
      };
    }

    if (inp && !(inp.type && inp.id && inp.description)) {
      return {
        type: 'type_error',
        i18nLabel: i18n('influence-app-entities--field_no_reltype', 'The field isn\'t a reelationship object'),
        message: "Fältet är inte ett relationsobjekt"
      };
    }
  }
});