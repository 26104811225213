import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import { render } from 'inferno';
import { createElement } from 'inferno-create-element';
/*
  _createClass(AtomNode, [{
    key: 'render',
    value: function render() {
      if (!this._rendered) {
        var options = this.atomOptions;
        var env = this.env;
        var _model = this.model;
        var value = _model.value;
        var payload = _model.payload; // cache initial render

        this._rendered = this.atom.render({
          // TODO: Add editor
          options: options,
          env: env,
          value: value,
          payload: payload
        });
      }

      this._validateAndAppendRenderResult(this._rendered);
    }
  }
*/

export var atomRenderer = function atomRenderer(component) {
  return function (_ref) {
    var editor = _ref.editor,
        env = _ref.env,
        options = _ref.options,
        payload = _ref.payload,
        value = _ref.value;
    var onTeardown = env.onTeardown;
    var element = createElement(component, _objectSpread(_objectSpread(_objectSpread({
      editor: editor
    }, env), options), {}, {
      value: value,
      payload: _objectSpread({}, payload)
    }));
    var targetNode = document.createElement('span');
    render(element, targetNode);
    onTeardown(function () {
      return render(null, targetNode);
    });
    return targetNode;
  };
};
export var classToDOMAtom = function classToDOMAtom(component, atomRenderer) {
  if (!component.displayName) {
    throw new Error("Can't create atom from component, no displayName defined: ".concat(component));
  }

  return {
    name: component.displayName,
    component: component,
    type: 'dom',
    render: atomRenderer(component)
  };
};
export function utilityToAtom(_ref2, atomRenderer) {
  var type = _ref2.type,
      RenderComponent = _ref2.RenderComponent;
  return {
    name: RenderComponent.displayName,
    component: RenderComponent,
    type: type,
    render: atomRenderer(RenderComponent)
  };
}