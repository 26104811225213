import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["className", "fixed", "stickToScrollRef", "hidden", "children"];
import { createVNode, createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import classnames from 'classnames';
import Sticky from '../widgets/Sticky';
export default function Footer(_ref) {
  var className = _ref.className,
      fixed = _ref.fixed,
      stickToScrollRef = _ref.stickToScrollRef,
      hidden = _ref.hidden,
      children = _ref.children,
      other = _objectWithoutProperties(_ref, _excluded); // Check that container is passed if we have a header


  var sticky = stickToScrollRef != null;

  var props = _objectSpread({
    className: classnames('footer', {
      hide: hidden,
      fixed: fixed
    }, className)
  }, other);

  if (sticky && !fixed) {
    return createComponentVNode(2, Sticky, {
      "scrollRef": stickToScrollRef,
      "stickToBottom": true,
      children: normalizeProps(createVNode(1, "div", null, children, 0, _objectSpread({}, props)))
    });
  } else {
    return normalizeProps(createVNode(1, "div", null, children, 0, _objectSpread({}, props)));
  }
}