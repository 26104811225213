import { createObjectPrototype, Utility } from 'component-registry';
import { IMedia } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IRoleManager, IHasRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
import { Permissions } from 'influence-permissions/lib/permissions';
import assert from 'assert';
export var Media = createObjectPrototype({
  implements: [IMedia, IHasRoleManager],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'Media';

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'customerService', 'member', 'anonymous'],
      // TODO: Anon should not be alowed to create image
      read: ['owner', 'admin', 'customerService', 'anonymous'],
      update: ['owner', 'admin', 'customerService'],
      delete: ['owner', 'admin', 'customerService']
    });
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Media',
  getPrototype: function getPrototype() {
    return Media;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {}; // TODO: I am making rolemanager optional so we can upload profile images during onboarding, but this is a quickfix!

    if (!data._permissions && IRoleManager.providedBy(roleManager)) {
      // Permissions for newly created object
      assert(IRoleManager.providedBy(roleManager), 'No RoleManager passed during creation of ' + data._type);
      data._permissions = new IRoleManagerAdapter(roleManager).getPermissions(this._name);
      data._roleManagerId = roleManager._id;
    }

    return new Media(data);
  }
});