import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, BoolField, IntegerField, TextField, TextAreaField, ListField, ObjectField, i18n } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { ImageListField } from 'app-field-ImageListField';
import { GalleryLayoutField } from 'app-field-GalleryLayoutField';
import { ConnectedTitleField } from 'app-field-ConnectedTitleField';
var ingredientSchema = new Schema('IngredientSchema', {
  amount: new TextField({
    label: i18n('IngredientSchema-amount-label', 'Amount'),
    required: true
  }),
  name: new TextField({
    label: i18n('IngredientSchema-name-label', 'Ingredient Name'),
    required: true
  }),
  note: new TextField({
    label: i18n('IngredientSchema-note-label', 'Note')
  })
});
var stepSchema = new Schema('StepsSchema', {
  description: new TextAreaField({
    label: i18n('StepsSchema-description-label', 'Step description'),
    required: true
  }),
  timerMins: new IntegerField({
    label: i18n('StepsSchema-timerMins-label', 'Timer Minutes'),
    help: i18n('StepsSchema-timerMins-help', 'This is optional and will display a timer at this step')
  })
});
var IRecipeCard = new Interface({
  name: 'IRecipeCard',
  schema: new Schema('RecipeCard Schema', {
    title: new TextField({
      label: i18n('IRecipeCard-title-label', 'Title'),
      placeholder: i18n('IRecipeCard-title-placeholder', 'Type here...'),
      required: true
    }),
    connectedTitle: new ConnectedTitleField({
      labelActive: 'post title',
      labelInactive: 'card title',
      helpActive: i18n('ICard-connectedTitle-helpActive', 'You are connected'),
      helpInactive: i18n('ICard-connectedTitle-helpInactive', 'Now you are disconnected')
    }),
    images: new ImageListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    }),
    shortDescription: new TextAreaField({
      label: i18n('ICard-shortDescription-label', 'Short Description'),
      required: true
    }),
    timeInMins: new IntegerField({
      label: i18n('IRecipeCard-timeInMins-label', 'Total Time in Minutes')
    }),
    nrofPeople: new IntegerField({
      label: i18n('IRecipeCard-nrofPeople-label', 'Total Nrof People')
    }),
    ingredients: new ListField({
      label: i18n('IRecipeCard-ingredients-label', 'Ingredients'),
      valueType: new ObjectField({
        schema: ingredientSchema,
        help: i18n('IngredientSchema-ingredients-help', 'Use metric such as 1dl, 200g. Or just the nrof items.')
      })
    }),
    steps: new ListField({
      label: i18n('IRecipeCard-steps-label', 'Steps'),
      valueType: new ObjectField({
        schema: stepSchema
      })
    })
  })
});
export { IRecipeCard };