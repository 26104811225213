import { createInterfaceClass } from 'component-registry';
import { Schema, TextField, DateTimeField, i18n } from 'isomorphic-schema';
import { MobiledocField } from 'app-field-MobiledocField';
import { ConnectedTitleField } from 'app-field-ConnectedTitleField';
var Interface = createInterfaceClass('app-entity');
export var IBlogPost = new Interface({
  name: 'IBlogPost',
  schema: new Schema('BlogPost Schema', {
    title: new TextField({
      label: i18n('IBlogPost-title-label', 'Title'),
      placeholder: i18n('IBlogPost-title-placeholder', 'Type here...')
    }),
    connectedTitle: new ConnectedTitleField({
      labelActive: 'connected',
      labelInactive: 'disconnect',
      helpActive: i18n('IBlogPost-connectedTitle-helpActive', 'You are connected'),
      helpInactive: i18n('IBlogPost-connectedTitle-helpInactive', 'Now you are disconnected')
    }),
    publishingStartsAt: new DateTimeField({
      label: i18n('ITimedPublishing-publishingStartsAt-label', 'Publish from')
    }),
    publishingEndsAt: new DateTimeField({
      label: i18n('ITimedPublishing-publishingEndsAt-label', 'Publish to')
    }),
    subject: new TextField({
      label: i18n('IBlogPost-subject-label', 'Ämne'),
      placeholder: i18n('IBlogPost-title-placeholder', 'Type here...')
    }),
    body: new MobiledocField({
      label: i18n('IBlogPost-body-label', 'Body'),
      placeholder: i18n('IBlogPost-body-placeholder', 'Type here...'),
      toolbar: 'PublicPost'
    })
  })
});