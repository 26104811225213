import { createObjectPrototype, Utility } from 'component-registry';
import { Permissions } from 'influence-permissions/lib/permissions';
import { PublishWorkflow } from 'app-workflow-PublishWorkflow';
import { IContentTemplate } from './interface';
import { IObject, IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IHasRoleManager } from 'influence-interfaces/permissions';
export var ContentTemplate = createObjectPrototype({
  implements: [IContentTemplate, IHasRoleManager, IObject],
  extends: [Permissions, PublishWorkflow],
  constructor: function constructor(params, roleManager) {
    this._type = 'ContentTemplate'; // NOTE: For now we only support site wide templates. We might want
    // to change this to allow users to create their own templates in
    // the future, but it needs some deeper thought

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'editor'],
      read: ['owner', 'admin', 'customerService', 'member:publishWorkflow.published'],
      update: ['owner', 'admin', 'customerService'],
      delete: ['owner', 'admin']
    }); // TODO: Implement support for cards
    // Because this contains cards we need a created object to be able to store
    // cards with the corret id. That transient state is undefined and if the create is
    // canceled we can purge the object.


    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: undefined
    });
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'ContentTemplate',
  getPrototype: function getPrototype() {
    return ContentTemplate;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {}; // NOTE: We currently don't support role managers for templates
    // see comment in constructor
    // if (!data._permissions) {
    //     // Permissions for newly created object
    //     assert(IRoleManager.providedBy(roleManager), 'No RoleManager passed during creation of ContentTemplate')
    //     data._permissions = new IRoleManagerAdapter(roleManager).getPermissions(this._name)
    //     data._roleManagerId = roleManager._id
    // }

    return new ContentTemplate(data);
  }
});