import { createObjectPrototype, Utility } from 'component-registry';
import { IVideoCard } from './interface';
import { ICard } from 'app-card-Card/interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IRoleManager, IHasRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
import { Permissions } from 'influence-permissions/lib/permissions';
import assert from 'assert';
export var VideoCard = createObjectPrototype({
  implements: [IVideoCard, ICard, IHasRoleManager],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'VideoCard';

    this._IPermissions.constructor.call(this, params, params._permissions);
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'VideoCard',
  getPrototype: function getPrototype() {
    return VideoCard;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};

    if (!data._permissions) {
      // Permissions for newly created object
      assert(IRoleManager.providedBy(roleManager), 'No RoleManager passed during creation of ' + data._type);
      data._permissions = new IRoleManagerAdapter(roleManager).getPermissions(this._name);
      data._roleManagerId = roleManager._id;
    }

    return new VideoCard(data);
  }
});