import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _extends from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/extends";
import { createVNode, normalizeProps } from "inferno";
export default function UserIcon(_ref) {
  var props = _extends({}, _ref);

  return normalizeProps(createVNode(32, "svg", null, [createVNode(32, "defs", null, createVNode(32, "path", null, null, 1, {
    "d": "M119.163,84.785 C122.476,84.785 125.504,85.329 128.248,86.415 C130.99,87.501 133.311,88.953 135.213,90.773 C137.115,92.593 138.799,94.793 140.265,97.373 C141.732,99.953 142.9,102.601 143.769,105.316 C144.638,108.032 145.359,110.978 145.929,114.156 C146.499,117.334 146.879,120.294 147.07,123.037 C147.262,125.78 147.357,128.591 147.357,131.47 C147.357,137.988 145.375,143.134 141.409,146.909 C137.443,150.683 132.175,152.57 125.603,152.57 L125.603,152.57 L54.395,152.57 C47.823,152.57 42.554,150.683 38.589,146.909 C34.624,143.134 32.641,137.987 32.641,131.47 C32.641,128.591 32.736,125.781 32.926,123.037 C33.116,120.293 33.496,117.334 34.067,114.156 C34.637,110.978 35.357,108.032 36.226,105.316 C37.095,102.602 38.263,99.953 39.729,97.373 C41.195,94.793 42.879,92.593 44.78,90.773 C46.681,88.954 49.003,87.501 51.746,86.415 C54.489,85.329 57.517,84.785 60.83,84.785 C61.319,84.785 62.459,85.369 64.252,86.537 C66.044,87.705 68.067,89.009 70.321,90.448 C72.575,91.886 75.509,93.191 79.12,94.357 C82.732,95.525 86.357,96.109 89.996,96.109 C93.636,96.109 97.261,95.525 100.873,94.357 C104.484,93.191 107.418,91.886 109.672,90.448 C111.926,89.009 113.949,87.705 115.741,86.537 C117.533,85.369 118.674,84.785 119.163,84.785 Z M89.999,27.429 C98.635,27.429 106.009,30.484 112.119,36.594 C118.23,42.705 121.285,50.078 121.285,58.714 C121.285,67.351 118.23,74.724 112.119,80.834 C106.01,86.944 98.637,90 89.999,90 C81.363,90 73.99,86.944 67.879,80.834 C61.768,74.724 58.713,67.351 58.713,58.714 C58.713,50.078 61.768,42.705 67.879,36.594 C73.989,30.484 81.363,27.429 89.999,27.429 Z",
    "id": "userIconPath"
  }), 2), createVNode(32, "mask", null, createVNode(32, "use", null, null, 1, {
    "href": "#userIconPath"
  }), 2, {
    "id": "userIconMask",
    "fill": "white"
  }), createVNode(32, "g", null, createVNode(32, "rect", null, null, 1, {
    "id": "Rectangle-2",
    "x": "0",
    "y": "0",
    "width": "180",
    "height": "180"
  }), 2, {
    "mask": "url(#userIconMask)",
    "fill": "currentColor"
  })], 4, _objectSpread({
    "width": "180px",
    "height": "180px",
    "viewBox": "0 0 180 180"
  }, props)));
}