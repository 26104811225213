import { createObjectPrototype, Utility } from 'component-registry';
import assert from 'assert';
import { Permissions } from 'influence-permissions/lib/permissions';
import { PublishWorkflow } from 'app-workflow-PublishWorkflow';
import { TimedPublishing } from 'app-aspect-TimedPublishing';
import { IBlogPost } from './interface';
import { IObject, IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IRoleManager, IHasRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
export var BlogPost = createObjectPrototype({
  implements: [IBlogPost, IHasRoleManager, IObject],
  extends: [Permissions, PublishWorkflow, TimedPublishing],
  constructor: function constructor(params, roleManager) {
    this._type = 'BlogPost';

    this._IPermissions.constructor.call(this, params, params._permissions); // Because this contains cards we need a created object to be able to store
    // cards with the corret id. That transient state is undefined and if the create is
    // canceled we can purge the object.


    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: undefined
    });

    this._ITimedPublishing.constructor.call(this, params);
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'BlogPost',
  getPrototype: function getPrototype() {
    return BlogPost;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};

    if (!data._permissions) {
      // Permissions for newly created object
      assert(IRoleManager.providedBy(roleManager), 'No RoleManager passed during creation of BlogPost');
      data._permissions = new IRoleManagerAdapter(roleManager).getPermissions(this._name);
      data._roleManagerId = roleManager._id;
    }

    return new BlogPost(data);
  }
});