import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createVNode, createComponentVNode, normalizeProps } from "inferno";
import { Adapter } from 'component-registry';
import { Body, Footer } from 'influence-ux-components';
import { ResponsiveImage } from 'influence-ux-responsive-image';
import { FormRows } from 'influence-ux-formlib';
import { IMediaSelectDetails } from 'influence-interfaces/presentation';
import { IMedia } from '../interface'; // <Button onClick={linkEvent(props, onSelect)}>Select</Button>

function MediaSelectDetails(props) {
  return createVNode(1, "div", "MediaSelectDetails", [normalizeProps(createComponentVNode(2, ResponsiveImage, _objectSpread({
    "width": "100%"
  }, props.context))), createComponentVNode(2, Body, {
    children: createComponentVNode(2, FormRows, {
      "schema": props.context._implements[0].schema,
      "value": props.context,
      "selectFields": "shortDescription,fileType",
      "onChange": function onChange() {
        return null;
      }
    })
  }), props.children && createComponentVNode(2, Footer, {
    children: props.children
  })], 0);
}

new Adapter({
  implements: IMediaSelectDetails,
  adapts: IMedia,
  Component: MediaSelectDetails
});