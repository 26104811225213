import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { IApiClient } from 'influence-interfaces/presentation';
import { Link } from 'inferno-router';
import { Body, ButtonBar, StateButton, Trailing, NavButton } from 'influence-ux-components';
import { WorkflowActions } from 'app-widget-WorkflowWidgets';
import { IListItem, INotificationManager } from 'influence-interfaces/presentation';
import { IBlog } from '../interface';
import { IPublishWorkflow } from 'app-workflow-PublishWorkflow'; // TODO: Fix these: import '../common/ListItem.scss'

var workflowStates = IPublishWorkflow.schema.workflowStates;

function doChangeWorkflowState(obj, newState) {
  obj._workflows.publishWorkflow = newState;
  new IApiClient().update({
    URI: "/content/Blog/".concat(obj._id),
    data: obj,
    invalidate: "/content/Blog"
  }).then(function (_ref) {
    var data = _ref.data;
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage();
  });
}

function ListItem(props, context) {
  var obj = props.context;
  return createVNode(1, "div", "ListItem", [createComponentVNode(2, Body, {
    children: createComponentVNode(2, Link, {
      "to": "/admin/blog/".concat(props.context.title || props.context._id),
      children: props.context.title
    })
  }), createComponentVNode(2, Trailing, {
    "className": "workflow",
    children: [createVNode(1, "h2", "status", obj._workflows && obj._workflows.publishWorkflow, 0), createComponentVNode(2, WorkflowActions, {
      "obj": obj,
      "workflowStates": workflowStates,
      "doChangeState": function doChangeState(state) {
        return doChangeWorkflowState(obj, state);
      }
    })]
  })], 0);
}

new Adapter({
  implements: IListItem,
  adapts: IBlog,
  Component: ListItem
});