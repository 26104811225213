export * from './SelectMediaWidget';
import { Adapter } from 'component-registry';
import { IImageField } from '../field';
import { ImageFieldWidget } from './ImageFieldWidget';
import { interfaces } from 'influence-ux-formlib';
var IInputFieldWidget = interfaces.IInputFieldWidget;
new Adapter({
  implements: IInputFieldWidget,
  adapts: IImageField,
  Component: ImageFieldWidget
});
export { IImageField, ImageFieldWidget };