import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { i18n, TextField } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IBlogTitleField = new Interface({
  name: 'IBlogTitleField'
});
export var BlogTitleField = createObjectPrototype({
  implements: [IBlogTitleField],
  extends: [TextField],
  constructor: function constructor(options) {
    this._ITextField.constructor.call(this, options);

    this._regex = /[^a-z0-9._-]*/gm;
  },
  validate: function validate(inp) {
    var error = this._ITextField.validate.call(this, inp);

    if (error) {
      return error;
    } // Check for invalid chars (should be array of empty strings if valid)


    if (inp) {
      if (inp[0] !== '@') {
        return {
          type: 'value_error',
          i18nLabel: i18n('influence-app-entities--field_missing_at', 'The value should start with @'),
          message: 'The value should start with @'
        };
      } else if (inp.substring(1).match(this._regex).reduce(function (p, c) {
        return p || c;
      }, undefined)) {
        return {
          type: 'value_error',
          i18nLabel: i18n('influence-app-entities--field_illegal_chars', 'The value contains illegal characters'),
          message: 'The value contains illegal characters'
        };
      }
    }
  },
  fromString: function fromString(inp) {
    inp = inp.toLowerCase();
    var outp = inp.replace(this._regex, '');

    if (outp && outp[0] !== '@') {
      outp = '@' + outp;
    }

    return outp;
  }
});