import { Component } from 'inferno'
import { Animated } from 'inferno-animation'
import SitePageFooter from '../../widgets/SitePageFooter'
import '../ContentPage.scss'
import '../onboarding/RegisterStep.scss'
import logo_colored_svg from 'app-widget-common/img/logo/logo-colored.svg'

export default class Page extends Component {
  render() {
    return (
      <Animated key="me" className="Fullpage ContentPage" prefix="PageNav">
        <div id="Page-Logo"><img className="logo" src={logo_colored_svg} /></div>
        <div className="body">
          <h2>Contact</h2>
          <p>We are currently in beta, to apply for access follow our Instagram account <a href="https://www.instagram.com/memly.io/">@memly.io</a> and send us a DM.</p>
          <p>To contact our support by e-mail, use <a href="mailto:support@memly.io">support@memly.io</a>.</p>
        </div>
        <SitePageFooter />
      </Animated>
    )
  }
}
