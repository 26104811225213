import { Adapter } from 'component-registry';
import { IConnectedTitleField } from '../field';
import { ConnectedTitleFieldWidget } from './ConnectedTitleFieldWidget';
import { interfaces } from 'influence-ux-formlib';
import './index.scss';
var IInputFieldWidget = interfaces.IInputFieldWidget;
new Adapter({
  implements: IInputFieldWidget,
  adapts: IConnectedTitleField,
  Component: ConnectedTitleFieldWidget
});
export { IConnectedTitleField, ConnectedTitleFieldWidget };