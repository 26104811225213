import { createVNode, createComponentVNode } from "inferno";
import { Adapter } from 'component-registry';
import { Thumbnail } from 'influence-ux-responsive-image';
import { Body } from 'influence-ux-components';
import { IRelationFieldItemWidget } from 'app-field-RelationField/widgets';
import { IUser } from '../interface';
import { safeJoin } from 'safe-utils';

function RelationFieldItem(_ref) {
  var user = _ref.context;
  return createVNode(1, "div", "RelationField-User", [createComponentVNode(2, Thumbnail, {
    "className": "avatar",
    "image": user.avatarUrl
  }), createComponentVNode(2, Body, {
    children: [safeJoin([user.firstName, user.lastName], ' '), createVNode(1, "h3", null, user._id, 0)]
  })], 4);
}

new Adapter({
  implements: IRelationFieldItemWidget,
  adapts: IUser,
  Component: RelationFieldItem
});