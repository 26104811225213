import { createVNode } from "inferno";
import { Adapter } from 'component-registry';
import { IRelationFieldItemWidget } from 'app-field-RelationField/widgets';
import { ICityGuide } from '../interface';

function ListItem(_ref) {
  var city = _ref.context;
  return createVNode(1, "div", "RelationField-CityGuide", city.title, 0);
}

new Adapter({
  implements: IRelationFieldItemWidget,
  adapts: ICityGuide,
  Component: ListItem
});