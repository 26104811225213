import { Component } from 'inferno'
import classnames from 'classnames'
import { Page } from 'influence-ux-components'
import { typeMapping } from './_typeMapping'

import { IApiClient, IEditForm, IPageManager } from 'influence-interfaces/presentation'
import './Edit.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class EditPage extends Component {

    static async fetchData ({registry, match, location}) {
      new IPageManager({ registry }).setMetaData({
        title: 'Redigera...',
        url: FRONTEND_BASE_URI + location.pathname
      })

      const objType = typeMapping(match.params.type)
      const { data } = await new IApiClient({ registry }).query({
          URI: `/content/${objType}/${match.params.id}`
      })

      return data
    }

    render () {
        const data = this.props.fetchData

        let EditForm = null
        if (data) {
            EditForm = new IEditForm(data).Component
        }

        if (!EditForm) return null

        let showTitle
        switch (data._type) {
          case 'BlogPost':
          case 'Guide':
            showTitle = false;
            break;
          default:
            showTitle = true;
        }

        return (
          <Page key="page" className={classnames("EditPage", data._type)} animationPrefix="PageNav">
              <EditForm value={data} {...this.props} />
          </Page>
        )
    }
}

