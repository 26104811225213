import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { i18n, Schema, TextField, TextAreaField, SelectField, ObjectField } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { MobiledocField } from 'app-field-MobiledocField';
import { GuideContentListField } from 'app-field-GuideContentListField';
import { GuideContentListRowField } from 'app-field-GuideContentListRowField';
import { GuidePageRelationField } from 'app-field-GuidePageRelationField';
import { IGuideChapter } from 'app-entity-GuideChapter/interface';
export var IGuide = new Interface({
  name: 'IGuide',
  schema: new Schema('Guide Schema', {
    title: new TextField({
      label: i18n('IGuide-title-label', 'Name of Guide'),
      placeholder: i18n('ICard-title-placeholder', 'Type here...')
    }),
    shortDescription: new TextAreaField({
      label: i18n('ICard-shortDescription-label', 'Short description')
    }),
    heroImageUrl: new ImageField({
      label: i18n('IGuide-heroImageUrl-label', 'Bakgrundsbild'),
      placeholder: i18n('ICard-image-placeholder', 'Drag och släpp här...'),
      help: i18n('ICard-image-help', 'Bara rimliga bilder')
    }),
    guideType: new SelectField({
      label: i18n('IGuide-guideType-label', 'Guide type'),
      valueType: new TextField({}),
      options: [{
        name: 'guide',
        title: i18n('IGuide-guideType-option-guide', 'Guide')
      }, {
        name: 'tutorial',
        title: i18n('IGuide-guideType-option-tutorial', 'Tutorial')
      }]
    }),
    introduction: new MobiledocField({
      label: i18n('IGuide-introduction-label', 'Introduction'),
      toolbar: 'PublicPost'
    }),
    content: new GuideContentListField({
      label: i18n('IGuide-content-label', 'Content'),
      valueType: new GuideContentListRowField({
        valueTypes: [new ObjectField({
          schema: IGuideChapter._schema,
          objectFactoryName: "GuideChapter"
        }), new GuidePageRelationField({})]
      })
    })
  })
});