import './config'
import { ApiClient, App, doAllAsyncBefore, RestishRoute} from 'influence-App'

import { Redirect, matchPath } from 'inferno-router'
import querystring from 'querystring'

import determineRoleManager from 'app-aspect-RoleManager/determineRoleManager'
import { getCardTypeSlugs } from 'app-card-Card/widgets'
import { injectType, injectCardTypeSlug } from './routingHelpers'

import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'

// Register the translation utility
import './i18n'

/**
 * Register i18n utilities
 */
 import './i18n'

 /**
  * Register other app specific utilities
  */
 import 'app-field-AvatarImageField/widgets'
 import 'app-field-ConnectedTitleField/widgets'
 import 'app-field-GalleryLayoutField/widgets'
 import 'app-field-GeoPointField/widgets'
 import 'app-field-GuideContentListField/widgets'
 import 'app-field-GuideContentListRowFieldWidget/widgets' // Widgets are in separate packages due to circular deps
 import 'app-field-HeroImageField/widgets'
 import 'app-field-ImageField/widgets'
 import 'app-field-ImageField/widgets/FileUploadUtil'
 import 'app-field-ImageListField/widgets'
 import 'app-field-MobiledocField/widgets'
 import 'app-field-RelationField/widgets'
 import 'app-field-SearchField/widgets'
 import 'app-field-WorkflowFilterField/widgets'

 import 'app-external-googleAnalytics/appDummy' // Dummy analytics
 // import 'app-external-googleAnalytics/app' // Live analytics (check env vars)

 /**
  * Entities
  */
import 'app-entity-AccountEmail'
import 'app-entity-AccountFacebook'
import 'app-entity-AccountInstagram'
import 'app-entity-Blog'
import 'app-entity-Blog/widgets'
import 'app-entity-BlogPost'
import 'app-entity-BlogPost/widgets'
import 'app-entity-ContentTemplate'
import 'app-entity-ContentTemplate/widgets'
import 'app-entity-Guide'
import 'app-entity-Guide/widgets'
import 'app-entity-GuidePage'
import 'app-entity-GuidePage/widgets'
import 'app-entity-GuideChapter'
// GuideChapter has no widgets
import 'app-entity-Image'
import 'app-entity-Image/widgets'
import 'app-entity-Invitation'
import 'app-entity-Invitation/widgets'
import 'app-entity-Media'
import 'app-entity-Media/widgets'
import 'app-entity-User'
import 'app-entity-User/widgets'

// // Get cards
import 'app-card-CityGuide'
import 'app-card-CityGuide/widgets'
import 'app-card-ListCard'
import 'app-card-ListCard/widgets'
import 'app-card-MediaCard'
import 'app-card-MediaCard/widgets'
import 'app-card-PlaceCard'
import 'app-card-PlaceCard/widgets'
import 'app-card-RecipeCard'
import 'app-card-RecipeCard/widgets'
import 'app-card-VideoCard'
import 'app-card-VideoCard/widgets'
 
//  /**
//   * Media libraries
//   */
//  import 'app-widget-MediaLibrary';
import 'app-external-unsplash'
import 'app-external-unsplash/widgets'

 /**
  * Pages
  */
 
import NullPage from './pages/Null'

import DashboardPage from './pages/admin/Dashboard'
import CreatePage from './pages/admin/Create'
import EditPage from './pages/admin/Edit'
import EditGuidePage from './pages/admin/EditGuide'
import ListPage from './pages/admin/List'
import { ListPage as AdminListPage } from './pages/admin/ListAdmin'
import { CreatePage as AdminCreatePage } from './pages/admin/CreateAdmin'
import MasonryPage from './pages/admin/ListMasonry'
import ProfilePage from './pages/admin/Profile'

import LiveBlog from './pages/live/Blog'
import LiveMedia from './pages/live/Cards'
import LiveProfile from './pages/live/Profile'
import LiveGuidePage from './pages/live/Guide'
import LiveCard from './pages/live/Card'

// import AccountPage from './pages/Account'

import StartPage from './pages/public/Start'
import RegisterStep2 from './pages/onboarding/RegisterStep2'
import RegisterStep3 from './pages/onboarding/RegisterStep3'
import RegisterStep4 from './pages/onboarding/RegisterStep4'
import RegisterStep5 from './pages/onboarding/RegisterStep5'
import RegisterStep6 from './pages/onboarding/RegisterStep6'
import TermsAndCondtionsPage from './pages/onboarding/RegisterStep3'
import PrivacyPolicyPage from './pages/public/PrivacyPolicy'
import ContactPage from './pages/public/Contact'

/**
 * App CSS-styling
 */
import './index.scss'
import './App.scss'
 
/**
 * Image resources
 */

 import logo_colored_svg from './img/logo/logo-colored.svg'

// import IconOk from './widgets/icon_ok'
// import { IDeserialize } from 'influence-interfaces/object'

// let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
// const { API_URI } = _env_

class ExtendedApp extends App {
  constructor (props, context) {
    super(props, context)

    if (typeof window !== 'undefined') {

    }
  }

  _updateCurrentRoleManager = async (matches) => {
    const sessMgr = new ISessionManager()
    const currentRoleManager = sessMgr.getCurrentRoleManager()
    const newRoleManager = await determineRoleManager(new IApiClient(), matches, currentRoleManager)

    if ((currentRoleManager && currentRoleManager._id) !== (newRoleManager && newRoleManager._id)) {
      sessMgr.setCurrentRoleManager(newRoleManager)
    }
  }

  matchCallback = async (matches) => {
    this._updateCurrentRoleManager(matches)
  }
};

function appFactory () {

  return (
      <ExtendedApp
        defaultPageMetaData={{
          title: 'memly.io - Inspire to explore',
          description: 'Posting pictures and stories is fun and all but you have more to share. Welcome to a new content creation experience!',
        }}
        logoSvg={logo_colored_svg}>
        
        {/* Public Pages */}
        <RestishRoute public exact path={`/`} component={ StartPage } asyncBefore={StartPage.fetchData} />
        <RestishRoute public exact path={`/privacy-policy`} component={ PrivacyPolicyPage } />
        <RestishRoute public exact path={`/contact`} component={ ContactPage } />
        <RestishRoute public exact path={`/terms-and-conditions`} component={ TermsAndCondtionsPage } />
      
        {/* Onboarding */}
        <RestishRoute public exact section="onboarding" exact path={`/invite/:id`} component={ RegisterStep2 } asyncBefore={ injectType('Invitation', RegisterStep2.fetchData) } />
        <RestishRoute exact section="onboarding" path={`/register_3`} component={ RegisterStep3 } asyncBefore={ RegisterStep3.fetchData } />
        <RestishRoute exact section="onboarding" path={`/register_4`} component={ RegisterStep4 } />
        <RestishRoute exact section="onboarding" path={`/register_5`} component={ RegisterStep5} />
        <RestishRoute exact section="onboarding" path={`/edit/:roleManagerId/presentation`} component={ RegisterStep6} asyncBefore={ RegisterStep6.fetchData } />

        {/* Account – TODO: should this exist? */}
        {/*
        <RestishRoute exact path={`/account`} component={ AccountPage } asyncBefore={ AccountPage.fetchData } />
        */}

        {/* Admin */}
        <RestishRoute exact path={`/admin/:type/create`} component={ AdminCreatePage } asyncBefore={ AdminCreatePage.fetchData } />
        <RestishRoute path={`/admin/:type`} component={ AdminListPage } asyncBefore={ AdminListPage.fetchData } />
        <RestishRoute path={`/admin/:type/:id`} component={ EditPage } asyncBefore={ EditPage.fetchData } />


        {/* User Dashboard – redirects to correct taret ATM */}
        <RestishRoute exact path={`/edit`} component={ DashboardPage } asyncBefore={ DashboardPage.fetchData } />


        {/* User Edit – Profile */}
        <RestishRoute exact path={`/edit/:roleManagerId/Profile`} component={ ProfilePage } />

        {/* User Edit – Blogs TODO: Should this exist? */}
        {/*
        <RestishRoute path={`/edit/Blog`} component={ ListPage } asyncBefore={ injectType('Blog', ListPage.fetchData) } />
        <RestishRoute exact path={`/edit/Blog/:id`} component={ EditPage } asyncBefore={ injectType('Blog', EditPage.fetchData) } />
        */}

        {/* User Edit – Users TODO: Should this exist? */}
        {/*
        <RestishRoute path={`/edit/User`} component={ ListPage } asyncBefore={ injectType('User', ListPage.fetchData) } />
        <RestishRoute exact path={`/edit/User/:id`} component={ EditPage } asyncBefore={ injectType('User', EditPage.fetchData) } />
        */}

        {/* User Edit – Content */}
        <RestishRoute exact path={`/edit/:type/create`} component={ CreatePage } />
        <RestishRoute exact path={`/edit/:roleManagerId`} component={ ListPage } asyncBefore={ injectType('BlogPost', ListPage.fetchData) } />
        <RestishRoute exact path={`/edit/:roleManagerId/Cards`} component={ MasonryPage } asyncBefore={ injectType('MediaCard', MasonryPage.fetchData) } />
        <RestishRoute exact path={`/edit/:roleManagerId/:type/create`} component={ CreatePage } asyncBefore={ CreatePage.fetchData } />
        <RestishRoute path={`/edit/:roleManagerId/:type`} component={ ListPage } asyncBefore={ ListPage.fetchData } />
        <RestishRoute exact path={`/edit/:roleManagerId/Guide/:id`} component={ EditGuidePage } asyncBefore={ EditGuidePage.fetchData } />
        <RestishRoute path={`/edit/:roleManagerId/:type/:id`} component={ EditPage } asyncBefore={ EditPage.fetchData } />

        {/* Live Blog Pages */} 
        <RestishRoute public path={`/:roleManagerId/(Profile|Guide)`} component={ LiveProfile } asyncBefore={ LiveProfile.fetchData } />
        <RestishRoute public exact public path={`/:roleManagerId/Guide/:contentId/:title?`} component={ LiveGuidePage } asyncBefore={ LiveGuidePage.fetchData } />
        <RestishRoute exact public path={`/:roleManagerId/(Profile|Guide)`} component={ NullPage } />

        {/* Cards and canonical urls to cards */}
        <RestishRoute public exact exclude="/admin" path={`/:roleManagerId/Cards`} component={ LiveMedia } asyncBefore={ LiveMedia.fetchData } />
        <RestishRoute public exact exclude="/admin" path={`/:roleManagerId/Cards/:cardTypeSlug/:cardId/:title?`} component={ LiveCard } asyncBefore={ LiveCard.fetchData } />

        {/* Posts and cards viewed from posts */}
        <RestishRoute public exact exclude="/admin" path={`/:roleManagerId`} component={ LiveBlog } asyncBefore={ injectType('BlogPost', LiveBlog.fetchData) } />
        <RestishRoute public exact exclude="/admin" path={`/:roleManagerId/(${getCardTypeSlugs().join('|')})/:cardId/:title?`} component={ LiveCard } asyncBefore={ injectCardTypeSlug(0, LiveCard.fetchData) } />
        <RestishRoute public exact exclude="/admin" path={`/:roleManagerId/:contentId/:title?`} component={ LiveBlog } asyncBefore={ injectType('BlogPost', LiveBlog.fetchData) } />
        {/*<RestishRoute public exact exclude="/admin" path={`/:roleManagerId/:contentId/(${getCardTypeSlugs().join('|')})/:cardId/:title?`} component={ LiveCard } asyncBefore={ injectCardTypeSlug(0, LiveCard.fetchData) } />*/}

        {/* Redirects */}
        <Redirect exact path={'/admin'} to="/edit" />
        <Redirect to="/edit" />
      </ExtendedApp>
  )
}

export { 
  appFactory,
  doAllAsyncBefore,
  determineRoleManager,
  ApiClient
}