import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createVNode, normalizeProps } from "inferno";
export default function (props) {
  // Dropshadow https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3
  return normalizeProps(createVNode(32, "svg", null, [createVNode(32, "filter", null, [createVNode(32, "feGaussianBlur", null, null, 1, {
    "in": "SourceAlpha",
    "stdDeviation": "3"
  }), createVNode(32, "feOffset", null, null, 1, {
    "dx": "1",
    "dy": "1",
    "result": "offsetblur"
  }), createVNode(32, "feComponentTransfer", null, createVNode(32, "feFuncA", null, null, 1, {
    "type": "linear",
    "slope": "0.5"
  }), 2), createVNode(32, "feMerge", null, [createVNode(32, "feMergeNode"), createVNode(32, "feMergeNode", null, null, 1, {
    "in": "SourceGraphic"
  })], 4)], 4, {
    "id": "dropshadow",
    "height": "130%"
  }), createVNode(32, "g", null, createVNode(32, "g", null, createVNode(32, "g", null, [createVNode(32, "circle", null, null, 1, {
    "id": "OvalShadow",
    "fill-opacity": "0.5",
    "cx": "49.5",
    "cy": "49.5",
    "r": "49.5",
    "style": "filter:url(#dropshadow)"
  }), createVNode(32, "circle", null, null, 1, {
    "id": "Oval",
    "fill-opacity": "0.4",
    "cx": "49.5",
    "cy": "49.5",
    "r": "49.5"
  }), createVNode(32, "polygon", null, null, 1, {
    "id": "Triangle",
    "transform": "translate(55.500000, 50.000000) rotate(90.000000) translate(-55.500000, -50.000000) ",
    "points": "55.5 35 78 65 33 65"
  })], 4, {
    "id": "video_play"
  }), 2, {
    "id": "Video-player",
    "transform": "translate(5.000000, 5.000000)",
    "fill": "currentColor"
  }), 2, {
    "id": "Modules",
    "stroke": "none",
    "stroke-width": "1",
    "fill": "none",
    "fill-rule": "evenodd"
  })], 4, _objectSpread({
    "width": "109px",
    "height": "109px",
    "viewBox": "0 0 109 109"
  }, props)));
}