import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, TextField, i18n } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
var IVideoCard = new Interface({
  name: 'IVideoCard',
  schema: new Schema('VideoCard Schema', {
    videoUrl: new TextField({
      label: i18n('IVideoCard-videoUrl-label', 'URL to Youtube video'),
      placeholder: i18n('IVideoCard-videoUrl-placeholder', 'https://www.youtube.com/watch?v=...')
    }),
    shortDescription: new TextField({
      label: i18n('IVideoCard-shortDescription-label', 'Caption')
    }),
    image: new ImageField({
      label: i18n('IVideoCard-image-label', 'Image'),
      placeholder: i18n('ICard-image-placeholder', 'Drag and drop...'),
      help: i18n('ICard-image-help', 'Only JPEG or PNG. Max size 5Mb.')
    })
  })
});
export { IVideoCard };