import { Component, Fragment, createRef } from 'inferno'
import classnames from 'classnames'
import { findDOMNode } from 'inferno-extras'
import { safeGet, safeJoin } from 'safe-utils'

import { ScrollAwareImage } from '../widgets/ScrollAwareImage'
import {
  Footer,
  Hero,
  Parallax,
  TabPage,
  Section,
  Sticky, 
  reflowCoordinator
} from 'influence-ux-components'

import { MainMenu, PullUpMenu, LogoutMenu, AdminMenu, EditLiveMenu } from '../widgets/MainMenu'
import { HeroImageFieldWidget } from 'app-field-HeroImageField/widgets'

import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { INotificationManager } from 'influence-interfaces/presentation'

import { IBlog } from 'app-entity-Blog'
import { IImage } from 'app-entity-Image'

import './admin/List.scss'
import './admin/Dashboard.scss'
import './SectionPage.scss'

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
let { IMAGE_URI_BASE } = _env_
IMAGE_URI_BASE = IMAGE_URI_BASE || '//localhost:6001/images'

let _storedPullUpMode = false
let _storedScroll = 0

export default class SectionPage extends Component {

  _lastScrollPos = -1
  _scrollRef = null
  _animatingPullUp = false

  state = {
    pullUpMode: _storedPullUpMode,
    pullUpMenuHeight: 48
  }

  constructor(props) {
    super(props)
    this._scrollRef = (this.props.scrollRef != null ? this.props.scrollRef : createRef())
  }
  
  componentDidMount() {
    // Note! On first mount, _scrollRef.current doesn't exist, but it does on patches
    // This is okay because on first mount scroll === 0 anyway. But this
    // is inconsistent behaviour in Inferno and perhaps should be fixed.
    if (this._scrollRef != null && this._scrollRef.current != null) {
      // Set initial scroll when navigating between pages
      this._scrollRef.current.scrollTo(0, _storedScroll)
    }
    
    // Run once and then start listening to animation frames
    reflowCoordinator.runReflow({
      sharedStateRef: this._scrollRef,
      callback: this.didScroll
    })
    reflowCoordinator.subscribe({
      sharedStateRef: this._scrollRef,
      vNode: this,
      callback: this.didScroll
    })
  }

  render ({ type }, state, context) {
    const { isExact, params, path } = this.props.match
    type = type || params.type
    const { pullUpMode } = this.state
    const listPageType = type || 'BlogPost'
    const isLive = !path.startsWith('/edit') // This is a hack to disable parallax when editing

    // A bit wierd, should proabable be stored as currentBlog
    const currentBlog = new ISessionManager({ registry: context.registry }).getCurrentRoleManager()

    // Don't show hidden blog
    if (currentBlog === undefined) {
      return null
    }

    return (
      <TabPage
        //ref={(el) => { this._el = el; this._scrollElObj.el = el; this.props.domRefObj && (this.props.domRefObj.el = el) }}
        ref={el => this._scrollRef.current = el}
        className={classnames("SectionPage", {
          PullUp: pullUpMode,
          "cover": currentBlog.layoutType === 'cover'
        }, this.props.className)}>
        
        {this.renderToolbar()}

        <Section className="ListPageSection"> {/* TODO: Convert to <Section className="Hero" /> */}
          <PullUpMenu active={pullUpMode}>
            {this.props.pullUpMenuItems}
          </PullUpMenu>
          
          {currentBlog.layoutType === 'cover' && isLive
            ? <Parallax className="CollapseMe" scrollSpeed={3} scrollRef={this._scrollRef} onParallaxChange={this.doStoreScrollChange}>{this.renderCollapseMe(currentBlog)}</Parallax>
            : <Hero className="CollapseMe" scrollRef={this._scrollRef} onScrollChange={this.doStoreScrollChange}>{this.renderCollapseMe(currentBlog)}</Hero>}
        </Section>
        {this.renderFilter()}
        <Section className={`ListPageSection-${listPageType}`}>
          {this.props.children || null}
        </Section>
        {this.renderActionbar()}
      </TabPage>
    )
  }

  renderToolbar () {
    if (!this.props.toolbar) {
      return null
    }
    else {
      return (
        <div className="toolbar-container">{this.props.toolbar}</div>
      )
    }
  }

  renderFilter () {
    if (!this.props.filter) return null

    return (
      <Section className="filter-container FilterContainer">
        {this.props.filter}
      </Section>
    )
  }

  renderActionbar () {
    if (!this.props.actionbar) {
      return null
    }
    else {
      return (
        <Footer fixed className="actionbar-container">{this.props.actionbar}</Footer>
      )
    }
  }

  renderCollapseMe(currentBlog) {
    const { pullUpMode } = this.state
    const { editAvatar, editBlogVisibility } = this.props

    const onChangeAvatar = editAvatar ? this.didUpdateAvatar : undefined

    const blogUser = safeGet(() => currentBlog['_userInfo'], {})

    
    return ( <Fragment>
      {this.renderHero(currentBlog)}
      <div className="AvatarContainer">
        <ScrollAwareImage active={!pullUpMode} image={blogUser.avatarUrl} imgId="AvatarImg" start={50} end={200} onChange={onChangeAvatar} />
      </div>
      <div className="UserNameContainer">
        <div className="FullName">{safeJoin([blogUser.firstName, blogUser.lastName], ' ')}</div>
      </div>
      <div className="MainMenuContainer">
        <MainMenu editBlogVisibility={editBlogVisibility} />
      </div>
      <div className="TopMenuContainer">
        <AdminMenu />
        <EditLiveMenu />
        <LogoutMenu />
      </div>
    </Fragment>)

  }

  renderHero(currentBlog) {
    const { editHero } = this.props

    let heroImageUrl
    if (currentBlog && currentBlog.heroImageUrl) {
      heroImageUrl = IImage.providedBy(currentBlog.heroImageUrl)
        ? currentBlog.heroImageUrl.rendered.reduce((prev, curr) => curr.width > prev.width && curr.width <= 1600 ? curr
        : prev, { width: 0 }) : currentBlog.heroImageUrl
    }


    const styleHero = {
      'background-color': 'slategray',
      'background-image': `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2)), url(${heroImageUrl && heroImageUrl.imagePath ? IMAGE_URI_BASE + heroImageUrl.imagePath : heroImageUrl})`
    }

    const propName = 'heroImageUrl'
    const dummyAdapter = {
      context: IBlog.schema._fields[propName]
    }

    if (editHero) {
      return <HeroImageFieldWidget className="Hero"
        adapter={dummyAdapter}
        value={currentBlog[propName]}
        propName={propName}
        onChange={this.didUpdateHero} />
    }
    else {
      return <div className="Hero" style={styleHero} />
    }

  }

  doStoreScrollChange = (scrollY) => {
    _storedScroll = scrollY
  }

  didScroll = (phase, sharedState) => {
    // If we aren't top page or el not available yet, don't perform scroll snapping
    if (this._scrollRef == null
      || this._scrollRef.current == null
      || !this.props.match.isExact) return

    const scrollEl = this._scrollRef.current
    
    switch (phase) {
      case 'calc':
        const scrollTop = sharedState.scrollTop()

        // Fast path: Only do something if scroll position has changed but always check when no scroll
        if (this._lastScrollPos === scrollTop && scrollTop > 0) return sharedState
    
        // Quickfix to reset animatingPullUp during testing
        if (scrollTop === 0) {
          this._animatingPullUp = false
        }
        
        // Don't go crazy during animation
        if (!this._animatingPullUp) {
          // if we land on the page the delta will be 0 and we want to make sure it is pulled up
          const scrollUp = (scrollTop - this._lastScrollPos) <= 0 ? true : false
          const delta = Math.abs(scrollTop - this._lastScrollPos)
          this._lastScrollPos = scrollTop

          if (!scrollUp && !this.state.pullUpMode && scrollTop > 200) {
            this._animatingPullUp = true
            _storedPullUpMode = true
            setTimeout(() => this._animatingPullUp = false, 600)
            this._nextStateUpdate = { pullUpMode: true }
          }
          // // TODO: Should we release, if so when? pull to overflow?
          else if (scrollUp && this.state.pullUpMode && scrollTop < 180) {
            this._animatingPullUp = true
            _storedPullUpMode = false
            setTimeout(() => this._animatingPullUp = false, 600)
            this._nextStateUpdate = { pullUpMode: false }
          }
        }
        return sharedState
      case 'setState':
          // Did we get any updates?
          if (this._nextStateUpdate != null && Object.keys(this._nextStateUpdate).length > 0) {
            this.setState(this._nextStateUpdate)
          }
          this._nextStateUpdate = null
    }
  }

  didUpdateAvatar = (propName, val) => {
    const blogUser = new ISessionManager().getCurrentUser()
    blogUser[propName] = val

    // Make sure the image is updated in the hero
    const blog = new ISessionManager().getCurrentRoleManager()
    blog['_userInfo'].avatarUrl = val

    new ISessionManager().setCurrentRoleManager(blog)

    new IApiClient().update({
        URI: `/content/User/${blogUser._id}`,
        data: blogUser,
        invalidate: ['/content/User', '/session']
    }).then(() => {
      // Make sure we have the updated user
      new ISessionManager().refreshCurrentUser()
      // Show success indicator
      new INotificationManager().showSuccessMessage()
    })
  }
  
  didUpdateHero = (propName, val) => {
    const blog = new ISessionManager().getCurrentRoleManager()
    blog[propName] = val
    new ISessionManager().setCurrentRoleManager(blog)
    
    new IApiClient().update({
        URI: `/content/Blog/${blog._id}`,
        data: blog,
        invalidate: ['/content/Blog']
    }).then(() => {
      // new ISessionManager().refreshCurrentUser()
      // TODO: Show success indicator at center of screen
      new INotificationManager().showSuccessMessage()
    })
  }

  _getElFromScrollRef = () => {
    if (this._scrollRef) {
      return this._scrollRef.current
    }
  }
}



