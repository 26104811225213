import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createVNode, createComponentVNode, normalizeProps, createTextVNode } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import MultiImageIcon from './multi-image.js';
import classnames from 'classnames';
import { Image } from 'influence-ux-components';
import { IImageHelper } from 'influence-interfaces/presentation';

var _env_ = typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env;

var IMAGE_URI_BASE = _env_.IMAGE_URI_BASE;
IMAGE_URI_BASE = IMAGE_URI_BASE || '//localhost:6001/images';

function isMultiple(images) {
  return Array.isArray(images) && images.length > 1;
}

export function Thumbnail(_ref) {
  var className = _ref.className,
      image = _ref.image,
      imageUrl = _ref.imageUrl,
      images = _ref.images,
      imageUrls = _ref.imageUrls,
      onClick = _ref.onClick,
      onLoad = _ref.onLoad;
  image = image || imageUrl;
  images = images || imageUrls;
  var multiple = isMultiple(images);

  try {
    if (!multiple && image && image.thumbnails) {
      var imgHlpr = new IImageHelper(image);
      var _image$thumbnails$ = image.thumbnails[0],
          width = _image$thumbnails$.width,
          height = _image$thumbnails$.height;
      var size = {}; // { width, height }

      return normalizeProps(createComponentVNode(2, Image, _objectSpread(_objectSpread({}, size), {}, {
        "className": classnames("thumbnail", className),
        "src": imgHlpr.getThumbSrc(),
        "srcSet": imgHlpr.getThumbSrcSet(),
        "onLoad": onLoad,
        "onClick": onClick
      })));
    } else if (images[0] && images[0].hasOwnProperty('rawImage') === true) {
      var _imgHlpr = new IImageHelper(images[0]);

      var _images$0$thumbnails = images[0].thumbnails,
          _width = _images$0$thumbnails.width,
          _height = _images$0$thumbnails.height;
      var _size = {}; // { width, height }

      return createVNode(1, "div", classnames("thumbnail", className), [normalizeProps(createComponentVNode(2, Image, _objectSpread(_objectSpread({}, _size), {}, {
        "className": "thumbnail",
        "itemprop": "image",
        "src": _imgHlpr.getThumbSrc(),
        "srcSet": _imgHlpr.getThumbSrcSet(),
        "onLoad": onLoad
      }))), multiple && createComponentVNode(2, MultiImageIcon, {
        "className": "icon"
      })], 0, {
        "onClick": onClick
      });
    }
  } catch (e) {
    console.log(e);
    return createComponentVNode(2, Image, {
      "className": classnames("thumbnail", className),
      "src": "Broken image object"
    });
  }
}
export function ResponsiveImage(_ref2) {
  var className = _ref2.className,
      image = _ref2.image,
      imageUrl = _ref2.imageUrl,
      limit = _ref2.limit,
      images = _ref2.images,
      imageUrls = _ref2.imageUrls,
      thumbnail = _ref2.thumbnail,
      maxWidth = _ref2.maxWidth,
      onClick = _ref2.onClick,
      onLoad = _ref2.onLoad;
  image = image || imageUrl;
  images = images || imageUrls;
  var multiple = isMultiple(images);

  try {
    if (!multiple && image && image.rendered) {
      var imgHlpr = new IImageHelper(image);
      var _image$rendered$ = image.rendered[0],
          width = _image$rendered$.width,
          height = _image$rendered$.height;
      var size = {}; // { width, height }

      return normalizeProps(createComponentVNode(2, Image, _objectSpread(_objectSpread({}, size), {}, {
        "className": className,
        "src": imgHlpr.getSrc(maxWidth),
        "srcSet": imgHlpr.getSrcSet(maxWidth),
        "onClick": onClick,
        "onLoad": onLoad
      })));
    } else if (thumbnail) {
      var imgObj = images[0];

      var _imgHlpr2 = new IImageHelper(imgObj);

      var _imgObj$rendered$ = imgObj.rendered[0],
          _width2 = _imgObj$rendered$.width,
          _height2 = _imgObj$rendered$.height;
      var _size2 = {}; // { width, height }

      return createVNode(1, "div", classnames("thumbnail", className), [normalizeProps(createComponentVNode(2, Image, _objectSpread(_objectSpread({}, _size2), {}, {
        "className": "thumbnail",
        "src": _imgHlpr2.getSrc(maxWidth),
        "srcSet": _imgHlpr2.getSrcSet(maxWidth),
        "onClick": onClick,
        "onLoad": onLoad
      }))), multiple && createComponentVNode(2, MultiImageIcon, {
        "className": "icon"
      })], 0);
    } else if (Array.isArray(images) && images[0].hasOwnProperty('rawImage') === true) {
      var showImages = images.filter(function (img) {
        return img;
      });
      var notShown = 0;

      if (limit < images.length) {
        notShown = images.length - limit;
        showImages = showImages.slice(0, limit);
      }

      var leftToLoad = showImages.length; // TODO: Consider checking complete to see if image is already loaded!

      var onImageLoad = function onImageLoad(e) {
        leftToLoad--;

        if (leftToLoad <= 0) {
          onLoad && onLoad(e);
        }
      };

      return showImages.map(function (imgObj, i, arr) {
        var imgHlpr = new IImageHelper(imgObj);
        var _imgObj$rendered$2 = imgObj.rendered[0],
            width = _imgObj$rendered$2.width,
            height = _imgObj$rendered$2.height;
        var size = {}; // { width, height }

        if (notShown > 0 && i === limit - 1) {
          return createVNode(1, "i", "image", [normalizeProps(createComponentVNode(2, Image, _objectSpread(_objectSpread({}, size), {}, {
            "className": classnames(className),
            "src": imgHlpr.getSrc(maxWidth),
            "srcSet": imgHlpr.getSrcSet(maxWidth),
            "onClick": onClick,
            "onLoad": onImageLoad
          }))), createVNode(1, "i", null, [createTextVNode("+"), notShown], 0)], 4);
        } else {
          return createVNode(1, "i", "image", normalizeProps(createComponentVNode(2, Image, _objectSpread(_objectSpread({}, size), {}, {
            "className": classnames(className),
            "src": imgHlpr.getSrc(maxWidth),
            "srcSet": imgHlpr.getSrcSet(maxWidth),
            "onClick": onClick,
            "onLoad": onLoad
          }))), 2);
        }
      });
    }
  } catch (e) {
    console.log(e);
    return createComponentVNode(2, Image, {
      "className": classnames(className),
      "src": "Broken image object (Error thrown on render, set breakpoint in ResponsiveImage.js to debug)"
    });
  }
}