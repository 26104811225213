import { createObjectPrototype, Utility } from 'component-registry';
import assert from 'assert';
import { Permissions } from 'influence-permissions/lib/permissions';
import { PublishWorkflow } from 'app-workflow-PublishWorkflow';
import { TimedPublishing } from 'app-aspect-TimedPublishing';
import { IGuidePage } from './interface';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IRoleManager, IHasRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
var GuidePage = createObjectPrototype({
  implements: [IGuidePage, IHasRoleManager],
  extends: [Permissions, PublishWorkflow, TimedPublishing],
  constructor: function constructor(params) {
    this._type = 'GuidePage';

    this._IPermissions.constructor.call(this, params, params._permissions);

    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });

    this._ITimedPublishing.constructor.call(this, params);
  }
});
export default GuidePage;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'GuidePage',
  getPrototype: function getPrototype() {
    return GuidePage;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};

    if (!data._permissions) {
      // Permissions for newly created object
      assert(IRoleManager.providedBy(roleManager), 'No RoleManager passed during creation of GuidePage');
      data._permissions = new IRoleManagerAdapter(roleManager).getPermissions(this._name);
      data._roleManagerId = roleManager._id;
    }

    return new GuidePage(data);
  }
});