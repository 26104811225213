import { Component } from 'inferno'
import { safeGet, safeJoin } from 'safe-utils'

// Formlib widget for ImageField
import 'app-field-ImageField/widgets'

import {
  Body,
  MessageCanvas,
  Section,
} from 'influence-ux-components'
import { ResponsiveImage } from 'influence-ux-responsive-image'

import { IUser } from 'app-entity-User'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'

import 'app-entity-User/widgets/EditForm.scss'
import './Dashboard.scss'
import anonAvatar_svg from 'app-widget-common/img/anon_avatar.svg'


function _calcProgress(scrollY, start, end) {
  var scrollProgress = (scrollY > start ? (scrollY - start) / (end - start) : 0)
  if (scrollProgress > 1) {
    scrollProgress = 1
  }

  return scrollProgress
}

class ScrollAwareImg extends Component {

  constructor(props) {
    super(props)

    var scrollY = (typeof window !== 'undefined' ? window.scrollY : 0)

    this.state = {
      scrollY: scrollY,
      scrollProgress: _calcProgress(scrollY, this.props.start || 0, this.props.end || 0),
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.didScroll.bind(this), {
      passive: true
    })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.didScroll.bind(this), {
      passive: true
    })
  }

  didScroll (e) {
    var self = this

    if (!this.animFrame) {
      var scrollY = window.scrollY

      // Don't repeatedly fire scroll events if outside boundary
      if ((scrollY < this.props.start && this.state.scrollProgress <= 0) ||
        (scrollY > this.props.end && this.state.scrollProgress >= 1)) return

      this.animFrame = window.requestAnimationFrame(function () {
        var scrollProgress = _calcProgress(scrollY, self.props.start || 0, self.props.end || 0)
        self.setState({
          scrollY: scrollY,
          scrollProgress: scrollProgress
        })
        delete self.animFrame
      })
    }
  }

  render (props, state, context) {
    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser() || {}

    const scale = (0.5 + (1 - this.state.scrollProgress) * 0.5)
    const styles = {
      transform: 'scale(' + scale + ', ' + scale + ')',
      opacity: (1 - this.state.scrollProgress * 0.5)
    }

    return (
      <div class="AvatarWrapper" style={styles}>
        <img class="Avatar" src={safeGet(() => currentUser.avatarUrl, anonAvatar_svg)} />
      </div>
    )
  }
}

export default class DashboardPage extends Component {

  constructor (props, context) {
    super(props)

    this.state = {
      value: { blogName: safeGet(() => new ISessionManager({ registry: context.registry }).getCurrentUser().blogName) },
      validationError: undefined,
      actionBarBoundary: {top: 0, bottom: 0}
    }

  }

  componentDidUpdate () {
    // Navigate to blog list view if we have a blog
    const data = this.props.fetchData
    if (data) {
      const blogNameRedirect = data.mainBlogName ? data.mainBlogName : data.mainBlogId
      if (blogNameRedirect) {
        return safeGet(() => this.context.router.history.push(`/edit/${blogNameRedirect}`))
      }
    }
  }

  static async fetchData ({registry, match, location, router}) {
    const { data } = await new IApiClient({ registry }).query({
      URI: '/session'
    })

    if (!data) return undefined

    if (IUser.providedBy(data)) {
      // Check that user has accepted terms and conditions
      if (safeGet(() => data.termsAndConditions.name) != 'onboarding-terms') {
        return setTimeout(() => router.history.push('/register_3'), 100)
      }

      // Check that user has set birth datee
      if (!data.birthDate) {
        return setTimeout(() => router.history.push('/register_4'), 100)
      }
      
      // Check if user has a blog
      const blogName = data.mainBlogName || data.mainBlogId
      if (!blogName) {
        return setTimeout(() => router.history.push('/register_5'), 100)
      }

      const { data: blog } = await new IApiClient({ registry }).query({
        URI: `/content/Blog/${blogName}`
      })

      // Check if user has presentation
      if (!blog.profilePost) {
        return setTimeout(() => router.history.push(`/edit/${blogName}/presentation`), 100)
      }

      return setTimeout(() => router.history.push(`/edit/${blogName}`), 100)
    }

    return data
  }

  render (props, state, context) {
    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser() || {}

    return (
      <div className="Fullpage Onboarding">
        <Body className="Dashboard">
          <Section hasColumns className="AvatarWrapper">
            {currentUser.avatarUrl && <ResponsiveImage className="Avatar" image={currentUser.avatarUrl} />}
            {!currentUser.avatarUrl && <img  className="Avatar" src={anonAvatar_svg} />}
          </Section>
          <Section hasColumns className="FullName">{safeJoin([currentUser.firstName, currentUser.lastName], ' ')}</Section>
          <MessageCanvas>TODO: Create a dashboard</MessageCanvas>
        </Body>
      </div>
    )
  }
}