import { Component } from 'inferno'
import classnames from 'classnames'
import { safeGet } from 'safe-utils'
import { IImageHelper } from 'influence-interfaces/presentation'
import { IImage } from 'app-entity-Image'
import anonAvatar_svg from 'app-widget-common/img/anon_avatar.svg'
import { utils as aninmationUtils } from 'inferno-animation'

const { registerTransitionListener } = aninmationUtils

export class Avatar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      animState: undefined,
      animAnimating: undefined
    }
  }
  componentDidUpdate (lastProps) {
    if (this.props.active && !lastProps.active) {
      const targetEl = this.targetEl = document.getElementById(this.props.targetElId)
  
      const { width, left, top } = targetEl.getBoundingClientRect()
      const endRect = this.imgWrapperEl.getBoundingClientRect()
  
      this.startAnimTransform = {
        opacity: 1,
        scaleX: width / endRect.width,
        scaleY: width / endRect.width,
        x: left - endRect.left,
        y: top - endRect.top
      }
      
      this.setState({
        animState: 'start'
      }, () => {
        // TODO: Implement with transition-end events
        registerTransitionListener(this.imgEl, () => {
          this.setState({
            animState: undefined,
            animAnimating: undefined
          })
        })
       
        setTimeout(() => {
          this.setState({
            animState: 'end',
            animAnimating: true
          })
        }, 10)
      })
    }
  }

  render () {
    let style
    if (this.state.animState === 'start') {
      // style = this.startAnimCss
      const { opacity, x, y, scaleX, scaleY } = this.startAnimTransform
      style = {
        transform: `translate(${x}px, ${y}px) scale(${scaleX},${scaleY})`,
        opacity
      }
    }
    else if (this.state.animState === 'end') {
      style = {
        transform: 'translate(0,0) scale(1,1)',
        opacity: 1
      }
    }

    let src = anonAvatar_svg
    let srcSet
    
    if (IImage.providedBy(this.props.image)) {
      const imgHlpr = new IImageHelper(this.props.image)
      src = imgHlpr.getThumbSrc()
      srcSet = imgHlpr.getThumbSrcSet()
    }

    return (
      <div  ref={(el) => this.imgWrapperEl = el} className="AvatarWrapper">
        <img
          ref={(el) => this.imgEl = el}
          style={style}
          className={classnames("Avatar", {
            "Avatar-animating": this.state.animAnimating,
            "Avatar-animateStart": this.state.animState === 'start',
            "Avatar-animateEnd": this.state.animState === 'end'
          })} src={src} srcSet={srcSet} />
      </div>
    )
  }
}