import { Utility } from 'component-registry'
import { en } from './en'
import { ITranslationUtil, ITranslationLang } from 'influence-interfaces/i18n'

const langLibrary = { en }

new Utility({
  implements: ITranslationUtil,
  message (label, lang) {
    // Use 'en' as fallback language
    const langDict = langLibrary[lang] || langLibrary['en']
    // Use label as fallback string
    return langDict[label] || label
  }
})


new Utility({
  implements: ITranslationLang,
  lang () {
    return 'en'
  }
})
