import { createElement } from 'inferno-create-element'
import { utils } from 'inferno-animation'
const {
  addClassName,
  animationIsRunningOnParent,
  removeClassName,
  registerTransitionListener,
  forceReflow,
  setDisplay
} = utils

export function AnimatedImage ({ prefix, onDidEnter, onDidLeave, ...attrs}) {

  return <OriginalAnimated {...attrs}
    onComponentDidMount={(dom) => animateOnAdd(dom, prefix, onDidEnter)}
    onComponentWillUnmount={(dom) => animateOnRemove(dom, prefix, onDidLeave)} />
}

function OriginalAnimated ({ el, tag, children, ...attrs}) {
  return createElement(
    tag || el || 'div', 
    attrs, 
    children
  )
}

const animateOnRemove = function (node, animationName, callback) {
  if (animationIsRunningOnParent(node)) return

  let animCls = {}
  if (typeof animationName === 'object') {
    animCls = animationName
  } else {
    animCls['start'] = animationName + '-leave'
    animCls['active'] = animationName + '-leave-active'
    animCls['end'] = animationName + '-leave-end'
  }

  // 1. Clone DOM node, inject it and hide original
  const clone = node.cloneNode(true)
  addClassName(clone, animCls.start)
  // Leaving original element so it can be removed in the normal way
  setDisplay(node, 'none !important')
  node.insertAdjacentElement('beforebegin', clone)

  // 2. Activate transitions
  addClassName(clone, animCls.active)

  // 3. Set an animation listener, code at end
  // Needs to be done after activating so timeout is calculated correctly
  registerTransitionListener(clone, function () {
    // *** Cleanup ***
    callback && callback(clone)
    clone.remove()
  })

  // 4. Activate target state
  setTimeout(() => {
    addClassName(clone, animCls.end)
    removeClassName(clone, animCls.start)
  }, 5)
}

const animateOnAdd = function (node, animationName, callback) {
  if (animationIsRunningOnParent(node)) return

  let animCls = {}
  if (typeof animationName === 'object') {
    animCls = animationName
  } else {
    animCls['start'] = animationName + '-enter'
    animCls['active'] = animationName + '-enter-active'
    animCls['end'] = animationName + '-enter-end'
  }

  // 1. Get height and set start of animation
  addClassName(node, animCls.start)
  forceReflow()

  // 2. Activate transition
  addClassName(node, animCls.active)

  // 3. Set an animation listener, code at end
  // Needs to be done after activating so timeout is calculated correctly
  registerTransitionListener([node, node.children[0]], function () {
    // *** Cleanup ***
    // 5. Remove the element
    removeClassName(node, animCls.active)
    removeClassName(node, animCls.end)
    
    // 6. Call callback to allow stuff to happen
    callback && callback(node)
  })
 
  // 4. Activate target state
  if (node.complete) {
    // Image has been loaded
    setTimeout(() => {
      removeClassName(node, animCls.start)
      addClassName(node, animCls.end)
    }, 5)
  }
  else {
    node.addEventListener('load', () => {
      removeClassName(node, animCls.start)
      addClassName(node, animCls.end)
    })
  }
}