import { Component } from 'inferno'
import classnames from 'classnames'
import { Animated } from 'inferno-animation'

import { CloseButton, ButtonBar } from 'influence-ux-components'

import { IViewForm } from 'influence-interfaces/presentation'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import '../admin/Edit.scss'
import './page.scss'


const PageNavbar = ({onClose, className, ...props}) => {
  
  return (
    <div className={classnames('PageNavbar', className)} {...props}>
      <ButtonBar className="Actions">
        <CloseButton onClick={(e) => {e.preventDefault(); onClose()}}>Close</CloseButton>
        <div className="spacer" />
      </ButtonBar>
    </div>
  )
}

export default class Page extends Component {

  static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
        URI: `/content/Guide/${match.params.contentId}`
    })

    return data
  }

  componentDidMount () {
    window.scroll(0, 0)
  }

  doClose = () => {
    const { title: blogName } = new ISessionManager().getCurrentRoleManager()
    this.context.router.history.push(`/${blogName}/Profile`)
    // TODO: Check history length, if 0 go to profile (for now)
    // this.context.router.history.goBack()
  } 

  render () {
    const data = this.props.fetchData

    let GuideIntroPage = null
    if (data) {
        GuideIntroPage = new IViewForm(data).Component
    }

    if (!GuideIntroPage) return null

    return (
      <Animated key="me" className="Guide ViewPage" prefix="PageNav">
        <PageNavbar onClose={this.doClose} />
        <GuideIntroPage context={data} />
      </Animated>
    )
  }
}
