/**
 * Geometric point as defined in GeoJSON
 * https://tools.ietf.org/html/rfc7946#section-3.1.1
 * 
 * This field is the equivalent of the geometry object
 * in a GeoJSON feature object.
 * 
 * ex: 
 * 
 * "geometry": {
        "type": "Point",
        "coordinates": [
          16.171875,
          52.482780222078226
        ]
      }
 */
import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { BaseField, i18n } from 'isomorphic-schema';
var Interface = createInterfaceClass('app-field');
export var IGeoPointField = new Interface({
  name: 'IGeoPointField'
});
export var GeoPointField = createObjectPrototype({
  implements: [IGeoPointField],
  extends: [BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  },
  validate: function validate(inp) {
    var error = this._IBaseField.validate.call(this, inp);

    if (error) {
      return error;
    } // TODO: Check for line breaks


    if (inp && typeof inp !== "object") {
      return {
        type: 'type_error',
        i18nLabel: i18n('influence-app-entities--field_no_geotype', 'The field isn\'t an object'),
        message: "Fältet är inte en sträng"
      };
    }

    if (inp && !(Array.isArray(inp.coordinates) && inp.coordinates.length >= 2 && inp.coordinates.length <= 3 && typeof inp.coordinates[0] === 'number' && // long
    typeof inp.coordinates[1] === 'number' && ( // lat
    inp.coordinates[2] === undefined || typeof inp.coordinates[2] === 'number') && // optional elevation
    inp.type === 'Point')) {
      return {
        type: 'type_error',
        i18nLabel: i18n('influence-app-entities--field_no_geotype', 'The field isn\'t a proper geometry point'),
        message: "Fältet är inte en sträng"
      };
    }
  },
  fromString: function fromString(inp) {
    // Convert simple object with named props to proper GeoJSON Point geometry object
    var coordinates = [inp.latitude, inp.longitude]; // Optional

    if (inp.elevation !== undefined) {
      coordinates.push(inp.elevation);
    }

    return {
      type: 'Point',
      coordinates: coordinates,
      description: inp.description
    };
  },
  toFormattedString: function toFormattedString(inp) {
    // Convert to simple object with named props
    var outp = {
      description: inp.description,
      longitude: inp.coordinates && inp.coordinates[0],
      latitude: inp.coordinates && inp.coordinates[1],
      elevation: inp.coordinates && inp.coordinates[2]
    };
    return outp;
  }
});