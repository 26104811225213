import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import { createComponentVNode, normalizeProps } from "inferno";
import { Adapter, Utility } from 'component-registry';
import { Modal } from 'influence-ux-components';
import { LiveItem } from './LiveItem';
import { ILightbox } from 'influence-interfaces/presentation';
import { IPlaceCard } from '../interface'; // import '../LiveListItem.scss'

import './LiveListItem.scss';

function Lightbox(props) {
  return createComponentVNode(2, Modal, {
    "fade": true,
    "isOpen": props.isOpen,
    "doClose": props.onToggle,
    children: normalizeProps(createComponentVNode(2, LiveItem, _objectSpread({}, props)))
  });
}

new Adapter({
  implements: ILightbox,
  adapts: IPlaceCard,
  Component: Lightbox
});
new Utility({
  implements: ILightbox,
  name: 'PlaceCard',
  Component: Lightbox
});