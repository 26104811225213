import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, BoolField, TextField, i18n } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { ImageListField } from 'app-field-ImageListField';
import { GalleryLayoutField } from 'app-field-GalleryLayoutField';
var IMediaCard = new Interface({
  name: 'IMediaCard',
  schema: new Schema('MediaCard Schema', {
    shortDescription: new TextField({
      label: i18n('IMediaCard-shortDescription-label', 'Caption')
    }),
    // TODO:  Deprecated
    image: new ImageField({
      label: i18n('ICard-image-label', 'Image'),
      placeholder: i18n('ICard-image-placeholder', 'Drag and drop...'),
      help: i18n('ICard-image-help', 'Only JPEG or PNG. Max size 5Mb.')
    }),
    // TODO: Deprecate
    multiple: new BoolField({
      label: 'Multiple images'
    }),
    images: new ImageListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    // TODO: Deprecate, always allow crop
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    })
  })
});
export { IMediaCard };