import { Component, createRef } from 'inferno'
import { safeGet } from 'safe-utils'
import { IPublicListItem, IPublicForm, IImageHelper } from 'influence-interfaces/presentation'
import { IApiClient, ISessionManager, IPageManager } from 'influence-interfaces/presentation'
import { Placeholder } from '../../widgets/Placeholder'
import querystring from 'querystring'

import '../admin/List.scss'
import '../admin/Dashboard.scss'
import './Blog.scss'
import {
  Form,
  MessageCanvas,
  reflowCoordinator,
} from 'influence-ux-components'
import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'

import 'app-field-WorkflowFilterField/widgets'
import SectionPage from '../SectionPage'
import { FormRows } from 'influence-ux-formlib'
import { InfinityScroll } from 'app-widget-InfinityScroll'
import { isNullOrUndefined } from 'app-card-Card/widgets'

const filterSchema = new Schema('Filter Schema', {
  search: new TextField({
    label: 'Filter list:',
    placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
  })
})

// How many pixels from top should we update the adressbar to next post
const _ADDRESS_CHANGE_MARGIN_TOP = 80

var oldId // This is just for development, remove

function _getPostId(post) {
  return post && (post._pathId || post._id)
}

function _getPostSlug(post) {
  if (post && post._slug) {
    return '/' + post._slug
  }
  else {
    return ''
  }
}

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

function _setMetaData (registry, post) {
  const blog = new ISessionManager({ registry }).getCurrentRoleManager()
  const metaData = {
    title: safeGet(() => `${post.title} `, '') + `(by ${blog.title})`,
    description: safeGet(() => post.subject || '', ''),
    url: `${FRONTEND_BASE_URI}/${blog.title}` + safeGet(() => `/${post._pathId}/${post._slug}`, '')
  }

  // If the post has no image set, use the first card with images
  if (!metaData['image']) {
    if (safeGet(() => Array.isArray(post.body.cards))) {
      const firstCardWithImages = post.body.cards.reduce((prev, curr) => {
        if (prev) return prev
        else if (Array.isArray(curr[1].images) && curr[1].images.length > 0) return curr[1] // The actual card is the second element
        else return undefined
      }, undefined)
      if (firstCardWithImages) {
        // Get sharing image
        const images = firstCardWithImages.images.filter(img => img) // no undefined
        const imgHlpr = new IImageHelper(images[0])
        const { width, height } = images[0].rendered[0]
        const imageMetaData = {
          url: imgHlpr.getSrc(width),
          width,
          height
        }
        metaData['image'] = imageMetaData
        // If we use a card image, we should also use the card description
        metaData['description'] = firstCardWithImages.shortDescription
      }
    }
  }

  new IPageManager({ registry }).setMetaData(metaData)
  return metaData
}

export default class BlogPage extends Component {

  static limit = 1
  _articleEls = null
  _scrollRef = null
  _nextAdressUpdate = null

  constructor (props) {
    super(props)

    this.state = {
      search: undefined,
      blogPosts: safeGet(() => props.fetchData.entries, []),
      queryIsLoading: false,
      page: 2, // First page is always loaded by router
      hasMore: safeGet(() => props.fetchData.hasMore, true),
    }

    this._articleEls = {}
    this._scrollRef = createRef()
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    const URI = `/content/BlogPost`

    const { search } = location.search || {}
    const query = { 
      search: (search && search.length >= 3 ? { 
        title: search,
        fullFreetext: search
      } : undefined), 
      stateFilter: 'publishWorkflow.published'
    }
    
    if (match.params.roleManagerId) {
      query['roleManagerId'] = match.params.roleManagerId
      console.log(`Got roleManagerId  ${query['roleManagerId']}`)

      // If we have selected a specific entry to show, this is nr one
      const contentId = match.params.contentId

      if (contentId && page === 1) {
        const { data: post } = await new IApiClient({ registry }).query({
          URI: `${URI}/${contentId}`,
        })
        const metaData = _setMetaData(registry, post)
        // Make sure we always visit the correct url
        if (!metaData.url.endsWith(location.pathname)) {
          router.history.push(metaData.url)
        }
        return { entries: [post], hasMore: true }
      }
      else {
        console.log('Calling API: ' + URI)
        let { data: entries } = await new IApiClient({ registry }).query({
          URI,
          query: { 
            query,
            page,
            limit: BlogPage.limit
          }
        })
        console.log(`got ${entries.length} results`)
        
        let hasMore = true
        if (contentId) {
          // Hack during development to remove selected post
          const entry = entries.find((entry) => _getPostId(entry) === contentId)
          entries = entries.filter((entry) => _getPostId(entry) !== contentId)
        }
        else if (entries.length === 0) {
          hasMore = false
        }
        entries.sort((a, b) => b._createdAt - a._createdAt)

        if (page === 1 && entries.length > 0) {
          _setMetaData(registry, entries[0])
        }
  
        return { entries, hasMore }
      }
    }
    else {
      console.log(`No roleManagerId`)
      const data = await new IApiClient({ registry }).query({
        URI,
        query: { 
          query,
          page: 1,
          limit: BlogPage.limit
        }
      })
      
      return {
        entries: data.sort((a, b) => b._createdAt - a._createdAt),
        hasMore: data.length > 0
      }
    }
  }

  componentDidMount () {
    reflowCoordinator.subscribe({
      sharedStateRef: this._scrollRef,
      vNode: this,
      callback: this.didScroll
    })
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const newState = {}

    // Make sure load more is enabled when search is manipulated
    if (nextProps.location.search !== this.props.location.search) {
      newState['hasMore'] = true
      newState['page'] = 1 // Reset the page count (this is a quickfix, should refactor)
    }

    // Append newly loaded
    const nextBlogPost = safeGet(() => nextProps.fetchData.entries)
    const prevBlogPost = safeGet(() => this.props.fetchData.entries)
    
    if (nextBlogPost === undefined && prevBlogPost !== undefined) {
      newState['blogPosts'] = []
      this._articleEls = []
    }
    else if (Array.isArray(nextBlogPost)) {
      newState['blogPosts'] = nextBlogPost
      this._articleEls = []
    }
    else if ((_getPostId(nextBlogPost)) !== (_getPostId(prevBlogPost))) {
      newState['blogPosts'] = [nextBlogPost]
      this._articleEls = []
    }

    this.setState(newState)
  }

  // TODO: Fix scroll more...
  didScroll = (phase, sharedState) => {
    const scrollEl = this._scrollRef.current

    if (scrollEl) {
      switch (phase) {
        case 'calc':
          const scrollTop = sharedState.scrollTop()

          // Check if we need to update address bar
          let newId
          const tmpKeys = Object.keys(this._articleEls)
          for (let i=tmpKeys.length - 1; i >= 0 ; i--) {
            const key = tmpKeys[i]
            const el = this._articleEls[key]

            // Check for empty entries
            if (!el) continue

            const topOfEl = el.offsetTop
            if (scrollTop > (topOfEl - _ADDRESS_CHANGE_MARGIN_TOP)) {
              newId = key
              break
            }
          }

          // Get post so we can determine title
          let post
          if (newId && this.state.blogPosts.length > 0) {
            for (let j = 0; j < this.state.blogPosts.length; j++) {
              const curr = this.state.blogPosts[j]
              if (curr != null
                && (curr._pathId === newId || curr._id === newId)) {
                post = curr
                break
              }
            }
          }

          if (newId && newId !== oldId) {
            this._nextAdressUpdate = {
              newId,
              post
            }
          }
          return sharedState
        case 'setState':
          if (this._nextAdressUpdate != null) {
            const firstPost = safeGet(() => this.props.fetchData.entries[0])
            const { newId, post } = this._nextAdressUpdate
            // Remove this and "var oldId" when we implement the proper location replace
            const { roleManagerId } = this.props.match.params
            const search = window.location.search
            if (newId === _getPostId(firstPost) && `/${roleManagerId}` === this.props.location.pathname) {
              // Don't add blogId to pathname if we entered through the root blog url
              window.history.replaceState(undefined, "", `${this.props.location.pathname}${search}`)
            }
            else {
              window.history.replaceState(undefined, "", `/${roleManagerId}/${newId}${(search ? search : _getPostSlug(post))}`)
            }
            oldId = newId

            this._nextAdressUpdate = null
          }
          break
      }
    }
  }

  loadMore = async (go) => {
    // Bail out if marked empty
    if (!this.state.hasMore) return

    if (!go) {
      // Debounce load more calls
      if (!isNullOrUndefined(this._loadMoreTimer) || this.state.queryIsLoading) return

      return this._loadMoreTimer = setTimeout(async () => {
        await this.loadMore(true)
      }, 100)
    }

    this.setState({ queryIsLoading: true })
    
    const { entries, hasMore } = await BlogPage.fetchData({
      match: this.props.match,
      location: this.props.location,
      router: this.context.router,
      page: this.state.page
    })

    if (entries.length > 0) {
      const blogPosts = this.state.blogPosts.concat(entries)
      this.setState({
        blogPosts
      })
    }
    
    this._loadMoreTimer = undefined
    setTimeout(() => {
      this.setState({
        queryIsLoading: false,
        page: this.state.page + 1,
        hasMore
      })
    }, 100)
  }

  didUpdate = (propName, value) => {
    const { pullUpMenu, ...state } = this.state
    state[propName] = value
    const search = Object.keys(state).filter((key) => state[key]).map((key) => key + '=' + state[key]).join('&')
    const router = this.context.router
    router.history.push(router.route.location.pathname + (search ? '?' + search : ''))
  }

  renderFilterForm () {
    const filterValue = this.state

    return (
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }

  renderPost (blogPost) {
    if (!blogPost) return null

    try {
      let Renderer = new IPublicForm(blogPost).Component
      return [
        <div className="IPublicForm" ref={(el) => this._articleEls[_getPostId(blogPost)] = el}><Renderer context={blogPost} /></div>,
        <AdWidget />
      ]
    }
    catch (e) {
      return null
    }
  }

  renderInfinityScroll () {
    if (this.state.blogPosts.length > 0) {
      return (
        <InfinityScroll
            scrollRef={this._scrollRef}
            className="LivePage-Feed"
            hasMore={this.state.hasMore}
            queryIsLoading={this.state.queryIsLoading}
            loadMorePadding={100}
            onLoadMore={this.loadMore}>
            {this.state.blogPosts.map(blogPost => this.renderPost(blogPost))}
          </InfinityScroll>
      )
    }
    else {
      return <div className="IEditItem"><p className="placeholder">I know I'm supposed to write amazing posts, but I haven't got around to it yet...</p></div>
    }
  }

  renderSearchContainer () {
    return [
      <h2 className="SearchHeader">Search Results:</h2>,
      <InfinityScroll
        scrollRef={this._scrollRef}
        className="LivePage-Feed"
        hasMore={this.state.hasMore}
        queryIsLoading={this.state.queryIsLoading}
        loadMorePadding={100}
        onLoadMore={this.loadMore}>
        {this.state.blogPosts.map(blogPost => this.renderPost(blogPost))}
      </InfinityScroll>,
      (this.state.blogPosts.length === 0) && <div>
        <MessageCanvas>Search and you will find...</MessageCanvas>
      </div>,
    ]
  }

  render () {
    const { location } = this.context.router.history
    const { search } = querystring.parse(location.search.replace(/^\?/, ''))
    const searchMode = (search ? true : false)
    return (
      <SectionPage className="LivePage"
        scrollRef={this._scrollRef}
        type="BlogPost"
        match={this.props.match}>
        {!searchMode && this.renderInfinityScroll()}
        {searchMode && this.renderSearchContainer()}
      </SectionPage>
    )
  }
}


function AdWidget () {
  return (
    <div className="InterPost" />
  )
}