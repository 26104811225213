function _getCursorOffset(editor) {
  var parentEl = editor.range.tail.marker.renderNode.markupElement.parentElement;
  var textNode = editor.range.tail.marker.renderNode.markupElement;

  while (textNode instanceof Element) {
    textNode = textNode.firstChild;
  }

  var clone = document.createElement('div'); // Traverse through previous siblings in this renderNode to allow us to find
  // the cursor offset using editor.range.tailSectionOffset
  // Some test cases: First text node, inline element (em/i), nested inline element, last text node

  var _curr = textNode;

  while (_curr !== null && _curr !== parentEl) {
    if (_curr.previousSibling === null) {
      _curr = _curr.parentElement;
    }

    if (_curr && _curr !== parentEl) {
      _curr = _curr.previousSibling ? _curr.previousSibling : _curr.parentNode.previousSibling;

      if (_curr) {
        // Add all nodes to that element
        clone.prepend(_curr.cloneNode(true));
      }
    }
  } // _.tailSectionOffset_ is the character position within parent element
  // _.splitText_ takes the character position within the current text node


  var iEl;

  if (editor.range.tailSectionOffset > 0) {
    textNode.splitText(editor.range.tailSectionOffset - clone.innerText.length);
    textNode.after(document.createElement('i'));
    iEl = textNode.nextElementSibling;
  } else {
    textNode.before(document.createElement('i'));
    iEl = textNode.previousElementSibling;
  }

  var cursorPosY = iEl.offsetTop + iEl.offsetHeight;
  _curr = iEl.offsetParent;

  while (_curr !== null) {
    cursorPosY += _curr.offsetTop;
    _curr = _curr.offsetParent;
  }

  iEl.remove();
  textNode.parentNode.normalize(); // Debug: console.log('Cursor position: ' + cursorPosY)

  return {
    y: cursorPosY
  };
}

var _prevEl;

var didCreateEditor = function didCreateEditor(e) {
  _prevEl = undefined;
};

function _scrollTo(el, y) {
  if (el === window) {
    window.scrollTo(0, y);
  } else {
    el.scroll(0, y);
  }
}

export var onCursorDidChange = function onCursorDidChange(editor, currCursorPos, scrollableEl) {
  if (!scrollableEl) return; // Keep cursor position at last known location

  var renderNode = editor.range.tail.marker.renderNode;

  if (renderNode === undefined || renderNode.markupElement === undefined) {
    return currCursorPos;
  }

  var element = editor.range.tail.marker.renderNode.markupElement.parentElement;
  var newCursorPosY; // TODO: Find other way to compare!

  if (_prevEl !== null || _prevEl.isSameNode(element)) {
    // TODO: Don't scroll if element is out of viewport
    if (editor.range.isCollapsed) {
      // Only do this if range is collapsed (at least for now)
      var _getCursorOffset2 = _getCursorOffset(editor),
          cursorPosY = _getCursorOffset2.y;

      newCursorPosY = cursorPosY;

      if (currCursorPos !== undefined && currCursorPos === newCursorPosY) {
        // currCursorPos is only passed on animFrames. If we haven't
        // moved the cursor we shouldn't scroll the page
        return currCursorPos;
      }

      var scrollY = scrollableEl === window ? window.scrollY : scrollableEl.scrollTop;

      if (scrollY > cursorPosY - 100) {
        // Cursor is scrolled out of view
        _scrollTo(scrollableEl, cursorPosY - 100 > 0 ? cursorPosY - 100 : 0);
      } else if (cursorPosY + 100 > scrollY + window.innerHeight) {
        _scrollTo(scrollableEl, cursorPosY + 100);
      }
    }
  } else {
    // Measure where we are in document by
    console.log('jumped');
  }

  _prevEl = element;
  return newCursorPosY;
};