import { Component } from 'inferno'
import classnames from 'classnames'

import { Page } from 'influence-ux-components'

import { typeMapping } from './_typeMapping'

import { ICreateForm } from 'influence-interfaces/presentation'
import './Edit.scss'

export class CreatePage extends Component {

    static async fetchData ({match, location}) {
      // Just triggering update of RoleManager
      return
    }

    render (props, state, context) {
      const { type } = context.router.route.match.params
      let EditForm = new ICreateForm(typeMapping(type)).Component

      return (
        <Page key="page" className={classnames("EditPage", type)} animationPrefix="PageNav">
          <EditForm {...this.props} />
        </Page>
      )
    }
}
