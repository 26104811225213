import { createInterfaceClass, createObjectPrototype } from 'component-registry';
import { BaseField } from 'isomorphic-schema';
import assert from 'assert';
var Interface = createInterfaceClass('app-field');
export var IMobiledocField = new Interface({
  name: 'IMobiledocField'
});
export var MobiledocField = createObjectPrototype({
  implements: [IMobiledocField],
  extends: [BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);

    assert(options.toolbar, 'Field is missing the property "toolbar". Should match name of a toolbar widget utility');
  }
});