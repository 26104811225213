import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { i18n, Schema, TextField, TextAreaField } from 'isomorphic-schema';
import { GuideContentListField } from 'app-field-GuideContentListField';
import { GuideContentListRowField } from 'app-field-GuideContentListRowField';
import { GuidePageRelationField } from 'app-field-GuidePageRelationField';
export var IGuideChapter = new Interface({
  name: 'IGuideChapter',
  schema: new Schema('Guide Chapter Schema', {
    title: new TextField({
      placeholder: i18n('IGuideChapter-title-placeholder', 'Chapter title here...')
    }),
    shortDescription: new TextAreaField({
      placeholder: i18n('IGuideChapter-shortDescription-placeholder', 'Short description here...')
    }),
    content: new GuideContentListField({
      valueType: new GuideContentListRowField({
        valueTypes: [new GuidePageRelationField({})]
      })
    })
  })
});