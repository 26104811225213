import { Component, Fragment, createRef } from 'inferno'
import querystring from 'querystring'
import { Animated } from 'inferno-animation'
import { Filter, Sticky } from 'influence-ux-components'
import { IPublicForm, INotificationManager } from 'influence-interfaces/presentation'
import { IRelatedItem } from 'app-widget-common'
import { IApiClient, ISessionManager, IPageManager } from 'influence-interfaces/presentation'
import '../admin/List.scss'
import '../admin/Dashboard.scss'
import '../admin/Edit.scss'
import 'app-entity-BlogPost/widgets/EditForm.scss'
import './Profile.scss'

import SectionPage from '../SectionPage'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class ProfilePage extends Component {

  _scrollRef = null

  state = {
    pullUpMenuHeight: 48
  }

  constructor(props) {
    super(props)
    this._scrollRef = createRef()
  }

  static async fetchData ({registry, match, location}) {
    const { data: blog } = await new IApiClient({ registry }).query({
      URI: `/content/Blog/${match.params.roleManagerId}`
    })
    // TODO: This won't work with SSR
    if (!blog.profilePost) {
      return undefined
    }

    const query = { 
      stateFilter: 'publishWorkflow.published',
      roleManagerId: blog._id
    }
    
    new ISessionManager({ registry }).setCurrentRoleManager(blog)
    const { data } = await new IApiClient({ registry }).query([
      { URI: `/content/BlogPost/${blog.profilePost.id}` },
      { URI: `/content/CityGuide`, query: { query, page: 1, limit: 5 } },
      { URI: `/content/RecipeCard`, query: { query, page: 1, limit: 5 } },
      { URI: `/content/Guide`, query: { query, page: 1, limit: 5 } }
    ])
    const post = data[0]

    new IPageManager({ registry }).setMetaData({
      title: `${post.title} (${post.title})`,
      description: post.subject || '',
      url: `${FRONTEND_BASE_URI}/${blog.title}/Profile`
    })
    
    return data
  }

  render () {
    const { location } = this.context.router.history
    const { search } = querystring.parse(location.search.replace(/^\?/, ''))
    const searchMode = (search ? true : false)
    return (
      <SectionPage className="LivePage Profile"
        scrollRef={this._scrollRef}
        match={this.props.match}>
        {!searchMode && this.renderProfile()}
        {searchMode && this.renderSearchContainer()}
      </SectionPage>
    )
  }

  renderToolbarContent () {
    return null
  }

  _createFilter () {
    const [ blogPost, cityGuides, recipeCards, guideCards ] = this.props.fetchData
    
    const filterOptions = [{ value: undefined, label: 'About Me'}]

    if (cityGuides.length > 0) filterOptions.push({ value: 'destinations', label: 'Destinations' })
    if (recipeCards.length > 0) filterOptions.push({ value: 'recipes', label: 'Recipes' })
    if (guideCards.length > 0) filterOptions.push({ value: 'guides', label: 'Guides & Tutorials' })
    
    const { location } = this.context.router.route
    const { subForm } = querystring.parse(location.search.replace(/^\?/, ''))

    return { filterOptions, value: subForm }
  }

  renderDestinations (cards) {
    return <Animated className="Profile-Destinations" key="destinations" prefix="FormRow--Animation">
      <h2>Destinations</h2>
      {cards && cards.map((card) => {
        const Item = new IRelatedItem(card).Component
        return <Item context={card} />
      })}
    </Animated>
  }

  renderRecipes (cards) {
    return <Animated className="Profile-Recipes" key="recipes" prefix="FormRow--Animation">
      <h2>Recipes</h2>
      {cards && cards.map((card) => {
        const Item = new IRelatedItem(card).Component
        return <Item context={card} />
      })}
    </Animated>
  }

  renderGuides (cards) {
    return <Animated className="Profile-Guides" key="guides" prefix="FormRow--Animation">
      <h2>Guides & Tutorials</h2>
      {cards && cards.map((card) => {
        const Item = new IRelatedItem(card).Component
        
        // Don't show investor guide in list
        if (card._pathId == '0XGrF') return null
        
        return <Item context={card} />
      })}
    </Animated>
  }

  renderProfile () {
    if (this.props.fetchData) {

      const [ blogPost, cityGuides, recipeCards, recipeGuides ] = this.props.fetchData
      
      const {filterOptions, value} = this._createFilter()

      if (blogPost) {
        const Renderer = new IPublicForm(blogPost).Component
        return (<Fragment>
          <Sticky scrollRef={this._scrollRef} stickToTop>
            <Filter options={filterOptions} value={value} onChange={this.doChangeFilter} />
          </Sticky>
          <div className="IEditItem">
            {!value && <Animated key="about" className="profile-post" prefix="FormRow--Animation"><Renderer context={blogPost} /></Animated>}
            {value === 'destinations' && this.renderDestinations(cityGuides)}
            {value === 'recipes' && this.renderRecipes(recipeCards)}
            {value === 'guides' && this.renderGuides(recipeGuides)}
          </div>
        </Fragment>)
      }
    }
    
    return <div className="IEditItem"><p className="placeholder">I'm still a bit of an enigma!</p></div>
  }

  renderSearchContainer () {
    return (
      <h2 className="SearchHeader">Search Results:</h2>
    )
  }

  didUpdate = (propName, value) => {
    const currentUser = new ISessionManager().getCurrentUser()
    currentUser[propName] = value
    this.forceUpdate()
  }

  doChangeFilter = (value) => {
    const { match } = this.context.router.route
    let targetUrl
    if (value === undefined) {
      targetUrl = match.url
    }
    else {
      targetUrl = `${match.url}?subForm=${value}`
    }
    this.context.router.history.push(targetUrl)
  }

  doSubmit = (e) => {
    e.preventDefault()
    const currentUser = new ISessionManager().getCurrentUser()
    new IApiClient().update({
        URI: `/content/User/${currentUser._id}`,
        data: currentUser,
        invalidate: ['/content/User', '/session']
    }).then(({data}) => {
        // TODO: Show success indicator at center of screen
        new INotificationManager().showSuccessMessage()
    })
  }

}