import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-card');
import { Schema, SelectField, BoolField, TextField, TextAreaField, ListField, ObjectField, i18n } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { ImageListField } from 'app-field-ImageListField';
import { GalleryLayoutField } from 'app-field-GalleryLayoutField';
import { GeoPointField } from 'app-field-GeoPointField';
import { ConnectedTitleField } from 'app-field-ConnectedTitleField';
var spotsSchema = new Schema('SpotsSchema', {
  description: new TextAreaField({
    label: i18n('SpotsSchema-description-label', 'Place'),
    required: true
  }),
  location: new GeoPointField({
    label: i18n('SpotsSchema-location-label', 'Destination'),
    placeholder: i18n('SpotsSchema-location-placeholder', 'Type an address')
  })
});
var ICityGuide = new Interface({
  name: 'ICityGuide',
  schema: new Schema('CityGuide Schema', {
    title: new TextField({
      label: i18n('ICityGuide-title-label', 'Title'),
      placeholder: i18n('ICityGuide-title-placeholder', 'Type here...'),
      required: true
    }),
    connectedTitle: new ConnectedTitleField({
      labelActive: 'post title',
      labelInactive: 'card title',
      helpActive: i18n('ICard-connectedTitle-helpActive', 'You are connected'),
      helpInactive: i18n('ICard-connectedTitle-helpInactive', 'Now you are disconnected')
    }),
    images: new ImageListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    }),
    shortDescription: new TextAreaField({
      label: i18n('ICard-shortDescription-label', 'Short Description'),
      required: true
    }),
    funFacts: new ListField({
      label: i18n('ICityGuide-funFacts-label', 'Fun Facts'),
      valueType: new TextField({})
    }),
    topSpots: new ListField({
      label: i18n('ICityGuide-topSpots-label', 'My Top Spots'),
      valueType: new ObjectField({
        schema: spotsSchema
      })
    })
  })
});
export { ICityGuide };