import { createComponentVNode } from "inferno";
import { Adapter, Utility } from 'component-registry';
import { Body, ButtonBar, CloseButton, Modal } from 'influence-ux-components';
import { ILightbox, IViewForm } from 'influence-interfaces/presentation';
import { IGuide } from '../interface';
import './Lightbox.scss';

function Lightbox(props, state, context) {
  var GuideIntroPage = new IViewForm(props.context).Component;
  return createComponentVNode(2, Modal, {
    "fade": true,
    "className": "GuideLightbox Guide Lightbox",
    "isOpen": props.isOpen,
    "doClose": props.onToggle,
    children: [createComponentVNode(2, ButtonBar, {
      "className": "Actions modal-header",
      children: createComponentVNode(2, CloseButton, {
        "className": "CloseButton",
        "onClick": props.onToggle,
        children: "Close"
      })
    }), createComponentVNode(2, Body, {
      children: createComponentVNode(2, GuideIntroPage, {
        "context": props.context
      })
    })]
  });
}

new Adapter({
  implements: ILightbox,
  adapts: IGuide,
  Component: Lightbox
});
new Utility({
  implements: ILightbox,
  name: 'Guide',
  Component: Lightbox
});