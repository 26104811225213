import { createVNode, createComponentVNode } from "inferno";
import classnames from 'classnames';
import { safeGet } from 'safe-utils';
import { i18n } from 'influence-i18n';
import { renderTimedPublishingString } from './utils';
import { Button, Dropdown } from 'influence-ux-components';
import { FormRows } from 'influence-ux-formlib';
import { ITimedPublishing } from '../interface';
import IconClock from 'app-widget-common/img/icons/clock';
import './TimedPublishing.scss';

function TimedPublishing(_ref) {
  var post = _ref.post,
      isOpen = _ref.isOpen,
      validationError = _ref.validationError,
      didUpdate = _ref.didUpdate,
      onOpen = _ref.onOpen,
      onClose = _ref.onClose;
  var active = post.publishingStartsAt || post.publishingEndsAt ? true : false;
  return createVNode(1, "div", "TimedPublishing", [createVNode(1, "div", "status", renderTimedPublishingString(post.publishingStartsAt, post.publishingEndsAt), 0), createComponentVNode(2, Dropdown, {
    "direction": "up",
    "doClose": onClose,
    "customWidget": createVNode(1, "div", null, [createVNode(1, "div", "header", i18n('TimedPublishing-formHeader', 'Tidsinställd publicering'), 0), createVNode(1, "div", "body", createComponentVNode(2, FormRows, {
      "schema": ITimedPublishing.schema,
      "selectFields": "publishingStartsAt",
      "validationErrors": validationError,
      "value": post,
      "onChange": didUpdate
    }), 2)], 4),
    "isOpen": isOpen,
    children: createComponentVNode(2, Button, {
      "link": true,
      "className": "status-btn",
      "onClick": function onClick(e) {
        return isOpen ? onClose(e) : onOpen(e);
      },
      children: createComponentVNode(2, IconClock, {
        "className": classnames("Icon-Clock", {
          active: active
        })
      })
    })
  })], 4);
}

export { TimedPublishing };