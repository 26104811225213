import { Component } from 'inferno'
import classnames from 'classnames'
import querystring from 'querystring'
import { safeGet } from 'safe-utils'
import { i18n } from 'influence-i18n'
import { NavFilter, Button, IconButton, Form, NavButton, SwitchButton } from 'influence-ux-components'

import { Avatar } from './Avatar'
import { Animated } from 'inferno-animation'
import { 
  Schema,
  i18n as i18nStr
} from 'isomorphic-schema'
import { SearchField } from 'app-field-SearchField'

import { FormRows } from 'influence-ux-formlib'

import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { INotificationManager } from 'influence-interfaces/presentation'

import './MainMenu.scss'

function _showingMyBlog (currentUser, roleManagerId) {
  return safeGet(() => currentUser.mainBlogId === roleManagerId || currentUser.mainBlogName === roleManagerId)
}

export const MainMenu = (props, context) => {
  const { roleManagerId } = context.router.route.match.params
  const { pathname } = context.router.route.location
  let publicMode = true

  let activePath, prefix
  if (pathname.startsWith('/edit')) {
    activePath = pathname.substr(5)
    prefix = '/edit'
    publicMode = false
  }
  else {
    activePath = pathname
    prefix = ''
  }

  const { location } = context.router.history
  const { search } = querystring.parse(location.search.replace(/^\?/, ''))
  const searchMode = (search ? true : false)

  const menuOptions = [
    {
      isSelected: `/${roleManagerId}` === activePath || `/${roleManagerId}/BlogPost` === activePath,
      to: `${prefix}/${roleManagerId}`,
      label: i18n('MainMenu-posts', 'Inlägg')
    },
    {
      isSelected: `/${roleManagerId}/Cards` === activePath,
      to: `${prefix}/${roleManagerId}/Cards`,
      label: i18n('MainMenu-cards', 'Kort')
    },
    {
      isSelected: `/${roleManagerId}/Profile` === activePath,
      to: `${prefix}/${roleManagerId}/Profile`,
      label: i18n('MainMenu-profile', 'Profile')
    },
  ]

  return (
    <div className={classnames("MainMenu", { search:  search }, props.classNames)}>
      <div className="MainMenu-Slot EditSlot">
        {!searchMode && <EditLiveMenu />}
      </div>
      <div className="MainMenu-Slot NavSlot">
        {!search &&
          <Animated key="nav" prefix="NavBar">
            <NavFilter options={menuOptions} />
          </Animated>
        }
      </div>
      <div className="MainMenu-Slot SearchSlot">
          {publicMode && <SearchMenu />}
          {!publicMode && props.editBlogVisibility && <BlogPublicSwitch isPublic={props.blogIsPublic} />}
      </div>    
    </div>
  )
}

const filterSchema = new Schema('Filter Schema', {
  search: new SearchField({
    label: 'Filter list:',
    placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
  })
})


class BlogPublicSwitch extends Component {

  state = {
    public: undefined,
  }

  componentDidMount() {
    const currentBlog = new ISessionManager().getCurrentRoleManager()
    const isPublic = currentBlog._workflows.publishWorkflow === 'published'

    if (this.state.public !== isPublic) {
      this.setState({
        public: isPublic
      })
    }
  }

  render () {
    return (
      <SwitchButton primary className="BlogPublicSwitch" isSelected={this.state.public} 
        textDefault="Blog is hidden"
        textSelected="Blog is public"
        onClick={this.didClick} />
    )
    // return (
    //   <IconButton icon={IconSwitch} className={classnames("BlogPublicSwitch", cls)} onClick={this.didClick}>{this.state.public
    //     ? 'PUBLIC'
    //     : 'Make public'}</IconButton>
    // )
  }

  didClick = (e) => {
    e.preventDefault()

    this.setState({
       public: !this.state.public
    })
    setTimeout(() => {
      const blog = new ISessionManager().getCurrentRoleManager()
      this.context.router.history.push(`/edit/${blog.title}/Profile`)
      
      // Call API
      blog._workflows.publishWorkflow = this.state.public ? 'published' : 'draft'
    
      new IApiClient().update({
        URI: `/content/Blog/${blog._id}`,
        data: blog,
        invalidate: ['/content/Blog']
      }).then(() => {
        new ISessionManager().setCurrentRoleManager(blog)
        // TODO: Show success indicator at center of screen
        new INotificationManager().showSuccessMessage()  
      })
    }, 500)
  }
}

function IconSwitch() {
  return <div className="IconSwitch"><div className="switch" /></div>
}

class SearchMenu extends Component {
  constructor (props) {
    super(props)
    this.didUpdate = this.didUpdate.bind(this)
  }
  
  didUpdate (propName, value) {
    if (propName === 'search') {
      const { router } = this.context
      router.history.push(router.route.location.pathname + (value ? '?' + querystring.encode({ search: value }) : ''))
    }
  }

  render () {
    const { location } = this.context.router.history
    const { search } = querystring.parse(location.search.replace(/^\?/, ''))
    const filterValue = { search }

    return (
      <Form className="SearchContainer">
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }
}

export function EditLiveMenu (props, context) {
  const { roleManagerId } = context.router.route.match.params
  const { pathname } = context.router.route.location
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  const showingMyBlog = _showingMyBlog (currentUser, roleManagerId)

  if (!showingMyBlog) {
    return null
  }

  let editUri, liveUri
  if (pathname.indexOf('/edit') === 0) {
    editUri = pathname
    liveUri = pathname.substr(5)
  } else {
    // We need to rewrite the URL for posts
    const tmp = safeGet(() => window.location.pathname.split('/'), [])
    if (tmp.length === 4 && ['Media', 'Profile'].indexOf(tmp[3]) < 0) {
      editUri = `/edit/${tmp[1]}`
    }
    else {
      editUri = `/edit${pathname}`
    }
    liveUri = pathname
  }

  const menuOptions = [
    {
      isSelected: editUri === pathname,
      to: editUri,
      label: i18n('MainMenu-edit', 'edit')
    },
    {
      isSelected: liveUri === pathname,
      to: liveUri,
      label: i18n('MainMenu-live', 'live')
    },
  ]

  return (
    <div className={classnames("EditLiveMenu", props.classNames)}>
      <NavFilter options={menuOptions} />
    </div>
  )
}

export function PullUpMenu (props, context) {
  const { roleManagerId } = context.router.route.match.params
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  const showingMyBlog = _showingMyBlog (currentUser, roleManagerId)

  const currentBlog = new ISessionManager({ registry: context.registry }).getCurrentRoleManager()
  let avatarUrl
  if (currentBlog) {
    const tmpUserInfo = safeGet(() => currentBlog['_userInfo'], {})
    avatarUrl = tmpUserInfo.avatarUrl
  }
  else {
    avatarUrl = currentUser.avatarUrl
  }

  return (
    <div className={classnames("PullUpMenu", {
      active: props.active
    })}>
      <div className="PullUpMenu-Background" />
      <Avatar image={avatarUrl} active={props.active} targetElId="AvatarImg" />
      <MainMenu />
    </div>
  )
}


async function doLogout(e, context) {
  e.preventDefault()

  await new IApiClient().delete({
    URI: '/session'
  })

  await new ISessionManager({ registry: context.registry }).refreshCurrentUser()

  // I have short timeout to allow the animation to play
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}

export function LogoutMenu(props, context) {
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  if (currentUser) {
    return (
      <div className="LogoutMenu">
        <Button link onClick={(e) => doLogout(e, context)}>{i18n('MainMenu-logout', 'Logout')}</Button>
      </div>
    )
  }
  else {
    return (
      <div className="LogoutMenu">
        <NavButton to='/edit'>{i18n('MainMenu-login', 'Login')}</NavButton>
      </div>
    )
  }
}

export function AdminMenu(props, context) {
  const { pathname } = context.router.route.location
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  if (!safeGet(() => currentUser.roles.indexOf('admin') >= 0 )) {
    return null
  }

  const menuOptions = [
    {
      isSelected: pathname.startsWith('/admin'),
      to: '/admin/invitation',
      label: i18n('AdminMenu-admin', 'Admin')
    },
    {
      isSelected: pathname.startsWith('/edit'),
      to: '/edit',
      label: i18n('AdminMenu-content', 'Content')
    },
  ]

  return (
    <div className="AdminMenu">
      <NavFilter options={menuOptions} />
    </div>
  )
}

export const AdminTypeMenu = (props, context) => {
  const { pathname } = context.router.route.location
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  
  const { location } = context.router.history
  const { search } = querystring.parse(location.search.replace(/^\?/, ''))
  const searchMode = (search ? true : false)


  const menuOptions = [
    {
      isSelected: '/admin/invitation' === pathname,
      to: '/admin/invitation',
      label: i18n('AdminTypeMenu-invitation', 'Invitation')
    },
    {
      isSelected: '/admin/User' === pathname,
      to: '/admin/User',
      label: i18n('AdminTypeMenu-user', 'Users')
    },
  ]

  return (
    <div className={classnames({
      MainMenu: true,
      search:  search
    }, props.classNames)}>
      <div className="MainMenu-Slot NavSlot">
        {!search &&
          <Animated key="nav" prefix="NavBar">
            <NavFilter options={menuOptions} />
          </Animated>
        }
      </div>   
    </div>
  )
}
