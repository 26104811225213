import { Component, createRef } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from 'influence-i18n'
import querystring from 'querystring'

import { MessageCanvas, NavButton, ButtonBar } from 'influence-ux-components'
import {
  Form,
} from 'influence-ux-components'
import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'

import SectionPage from '../SectionPage'
import { WorkflowFilterField } from 'app-field-WorkflowFilterField'
import { InfinityScroll } from 'app-widget-InfinityScroll'
import { FormRows } from 'influence-ux-formlib'

import { typeMapping } from './_typeMapping'

import 'app-field-WorkflowFilterField/widgets'
import 'app-widget-WorkflowWidgets'


import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { IPublishWorkflow } from 'app-workflow-PublishWorkflow'
import { IListItem } from 'influence-interfaces/presentation'

import './List.scss'
import './Dashboard.scss'

const filterSchema = new Schema('Filter Schema', {
  search: new TextField({
    label: 'Filter list:',
    placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
  }),
  publishWorkflow: new WorkflowFilterField({
    workflowInterface: IPublishWorkflow
  })
})



export default class ListPage extends Component {

  static limit = 30
  _lastLoadedPage = 1
  _scrollRef = null

  constructor (props) {
    super(props)

    this.state = {
      search: undefined,
      publishWorkflow: undefined,
      posts: props.fetchData || [],
      queryIsLoading: false,
      hasMore: true
    }

    this._scrollRef = createRef()

    this.didUpdate = this.didUpdate.bind(this)
    this.loadMore = this.loadMore.bind(this)
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    const objType = typeMapping(safeGet(() => match.params.type, 'BlogPost'))

    const URI = `/content/${objType}`
    
    const { search, publishWorkflow } = location.search || {}
    const query = { 
      search: (search && search.length >= 3 ? { title: search } : undefined), 
      stateFilter: (publishWorkflow && `publishWorkflow.${publishWorkflow}`) || '!publishWorkflow.trash'
    }
    
    if (match.params.roleManagerId) {
       query['roleManagerId'] = match.params.roleManagerId
    }

    for (let k in query) {
      if (!query[k]) delete query[k]
    }

    let data
    try {
      let { data: tmpData } = await new IApiClient({ registry }).query({
        URI,
        query: { 
          query,
          page,
          limit: ListPage.limit
        }
      })
      data = tmpData
    }
    catch (e) {
      data = []
    }

    // This is a hack!!!
    if (match.params.type === 'post') {
      try {
        const URI = `/content/Guide`
        const { data: guideData } = await new IApiClient({ registry }).query({
          URI,
          query: { 
            query,
            page,
            limit: ListPage.limit
          }
        })
        data = data.concat(guideData)
      }
      catch (e) {
        // Do nothing
      }
    }
    
    return data.sort((a, b) => b._createdAt - a._createdAt)
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { search, publishWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    let query = { search, publishWorkflow }
    const posts = nextProps.fetchData || []
    this.setState({ posts, ...query })
  }

  async loadMore () {
    if (this.state.queryIsLoading || this._lastLoadedPage === null) return
    
    // load some more
    const { router } = this.context
    
    const { search, publishWorkflow } = this.state
    const match = router.route.match
    const location = { ...router.route.location }
    location.search = { search, publishWorkflow }
    
    this.setState({ queryIsLoading: true })

    const posts = await ListPage.fetchData({ match, location, router: this.context.router, page: ++this._lastLoadedPage})

    if (posts.length === 0) {
      this._lastLoadedPage = null
    }

    this.setState({
      posts: this.state.posts.concat(posts)
    })

    // Delay so we don't get insane amounts of reloading because of
    // delay with reflow (this is actually a bit flaky because images
    // might be loaded too slow)
    setTimeout(() => this.setState({
      queryIsLoading: false,
      hasMore: Array.isArray(posts) && posts.length >= ListPage.limit
    }), 500)
  }

  didUpdate (propName, value) {
    const { search, publishWorkflow } = this.state
    const state = { search, publishWorkflow }
    state[propName] = value

    this.setState(state)

    if (this._typeFilter) {
      clearTimeout(this._typeFilter)
    }

    this._typeFilter = setTimeout(async () => {
      const tmp = {}
      for (let k in state) {
        if (state[k]) tmp[k] = state[k]
      }
      const query = querystring.encode(tmp)

      const { router } = this.context

      // Update URL
      window.history.replaceState(undefined, 'Filter', router.route.location.pathname + (query ? '?' + query : ''))
      // router.history.push(router.route.location.pathname + (query ? '?' + query : ''))
      this._typeFilter = undefined
      
      // Update list
      const match = router.route.match
      const location = { ...router.route.location }
      location.search = state

      this.setState({ queryIsLoading: true })
      
      this._lastLoadedPage = 1
      const posts = await ListPage.fetchData ({match, location, router})
      
      this.setState({
        posts,
        queryIsLoading: false,
        hasMore: Array.isArray(posts) && posts.length >= ListPage.limit
      })
    }, 200)
  }

  renderActionbarContent () {
    const { roleManagerId, type } = this.props.match.params
    const listPageType = type || 'BlogPost'

    // Hack to show Add guide in toolbar
    const isContentFeed = listPageType === 'BlogPost'

    return (
      <ButtonBar inline>
        <NavButton link  className="action" to={`/edit/${roleManagerId}/${listPageType}/create`}>{i18n('ListAction-createPost', '+ Skapa ett nytt inlägg')}</NavButton>
        {/*isContentFeed && <NavButton link  className="action"  to={`/edit/${roleManagerId}/Guide/create`}>{i18n('ListAction-createGuide', '+ Skapa en ny guide')}</NavButton>*/}
      </ButtonBar>
    )
  }

  renderFilterForm () {
    const filterValue = this.state

    return (
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }

  renderHint () {
    return (
      <MessageCanvas arrowPointing="down">
        <h2>Welcome!</h2>
        <p>To create content, use the button below.</p>
      </MessageCanvas>
    )
  }

  render () {
    // Pass type to <Page> as either the param type, or MediaCard if on Media endpoint, or default to BlogPost
    // This was implemented because we are mapping MediaCard to .../Media
    let { type } = this.props.match.params || {}
    type = type || 'BlogPost'

    const currentBlog = new ISessionManager({ registry: this.context.registry }).getCurrentRoleManager()
    const blogIsPublic = safeGet(() => currentBlog._workflows.publishWorkflow === 'published', true)

    return (
      <SectionPage
        editHero={safeGet(() => !currentBlog.heroImageUrl)}
        match={this.props.match}
        type={type}
        editBlogVisibility={!blogIsPublic}
        actionbar={this.renderActionbarContent()}
        filter={this.renderFilterForm()}>
          <InfinityScroll
            scrollRef={this._scrollRef}
            className="List-Container"
            hasMore={this.state.hasMore}
            queryIsLoading={this.state.queryIsLoading}
            inBackground={false}
            loadMorePadding={100}
            onLoadMore={this.loadMore}>
          {this.state.posts.map((post) => {
            const ListItem = new IListItem(post).Component
            return <ListItem key={post._id} context={post} />
          })}
          </InfinityScroll>
          {this.state.posts.length < 4 && this.renderHint()}
        </SectionPage>
    )
  }
}
