import { safeGet } from 'safe-utils';
import { ISessionManager } from 'influence-interfaces/presentation';
export function getPostId(post) {
  return post && (post._pathId || post._id);
}
export function getPostSlug(post) {
  if (post && post._slug) {
    return '/' + post._slug;
  } else {
    return '';
  }
}
export var maxImages = {
  'one': 1,
  'two': 2,
  'one_two': 3,
  'one_three': 4,
  'one_two_horizontal': 3,
  'one_four_horizontal': 5
};
/**
 * 
 * @param {object} editor - the mobiledoc instance
 * @param {object} postEditor - passed to editor.run callback
 * @param {object} range - current selected range
 * @param {string} cardType - card utility name
 * @param {object} payload - the data for this card
 */

export function insertCard(editor, postEditor, range, cardType, payload) {
  var position;

  if (editor.post.isBlank) {
    var p = postEditor.builder.createMarkupSection('p');
    postEditor.insertSection(p); // postEditor.setRange(postEditor.editor.post.sections.head.headPosition())

    position = postEditor.editor.post.headPosition();
  } else {
    position = range.tail;
  } // In case we are at the end of a post


  if (position.isBlank) {
    //const p = postEditor.builder.createMarkupSection('p')
    //postEditor.insertSection(p)
    position = postEditor.editor.post.tailPosition();
  }
  /*
  if (!range.isCollapsed) {
    position = postEditor.deleteRange(range);
  }
  */


  var section = position.section;

  if (section.isNested) {
    section = section.parent;
  }

  var collection = editor.post.sections;
  var card = postEditor.builder.createCardSection(cardType, payload);
  postEditor.insertSectionBefore(collection, card, section); // It is important to explicitly set the range to the end of the card.
  // Otherwise it is possible to create an inconsistent state in the
  // browser. For instance, if the user clicked a button that
  // called `editor.insertCard`, the editor surface may retain
  // the selection but lose focus, and the next keystroke by the user
  // will cause an unexpected DOM mutation (which can wipe out the
  // card).
  // See: https://github.com/bustle/mobiledoc-kit/issues/286

  postEditor.setRange(section.tailPosition());
}
export function getConnectedTitleDefault(cardPayload, postValue) {
  if (!postValue.connectedTitle) {
    // Show title connection widget as inactive
    return {
      postId: postValue._id,
      active: false
    };
  } else if (postValue.connectedTitle.cardId === cardPayload._id) {
    // Show title connection widget as active
    return {
      postId: postValue._id,
      active: postValue.connectedTitle.active
    };
  } else {
    return null;
  }
}
export function hasValidationError(validationErrors, propertyName) {
  return safeGet(function () {
    return validationErrors.fieldErrors[propertyName] !== undefined;
  });
}
var _typeToSlug = {
  RecipeCard: 'recipe',
  ListCard: 'list',
  CityGuide: 'destination',
  PlaceCard: 'location',
  MediaCard: 'gallery'
};
export function convertCardTypeToSlug(type) {
  return _typeToSlug[type] || 'no-card-type-found';
}
export function convertCardSlugToType(type) {
  var reverseLookup = {};

  for (var key in _typeToSlug) {
    reverseLookup[_typeToSlug[key]] = key;
  }

  return reverseLookup[type] || 'no-card-type-found';
}
export function getCardTypeSlugs() {
  return Object.values(_typeToSlug);
}
/**
 * Generate a URL to access card
 * @param {*} card the card object
 * @param {*} context the render context
 * @returns 
 */

export function getCardPageUrl(card, context) {
  if (context === undefined || context.router === undefined) {
    return undefined;
  }

  var _ISessionManager$getC = new ISessionManager({
    registry: context.registry
  }).getCurrentRoleManager(),
      blogName = _ISessionManager$getC.title,
      blogId = _ISessionManager$getC._id;

  var pathname = context.router.route.location.pathname;
  var _pathId = card._pathId,
      _id = card._id,
      _slug = card._slug,
      _type = card._type;
  var viewCardUrl = "/".concat(blogName || blogId, "/").concat(convertCardTypeToSlug(_type), "/").concat(_pathId || _id) + (_slug ? "/".concat(_slug) : '');
  return viewCardUrl;
}
export function isNullOrUndefined(val) {
  return val === undefined || val === null;
}
export function getStoredLocationAndUpdateHref(card, context) {
  var outp = {
    href: window.location.href,
    title: window.document.title
  };
  window.history.replaceState(undefined, card.title, getCardPageUrl(card, context));
  return outp;
}
export function restoreHref(storedLocation) {
  var href = storedLocation.href,
      title = storedLocation.title;
  window.history.replaceState(undefined, title, href);
}