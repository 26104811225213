import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { createComponentVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import classnames from 'classnames';
import { Link } from 'inferno-router';
/**
 * Basic button
 * @param {String} param0.className 
 * @param {bool} param0.isSelected state button is selected
 * @param {String} param0.children button label
 * @param {Function} param0.onClick onClick callback
 * 
 */

export default function (_ref) {
  var className = _ref.className,
      isSelected = _ref.isSelected,
      to = _ref.to,
      children = _ref.children;
  var props = {
    className: classnames('NavStateButton', {
      isSelected: isSelected
    }, className),
    to: to
  };
  return normalizeProps(createComponentVNode(2, Link, _objectSpread(_objectSpread({}, props), {}, {
    children: children
  })));
}