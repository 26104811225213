/*eslint no-template-curly-in-string: "off"*/
export const en = {
  'isomorphic-schema--field_required': 'Required',
  'isomorphic-schema--text_field_no_string': 'The field doesn\'t contain text',
  'isomorphic-schema--text_field_too_short': 'The text is too short. Min ${minLength} chars.',
  'isomorphic-schema--text_field_too_long': 'The text is too long. Max ${maxLength} chars.',
  'isomorphic-schema--text_area_field_no_string': 'The field doesn\'t contain text',
  'isomorphic-schema--integer_field_not_number': 'The field doesn\'t contain numbers',
  'isomorphic-schema--integer_field_no_decimals': 'The field may not contain decimals',
  'isomorphic-schema--integer_field_too_small': 'The value is too small. Min ${minValue}',
  'isomorphic-schema--integer_field_too_big': 'The value is too big. Max ${maxValue}',
  'isomorphic-schema--decimal_field_not_number': 'The field doesn\'t contain numbers',
  'isomorphic-schema--decimal_field_too_small': 'The value is too small. Min ${minValue}',
  'isomorphic-schema--decimal_field_too_big': 'The value is too big. Max ${maxValue}',
  'isomorphic-schema--credit_card_field_not_supported': 'Entered card type is not supported',
  'isomorphic-schema--credit_card_field_incorrect_formatting': 'The card number is incorrectly entered',
  'isomorphic-schema--date_field_incorrect_formatting': 'This doesn\'t look like a date',
  'isomorphic-schema--date_time_field_incorrect_formatting': 'This doesn\'t look like a date with time',
  'isomorphic-schema--email_field_incorrect_formatting': 'This is not a valid e-mail address',
  'isomorphic-schema--list_field_type_error': 'This is not proper list. This is a bug in the application',
  'isomorphic-schema--list_field_value_error_too_many_items': 'Too many items in list, max ${maxItems} allowed',
  'isomorphic-schema--list_field_value_error_too_few_items': 'Too few items in list, min ${minItems} allowed',
  'isomorphic-schema--list_field_value_error': 'There is an error in the content of this list',
  'isomorphic-schema--multi_select_field_value_error': 'One or more of the selected values is not allowed',
  'isomorphic-schema--object_field_value_error': 'There is an error in the content of this object',
  'isomorphic-schema--org_nr_field_incorrect_formatting': 'Malformatted',
  'isomorphic-schema--org_nr_field_too_short': 'Entered number is too short',
  'isomorphic-schema--org_nr_field_wrong_checksum': 'The entered number is incorrect (checksum error)',
  'isomorphic-schema--password_field_too_short': 'The password must contain at least 8 chars',
  'isomorphic-schema--select_field_value_error': 'The selected value is not allowed',
  
  'influence-ux-formlib--ListField_remove': 'Remove',
  'influence-ux-formlib--ListField_add': 'Add',

  'influence-ux-formlib--DateField': 'Date',
  'influence-ux-formlib--DateTimeField': 'Date and Time',
  'influence-ux-formlib--DateField-months': 'januari_februari_mars_april_may_june_july_august_september_october_november_december',
  'influence-ux-formlib--DateField-weekdays': 'su_mo_tu_we_th_fr_sa',
  'influence-ux-formlib--DateField-select_date': 'Select a date',
  'influence-ux-formlib--DateField-clear': 'clear',

  'ImageField-placeholder': 'Click or drop to upload...',
  

  'IUser-accounts-label': 'Connected accounts',
  'IUser-accountsEmail-label': 'E-mail account',
  'IUser-roles-label': 'System Roles',
  'IUser-roles-option-admin': 'Admin',
  'IUser-roles-option-customerService': 'Community Manager',
  'IUser-roles-option-member': 'Member',
  'IUser-avatarUrl-label': 'Profile image',
  'IUser-avatarUrl-placeholder': 'Click or drop to upload...',
  'IUser-avatarUrl-help': 'This image will be visible to all users. Only JPEG or PNG. Max 20Mb.',
  'IUser-firstName-label': 'First name',
  'IUser-lastName-label': 'Last name',
  'IUser-activeInfluencer-label': 'Active as influencer',
  'IUser-activeInfluencer-help': 'You can uncheck this box if you do not want to be approached for influencer opportunities',
  'IUser-influencer-label': 'Influencer',
  'IUser-city-label': 'City',
  'IUser-country-label': 'Country',
  'IUser-occupation-label': 'Occupation',
  'IUser-birthDate-label': 'Birthday',
  'IUser-birthDate-placeholder': 'yyyy-mm-dd',
  'IUser-gender-label': 'Gender',
  'IUser-option-male': 'Male',
  'IUser-option-female': 'Female',
  'IUser-option-other': 'Other',
  'IUser-mainBlogName-label': 'Blog Name',
  'IUser-mainBlogId-label': 'Blog ID',
  
  'IBlog-title-label': 'Blog Name',
  'IBlog-title-help': 'This name is similar to the Instagram user name, so no spaces and lowercase.',
  'IBlog-users-label': 'Users',
  'IBlog-profilePost-label': 'Blog Post shown in the "About Me" section of your profile',
  'IBlog-profilePost-placeholder': 'Type title of post...',
  'IBlog-profilePost-help': 'Start typing the title of post and select from matches in the list. If you don\'t see a list, check spelling.',
  'IBlog-heroImageUrl-label': 'Blog Header Image',
  'IBlog-heroImageUrl-help': 'This image will be visible to all users. Only JPEG or PNG. Max 20Mb.',
  'IBlog-layoutType-label': 'Layout',
  'IBlog-layoutType-option-normal': 'Normal',
  'IBlog-layoutType-option-cover': 'Cover',
  'IBlog-title-error-notAvailable': 'This name is not available',

  'IAccountInstagram-useForLogin-label': 'Use for login',
  'IAccountInstagram-accessToken-label': 'Instagram Access Token',
  'IAccountInstagram-user-label': 'Instagram Username',
  'IAccountInstagram-userId-label': 'Instagram User ID (Deprecated)',

  'IAccountFacebook-useForLogin-label': 'Use for login',
  'IAccountFacebook-accessToken-label': 'Facebook Access Token',
  'IAccountFacebook-user-label': 'Basic Profile',
  'IAccountFacebook-userId-label': 'Facebook User ID (Deprecated)',

  'IAccountEmail-email-label': 'Email',
  'IAccountEmail-password-label': 'Password',
  'IAccountEmail-password-help': 'Confirm password',
  'IAccountEmail-useForLogin-label': 'Use for login',


  'IBlogPost-title-placeholder': 'Type your title here...',
  'IBlogPost-connectedTitle-helpActive': 'A card is connected to your post title. This hides the card title in the post.',
  'IBlogPost-connectedTitle-helpInactive': 'Toggle to reconnect. (This switch will disappear on save.)',

  'IGuide-title-label': 'Name of Guide',
  'IGuide-shortDescription-label': 'Short description',
  'IGuide-heroImageUrl-label': 'Hero image',
  'IGuide-guideType-label': 'Guide type',
  'IGuide-guideType-option-guide': 'Guide',
  'IGuide-guideType-option-tutorial': 'Tutorial',
  'IGuide-introduction-label': 'Teaser',
  'IGuide-content-label': 'Content',
  'IGuide--ListFieldPage_add': 'Add page',
  'IGuide--ListFieldChapter_add': 'Add chapter',

  'IGuideChapter-title-placeholder': 'Type your chapter title here...',
  'IGuideChapter-shortDescription-placeholder': 'Short description here...',

  
  'IGuide--filter-about-label': 'About',
  'IGuide--filter-introduction-label': 'Teaser',
  'IGuide--filter-content-label': 'Content',

  'PrincipalRoleSchema-principalId-label': 'User',
  'PrincipalRoleSchema-role-label': 'Role',
  'PrincipalRoleSchema-role-option-admin': 'Admin',
  'PrincipalRoleSchema-role-option-editor': 'Editor',
  'PrincipalRoleSchema-role-option-writer': 'Writer',
  
  'filterForm-all-label': 'All',
  'profileFormSchema-blog-label': 'Blog Settings',
  'profileFormSchema-user-label': 'Profile Settings',
  'profileFormSchema-isPublic-label': 'Make blog public',

  'category-family': 'Family life',
  'category-horses': 'Horse life',
  'category-mental-health': 'Mental health',
  'category-pregnant': 'Pregnancy',
  'category-fashion': 'Fashion',
  'category-lifestyle': 'Lifestyle',
  'category-travel': 'Travel',
  'category-entertainment': 'Entertainment',
  'category-esport': 'E-sports',
  'category-youtuber': 'Youtuber',
  'category-food-baking': 'Food & baking',
  'category-health-fitness': 'Health & fitness',
  'category-makeup-beauty': 'Beauty & makeup',
  'category-career-business': 'Career & entrepreneurship',
  'category-home-interior': 'Home & interior design',
  'category-animals-nature': 'Animals & nature',
  'category-yoga-wellness': 'Yoga & Wellness',
  'category-sports': 'Sports',
  'category-photo-video': 'Photography & video',

  'invoiceDetails-email-label': 'Send invoices to e-mail',
  'invoiceDetails-companyName-label': 'Company Name',
  'invoiceDetails-companyVatNr-label': 'VAT-nr',
  'invoiceDetails-country-label': 'Country',

  'country-se': 'Sweden',

  'address-row-label': 'Address',
  'address-postalCode-label': 'Postal Code',
  'address-city-label': 'City',
  'address-country-label': 'Country',

  'contact-email-label': 'E-mail Address',
  'contact-phone-label': 'Mobile Phone',

  'gender-male': 'Male',
  'gender-female': 'Female',
  'gender-non-binary': 'Non-binary',

  'TopMenu-login': 'login',
  'TopMenu-contact': 'contact',
  'AdminMenu-admin': 'Admin',
  'AdminMenu-content': 'Content',

  'createBlog-choose-blogName-label': 'Choose the name of your blog:',
  'createBlog-blogName-placeholder': 'Type here...',
  'createBlog-importFile-label': 'Upload a Wordpress compatible export file:',
  'createBlog-importFile-placeholder': 'Click or drop to upload...',
  'createBlog-importFile-help': 'Your blog is instantly created but the imported posts may take a while to process.',


  'IWorkflowFilter-all-label': 'All',
  'IPublishWorkflow-option-draft': 'Draft',
  'IPublishWorkflow-option-published': 'Published',
  'IPublishWorkflow-option-trash': 'Trash',
  'WorkflowAction-to': 'to ',
  'WorkflowAction-updateStatus': 'Change Status',

  'MainMenu-posts': 'Feed',
  'MainMenu-cards': 'Cards',
  'MainMenu-profile': 'Profile',
  'MainMenu-edit': 'edit',
  'MainMenu-live': 'live',
  'MainMenu-logout': 'Logout',
  'MainMenu-login': 'Login',
  
  'FilterSchema-search-placeholder': 'Search...',
  'ListAction-createPost': '+ Create a new post',
  'ListAction-createGuide': '+ Create a new guide',

  'AdminTypeMenu-dashboard': 'Dashboard',
  'AdminTypeMenu-blog': 'Blogs',
  'AdminTypeMenu-invitation': 'Invitations',
  'AdminTypeMenu-user': 'Users',
  'AdminMenu-logout': 'Logout',
  'ListAction-createBlog': '+ Create a new blog',
  'ListAction-createInvitation': '+ Create a new invitation',
  'ListAction-createUser': '+ Create a new user',

  // These should probably all be prefixed with TimedPublishing- instead of IBlogPost
  'ITimedPublishing-publishingStartsAt-label': 'Publish start:',
  'ITimedPublishing-publishingEndsAt-label': 'Publish end:',
  'TimedPublishing-formHeader': 'Timed publishing',

  'ICard-title-label': 'Title',
  'ICard-title-placeholder': 'Type title here...',
  'ICard-images-label': 'Images',
  'ICard-images-valueType-placeholder': 'Click or drop to upload...',
  'ICard-layoutType-label': 'Layout Type',
  'ICard-layoutType-option-one': 'Single',
  'ICard-layoutType-option-two': 'Two, side by side',
  'ICard-layoutType-option-one_two': 'One on top, two underneath',
  'ICard-layoutType-option-one_three': 'One on top, three underneath',
  'ICard-layoutType-option-one_two_horizontal': 'Three, one on left',
  'ICard-layoutType-option-one_four_horizontal': 'Five, one on left',
  'ICard-allowCrop-label': 'Allow cropping to balance images',
  'ICard-shortDescription-label': 'Short Description',
  'ICard-image-label': 'Image',
  'ICard-image-help': 'Only JPEG or PNG. Max 20Mb.',
  'ICard-image-placeholder': 'Click or drop to upload...',
  'ICard-connectedTitle-helpActive': 'Using post title.',
  'ICard-connectedTitle-helpInactive': 'Using card title.',
  
  'ICityGuide-title-label': 'Destination Name',
  'ICityGuide-title-placeholder': 'Type title of destination here...',
  'ICityGuide-topSpots-label': 'Top Spots',
  'ICityGuide-funFacts-label': 'Fun Facts',
  'SpotsSchema-description-label': 'Description',
  'SpotsSchema-location-label': 'Destination',
  'SpotsSchema-location-placeholder': 'Type an address',

  'IPlaceCard-title-label': 'Place Name',
  'IPlaceCard-title-placeholder': 'Type title of place here...',
  'IPlaceCard-placeType-label': 'Type of Place',
  'IPlaceCard-placeType-option-bar': 'Bar',
  'IPlaceCard-placeType-option-coffeshop': 'Coffeshop',
  'IPlaceCard-placeType-option-fastfood': 'Fastfood Establishment',
  'IPlaceCard-placeType-option-nightclub': 'Nightclub',
  'IPlaceCard-placeType-option-park': 'Park',
  'IPlaceCard-placeType-option-place': 'Place',
  'IPlaceCard-placeType-option-restaurant': 'Restaurant',
  'IPlaceCard-placeType-option-shop': 'Shop',
  'IPlaceCard-collection-label': 'Belongs to destination guide:',
  'IPlaceCard-collection-placeholder': 'Type name of destination guide...',
  'IPlaceCard-url-label': 'Website',
  'IPlaceCard-location-label': 'Adress',
  'IPlaceCard-location-placeholder': 'Type an address...',
  'IPlaceCard-funFacts-label': 'Fun Facts',

  'IRecipeCard-title-label': 'Recipe Name',
  'IRecipeCard-title-placeholder': 'Type title of recipe here...',
  'IRecipeCard-timeInMins-label': 'Total Time in Minutes',
  'IRecipeCard-nrofPeople-label': 'Number of People',
  'IRecipeCard-ingredients-label': 'Ingredients',
  'IngredientSchema-ingredients-help': 'Use metric such as 1dl, 200g. Or just the nrof items.',
  'IngredientSchema-amount-label': 'Amount',
  'IngredientSchema-name-label': 'Ingredient',
  'IngredientSchema-note-label': 'Note',
  'IRecipeCard-steps-label': 'Steps',
  'StepsSchema-description-label': 'Step description',
  'StepsSchema-timerMins-label': 'Timer Minutes',
  'StepsSchema-timerMins-help': 'This is optional and will display a timer at this step',

  'IListCard-title-label': 'List Name',
  'IListCard-title-placeholder': 'Type title of list here...',
  'IListCard-shortDescription-label': 'The List',
  'IListCard-listLayoutType-label': 'List Layout',
  'IListCard-listLayoutType-option-thumbnail': 'Thumbnail to left',
  'IListCard-listLayoutType-option-header': 'Header on top',
  'ListSchema-description-label': 'Description',
  'ListSchema-url-label': 'URL',

  'IMediaCard-shortDescription-label': 'Image Caption',

  'IVideoCard-videoUrl-label': 'URL to Youtube Video',
  'IVideoCard-videoUrl-placeholder': 'https://www.youtube.com/watch?v=...',
  'IVideoCard-shortDescription-label': 'Video Caption',
  'IVideoCard-image-label': 'Video Poster Image',

  'IInvitation-firstName-label': 'First Name',
  'IInvitation-lastName-label': 'Last Name',
  'IInvitation-instagramUserName-label': 'Instagram Username',
  'IInvitation-websiteUrl-label': 'Website',
  'IInvitation-email-label': 'E-mail',
  'IInvitation-connectedBlogId-label': 'Connected Blog (ID)',

  'IInvitationWorkflow-option-draft': 'Draft',
  'IInvitationWorkflow-option-pending': 'Pending',
  'IInvitationWorkflow-option-activated': 'Activated',
  'IInvitationWorkflow-option-trash': 'Trash',

  'IUserWorkflow-option-active': 'Active',
  'IUserWorkflow-option-investigate': 'Investigate',
  'IUserWorkflow-option-suspended': 'Suspended',
  'IUserWorkflow-option-pendingDelete': 'To Be Delete',

  'IMedia-shortDescription-label': 'Short description',
}