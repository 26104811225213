import { Component } from 'inferno'
import { IListItem } from 'influence-interfaces/presentation'
import { IApiClient, ISessionManager } from 'influence-interfaces/presentation'
import { safeGet } from 'safe-utils'

import querystring from 'querystring'
import './List.scss'
import './Dashboard.scss'
import {
  Form,
  Masonry,
} from 'influence-ux-components'
import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'

import { WorkflowFilterField } from 'app-field-WorkflowFilterField'
import 'app-field-WorkflowFilterField/widgets'
import 'app-widget-WorkflowWidgets'
import { IPublishWorkflow } from 'app-workflow-PublishWorkflow'
import SectionPage from '../SectionPage'
import { FormRows } from 'influence-ux-formlib'

const filterSchema = new Schema('Filter Schema', {
  search: new TextField({
    label: 'Filter list:',
    placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
  }),
  publishWorkflow: new WorkflowFilterField({
    workflowInterface: IPublishWorkflow
  })
})



export default class MasonryPage extends Component {

  static limit = 10
  _lastLoadedPage = 1

  constructor (props) {
    super(props)

    this.state = {
      search: undefined,
      publishWorkflow: undefined,
      posts: props.fetchData || [],
      queryIsLoading: false,
      hasMore: true,
      rowGap: 10,
      rowHeight: 10
    }
  }

  static async fetchData ({registry, match, location, router, page = 1}) {
    const { search, publishWorkflow } = location.search || {}
    const query = { 
      search: (search && search.length >= 3 ? { title: search } : undefined), 
      stateFilter: (publishWorkflow && `publishWorkflow.${publishWorkflow}`) || '!publishWorkflow.trash'
    }

    if (match.params.roleManagerId) {
       query['roleManagerId'] = match.params.roleManagerId
    }

    for (let k in query) {
      if (!query[k]) delete query[k]
    }

    let tmpQuery
    if (match.params.type === 'MediaCard') {
      tmpQuery = [
        {
          URI: '/content/MediaCard', query: { query, page, limit: MasonryPage.limit }
        },
        {
          URI: '/content/CityGuide', query: { query, page, limit: MasonryPage.limit }
        },
        {
          URI: '/content/PlaceCard', query: { query, page, limit: MasonryPage.limit }
        },
        {
          URI: '/content/RecipeCard', query: { query, page, limit: MasonryPage.limit }
        },
        {
          URI: '/content/ListCard', query: { query, page, limit: MasonryPage.limit }
        }
      ]
    }
    else {
      tmpQuery = {
        URI: `/content/${safeGet(() => match.params.type, 'BlogPost')}`,
        query: { query, page, limit: MasonryPage.limit }
      }
    }
    
    try {
      const { data } = await new IApiClient({ registry }).query(tmpQuery)
      
      if (Array.isArray(data)) {
        const outp = data.reduce((prev, curr) => {
          return prev.concat(curr)
        }, [])
        outp.sort((a, b) => b._createdAt - a._createdAt)
        return outp
      }
    }
    catch (e) {
      // Do nothing
    }
    return []
  }

  componentDidMount() {
    requestAnimationFrame(this._checkLoadMore)
  }

  componentWillReceiveProps (nextProps, nextContext) {
    const { search, publishWorkflow } = querystring.parse(nextProps.location.search.replace(/^\?/, ''))
    let query = { search, publishWorkflow }
    const posts = nextProps.fetchData || []
    this.setState({ posts, ...query })
  }

  render ({ location }) {
    const { search } = querystring.parse(location.search.replace(/^\?/, ''))
    const searchMode = (search ? true : false)
    const currentBlog = new ISessionManager({ registry: this.context.registry }).getCurrentRoleManager()

    return (
      <SectionPage
        editHero={safeGet(() => !currentBlog.heroImageUrl)}
        match={this.props.match}
        type='Cards'
        toolbar={undefined}
        filter={this.renderFilterForm()}>
        <Masonry className="List-Container" domRef={(el) => this._thumbnailsEl = el}>
            {this.state.posts.map((post) => {
              const ListItem = new IListItem(post).Component
              return <ListItem key={post._id}
                        rowGap={this.state.rowGap}
                        rowHeight={this.state.rowHeight}
                        context={post} />
            })}
        </Masonry>
      </SectionPage>
    )
  }

  renderFilterForm () {
    const filterValue = this.state

    return (
      <Form onSubmit={(e) => e.preventDefault()}>
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }

  _checkLoadMore = () => {
    if (this.$UN) return

    if (this._thumbnailsEl) {
      if (!this.state.isLoading && this._hasMoreResults) {
        const {
          scrollTop,
          scrollHeight,
          offsetHeight
        } = this._thumbnailsEl
       
        if (scrollTop + offsetHeight + this.endZone > scrollHeight) {
          this.doLoadMore()
        }
      }
    }
    requestAnimationFrame(this._checkLoadMore)
  }

  doLoadMore = async () => {
    if (this.state.queryIsLoading || this._lastLoadedPage === null) return
    
    if (this._lastLoadedPage > 0) {
      this.setState({ queryIsLoading: true })

      const { router } = this.context
      const { search, publishWorkflow } = this.state
      const match = router.route.match
      // I need to inject the type
      match.params['type'] = 'MediaCard'
      const location = { ...router.route.location }
      location.search = { search, publishWorkflow }

      // TODO: Do we really need to pass apiClient?
      const posts = await MasonryPage.fetchData({apiClient: new IApiClient(), match, location, router: this.context.router, page: ++this._lastLoadedPage})

      if (posts.length === 0) {
        this._lastLoadedPage = null
      }

      this.setState({
        posts: this.state.posts.concat(posts)
      })
      
      // Delay so we don't get insane amounts of reloading
      setTimeout(() => this.setState({
        queryIsLoading: false,
        hasMore: Array.isArray(posts) && posts.length >= MasonryPage.limit
      }), 500)  
    }
  }

  didUpdate = (propName, value) => {
    const { search, publishWorkflow } = this.state
    const state = { search, publishWorkflow }
    state[propName] = value
    
    this.setState(state)

    if (this._typeFilter) {
      clearTimeout(this._typeFilter)
    }

    this._typeFilter = setTimeout(async () => {
      const tmp = {}
      for (let k in state) {
        if (state[k]) tmp[k] = state[k]
      }
      const query = querystring.encode(tmp)

      const { router } = this.context

      // Update URL
      window.history.replaceState(undefined, 'Filter', router.route.location.pathname + (query ? '?' + query : ''))
      // router.history.push(router.route.location.pathname + (query ? '?' + query : ''))
      this._typeFilter = undefined
      
      // Update list
      const match = router.route.match
      // I need to inject the type
      match.params['type'] = 'MediaCard'
      const location = { ...router.route.location }
      location.search = state

      this.setState({ queryIsLoading: true })
      
      this._lastLoadedPage = 1
      const posts = await MasonryPage.fetchData ({match, location, router})
      
      this.setState({
        posts,
        queryIsLoading: false,
        hasMore: Array.isArray(posts) && posts.length >= MasonryPage.limit
      })
    }, 500)
  }
}
