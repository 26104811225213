import { createObjectPrototype, Utility } from 'component-registry';
import { Permissions } from 'influence-permissions/lib/permissions';
import { PublishWorkflow } from 'app-workflow-PublishWorkflow';
import { RoleManager } from 'app-aspect-RoleManager';
import { IBlog } from './interface';
import { IObject, IObjectPrototypeFactory } from 'influence-interfaces/object';
export var Blog = createObjectPrototype({
  implements: [IBlog, IObject],
  extends: [RoleManager, Permissions, PublishWorkflow],
  constructor: function constructor(params) {
    this._type = 'Blog'; // Default values

    this.layoutType = 'normal';

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'customerService', 'member'],
      read: ['owner', 'admin', 'customerService', '{id}.admin', '{id}.editor', '{id}.writer', 'anonymous:publishWorkflow.published'],
      update: ['owner', 'admin', '{id}.admin', '{id}.editor'],
      delete: ['owner', 'admin']
    });

    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });

    this._IRoleManager.constructor.call(this, params);
  }
});
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Blog',
  getPrototype: function getPrototype() {
    return Blog;
  },
  getObject: function getObject(data) {
    return new Blog(data);
  }
});