import _objectSpread from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/objectSpread";
import _extends from "/var/www/app-admin-front/packages/AdminFront/node_modules/inferno-scripts/node_modules/babel-preset-inferno-app/node_modules/@babel/runtime/helpers/esm/extends";
import { createVNode, normalizeProps } from "inferno";
export default function SearchIcon(_ref) {
  var props = _extends({}, _ref);

  return normalizeProps(createVNode(32, "svg", null, [createVNode(32, "defs", null, createVNode(32, "path", null, null, 1, {
    "d": "M79.358,22 C87.125,22 94.553,23.507 101.643,26.522 C108.731,29.536 114.84,33.61 119.973,38.743 C125.106,43.876 129.18,49.986 132.194,57.075 C135.209,64.163 136.717,71.591 136.717,79.358 C136.717,91.307 133.348,102.144 126.613,111.866 L126.613,111.866 L154.558,139.811 C156.569,141.82 157.573,144.265 157.572,147.142 C157.572,149.966 156.54,152.411 154.476,154.474 C152.414,156.538 149.968,157.57 147.144,157.57 C144.209,157.57 141.767,156.538 139.812,154.474 L139.812,154.474 L111.867,126.611 C102.145,133.346 91.309,136.715 79.358,136.715 C71.591,136.715 64.164,135.208 57.075,132.193 C49.987,129.178 43.876,125.105 38.743,119.971 C33.61,114.838 29.536,108.729 26.522,101.641 C23.507,94.551 22,87.125 22,79.358 C22,71.591 23.507,64.164 26.522,57.075 C29.536,49.987 33.61,43.876 38.743,38.743 C43.876,33.61 49.986,29.536 57.075,26.522 C64.163,23.507 71.591,22 79.358,22 Z M79.358,42.857 C69.31,42.857 60.714,46.428 53.572,53.571 C46.43,60.713 42.858,69.309 42.858,79.357 C42.858,89.406 46.429,98 53.572,105.143 C60.715,112.285 69.31,115.857 79.358,115.857 C89.407,115.857 98.002,112.286 105.145,105.143 C112.287,98 115.86,89.406 115.86,79.357 C115.86,69.309 112.288,60.713 105.145,53.571 C98.001,46.429 89.406,42.857 79.358,42.857 Z",
    "id": "path-1"
  }), 2), createVNode(32, "g", null, createVNode(32, "use", null, null, 1, {
    "id": "Shape",
    "href": "#path-1"
  }), 2, {
    "id": "search",
    "stroke": "none",
    "stroke-width": "1",
    "fill-rule": "nonzero"
  })], 4, _objectSpread({
    "width": "180px",
    "height": "180px",
    "viewBox": "0 0 180 180",
    "version": "1.1",
    "xmlns": "http://www.w3.org/2000/svg"
  }, props)));
}