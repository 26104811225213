import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('app-entity');
import { Schema, BoolField, TextField, TextAreaField, SelectField, ListField, ObjectField, i18n, DateField } from 'isomorphic-schema';
import { ImageField } from 'app-field-ImageField';
import { IAccountEmail } from 'app-entity-AccountEmail/interface';
export var IUser = new Interface({
  name: 'IUser',
  schema: new Schema('User Schema', {
    avatarUrl: new ImageField({
      label: i18n('IUser-avatarUrl-label', 'Profile Image'),
      placeholder: i18n('IUser-avatarUrl-placeholder', 'Drag and drop...'),
      help: i18n('IUser-avatarUrl-help', 'Something reasonable please')
    }),
    firstName: new TextField({
      label: i18n('IUser-firstName-label', 'First Name'),
      required: true
    }),
    lastName: new TextField({
      label: i18n('IUser-lastName-label', 'Last Name'),
      required: true
    }),
    city: new TextField({
      label: i18n('IUser-city-label', 'City'),
      required: true
    }),
    country: new TextField({
      label: i18n('IUser-country-label', 'Country'),
      required: true
    }),
    // occupation: new TextField({
    //   label: i18n('IUser-occupation-label', 'Occupation'),
    //   required: false
    // }),
    birthDate: new DateField({
      label: i18n('IUser-birthDate-label', 'Birthdate'),
      placeholder: i18n('IUser-birthDate-placeholder', 'yyyy-mm-dd'),
      required: true
    }),
    gender: new SelectField({
      label: i18n('IUser-gender-label', 'Gender'),
      required: true,
      valueType: new TextField({}),
      options: [{
        name: 'male',
        title: i18n('IUser-option-male', 'Male')
      }, {
        name: 'female',
        title: i18n('IUser-option-female', 'Female')
      }, {
        name: 'other',
        title: i18n('IUser-option-other', 'Other')
      }]
    }),
    mainBlogName: new TextField({
      label: i18n('IUser-mainBlogName-label', 'Main Blog Name')
    }),
    mainBlogId: new TextField({
      label: i18n('IUser-mainBlogId-label', 'Main Blog')
    }),
    accounts: new ListField({
      label: i18n('IUser-accounts-label', 'Kopplade Konton'),
      valueType: new ObjectField({
        //label: i18n('IUser-accountsEmail-label', 'E-mail account'),
        objectFactoryName: 'AccountEmail',
        interface: IAccountEmail
      }),
      required: true
    }),
    roles: new ListField({
      label: i18n('IUser-roles-label', 'Systemroller'),
      valueType: new SelectField({
        options: [{
          name: 'admin',
          title: i18n('IUser-roles-option-admin', 'Admin')
        }, {
          name: 'customerService',
          title: i18n('IUser-roles-option-customerService', 'Community Manager')
        }, {
          name: 'member',
          title: i18n('IUser-roles-option-member', 'Member')
        }],
        valueType: new TextField({})
      }),
      minItems: 1,
      required: true
    })
  })
});