import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["domRef", "className", "type", "valid", "value", "placeholder", "children"];
import { createVNode, normalizeProps } from "inferno";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

import classnames from 'classnames';
export default function (_ref) {
  var domRef = _ref.domRef,
      className = _ref.className,
      type = _ref.type,
      valid = _ref.valid,
      value = _ref.value,
      placeholder = _ref.placeholder,
      children = _ref.children,
      attributes = _objectWithoutProperties(_ref, _excluded);

  type = type || 'text';
  var props = {
    className: classnames("form-input", "form-input-" + type, {
      'isNotValid': valid !== undefined && !valid
    }),
    placeholder: placeholder
  };

  switch (type) {
    case 'checkbox':
    case 'radio':
      if (value) attributes['checked'] = true;
      return normalizeProps(createVNode(64, "input", props.className, null, 1, _objectSpread({
        "type": type
      }, attributes), null, domRef));

    case 'file':
      return normalizeProps(createVNode(64, "input", null, null, 1, _objectSpread(_objectSpread({
        "type": type
      }, props), attributes), null, domRef));

    case 'textarea':
      return normalizeProps(createVNode(128, "textarea", null, null, 1, _objectSpread(_objectSpread({
        "value": value
      }, props), attributes), null, domRef));

    case 'select':
      return normalizeProps(createVNode(256, "select", null, children, 0, _objectSpread(_objectSpread({
        "value": value
      }, props), attributes), null, domRef));

    default:
      return normalizeProps(createVNode(64, "input", null, null, 1, _objectSpread(_objectSpread({
        "type": type,
        "value": value
      }, props), attributes), null, domRef));
  }
}